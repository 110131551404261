import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'ppg-external-arrow',
  standalone: true,
  template: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
    <path
      d="M5.63997 4.42999C5.50736 4.42999 5.38019 4.48267 5.28642 4.57644C5.19265 4.67021 5.13997 4.79738 5.13997 4.92999C5.13997 5.0626 5.19265 5.18978 5.28642 5.28355C5.38019 5.37731 5.50736 5.42999 5.63997 5.42999H10.0933L4.34664 11.1767C4.29751 11.2224 4.25811 11.2776 4.23078 11.339C4.20346 11.4003 4.18876 11.4665 4.18758 11.5336C4.18639 11.6008 4.19874 11.6675 4.22389 11.7297C4.24904 11.792 4.28647 11.8485 4.33395 11.896C4.38143 11.9435 4.43798 11.9809 4.50024 12.0061C4.5625 12.0312 4.62919 12.0436 4.69632 12.0424C4.76346 12.0412 4.82966 12.0265 4.891 11.9992C4.95233 11.9719 5.00753 11.9325 5.05331 11.8833L10.8 6.13666V10.59C10.8 10.7226 10.8527 10.8498 10.9464 10.9435C11.0402 11.0373 11.1674 11.09 11.3 11.09C11.4326 11.09 11.5598 11.0373 11.6535 10.9435C11.7473 10.8498 11.8 10.7226 11.8 10.59V4.92999C11.7996 4.86353 11.786 4.7978 11.76 4.73666C11.7237 4.65142 11.6647 4.57776 11.5895 4.52369C11.5143 4.46963 11.4257 4.43722 11.3333 4.42999H5.63997Z"
      fill="var(--fg-brand-tertiary, #0074A9)" />
  </svg> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExternalArrowComponent {}
