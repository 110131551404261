import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DividerModule } from 'primeng/divider';

@Component({
  selector: 'ppg-horizontal-divider',
  standalone: true,
  imports: [DividerModule],
  templateUrl: './horizontal-divider.component.html',
  styleUrl: './horizontal-divider.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HorizontalDividerComponent {}
