"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QStringV2Path = void 0;
const QFilterExpression_1 = require("../../QFilterExpression");
const QStringBasePath_1 = require("../base/QStringBasePath");
const QNumberV2Path_1 = require("./QNumberV2Path");
class QStringV2Path extends QStringBasePath_1.QStringBasePath {
  create(path) {
    return new QStringV2Path(path, this.converter);
  }
  indexOf(value) {
    return new QNumberV2Path_1.QNumberV2Path(this.getFunctionExpression("indexof" /* StringFilterFunctions.INDEX_OF */, value));
  }
  length() {
    const pathExpression = this.buildNoValueFunc("length" /* StringFilterFunctions.LENGTH */);
    return new QNumberV2Path_1.QNumberV2Path(pathExpression.getPath());
  }
  contains(value) {
    return new QFilterExpression_1.QFilterExpression(`${"substringof" /* StringFilterFunctions.SUBSTRING_OF */}(${this.convertInput(value)},${this.getPath()})`);
  }
}
exports.QStringV2Path = QStringV2Path;
