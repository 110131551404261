"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QDateTimeV2Path = void 0;
const UrlParamHelper_1 = require("../../param/UrlParamHelper");
const QDateTimeV2Param_1 = require("../../param/v2/QDateTimeV2Param");
const QBasePath_1 = require("../base/QBasePath");
const DateTimeFunctions_1 = require("./DateTimeFunctions");
class QDateTimeV2Path extends QBasePath_1.QBasePath {
  constructor() {
    super(...arguments);
    this.year = (0, DateTimeFunctions_1.yearFn)(this.getPath());
    this.month = (0, DateTimeFunctions_1.monthFn)(this.getPath());
    this.day = (0, DateTimeFunctions_1.dayFn)(this.getPath());
    this.hour = (0, DateTimeFunctions_1.hourFn)(this.getPath());
    this.minute = (0, DateTimeFunctions_1.minuteFn)(this.getPath());
    this.second = (0, DateTimeFunctions_1.secondFn)(this.getPath());
  }
  formatValue(value) {
    return (0, UrlParamHelper_1.formatWithTypePrefix)(QDateTimeV2Param_1.DATE_TIME_V2_TYPE_PREFIX, value);
  }
}
exports.QDateTimeV2Path = QDateTimeV2Path;
