import { Asset } from './asset.model';
import { ColorFamilyNaming } from './color-family-naming.model';
import { Color } from './color.model';
import { ColorInfoType } from '../../types/color/color-info-type.type';

export class ColorInfoValid implements Color {
  readonly isValid = true as const;
  readonly productNumber: string;
  readonly colorCode: string;
  readonly colorName: string;
  readonly rgb?: string;
  readonly image?: string;
  readonly assets: Asset[];
  readonly lightness?: number;
  readonly isBestSeller: boolean;
  readonly lrv?: number;
  readonly ncs?: string;
  readonly colorType?: ColorInfoType;
  readonly description?: string;
  readonly colorCollections?: string[];
  readonly colorFamilies?: ColorFamilyNaming[];
  addedAsFavoriteAt?: Date;
  isFavorite: boolean;
  readonly metaTitle?: string;
  readonly metaDescription?: string;

  constructor(
    productNumber: string,
    isFavorite: boolean,
    addedToFavoritesAt: Date | undefined,
    colorCode: string,
    colorName: string,
    rgb: string,
    image: string | undefined,
    assets: Asset[],
    isBestSeller: boolean,
    lrv?: number,
    ncs?: string,
    colorType?: ColorInfoType,
    description?: string,
    colorCollections?: string[],
    colorFamilies?: ColorFamilyNaming[],
    metaTitle?: string,
    metaDescription?: string,
  ) {
    this.productNumber = productNumber;
    this.isFavorite = isFavorite;
    this.addedAsFavoriteAt = addedToFavoritesAt;
    this.colorCode = colorCode;
    this.colorName = colorName;
    this.rgb = rgb;
    this.image = image;
    this.assets = assets;
    this.isBestSeller = isBestSeller;
    this.metaTitle = metaTitle;
    this.metaDescription = metaDescription;
    this.lightness = this.calculateLightness(rgb);
    this.lrv = lrv;
    this.ncs = ncs;
    this.colorType = colorType;
    this.description = description;
    this.colorCollections = colorCollections;
    this.colorFamilies = colorFamilies;
  }

  private calculateLightness(rgb: string): number | undefined {
    if (!rgb) {
      return undefined;
    }

    const [red, green, blue] = rgb.split(',').map((color) => parseInt(color));
    return red * 0.299 + green * 0.587 + blue * 0.114;
  }
}
