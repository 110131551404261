import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  Renderer2,
  RendererFactory2,
} from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { ResourceValuePipe } from '@ppg/shared/resource';

import {
  ConfirmationDialog,
  ConfirmationDialogData,
  ResourceDialogBody,
} from '../models/confirmation-dialog-data.model';

@Component({
  selector: 'ppg-confirmation-dialog-footer',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ButtonModule],
  template: `
    <div class="flex flex-column-reverse justify-content-end sm:flex-row">
      <p-button
        [label]="cancelButtonText"
        (onClick)="ref.close(false)"
        outlined="true"
        styleClass="w-full sm:w-auto min-w-11rem"
        data-test-id="confirmationDialog.cancel" />
      <p-button
        [label]="submitButtonText"
        (onClick)="ref.close(true)"
        styleClass="w-full sm:w-auto min-w-11rem"
        data-test-id="confirmationDialog.submit" />
    </div>
  `,
})
export class ConfirmationDialogFooterComponent extends ConfirmationDialog {
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig<ConfirmationDialogData>,
  ) {
    super();

    this.data = config.data;
  }

  get cancelButtonText(): string {
    return this.data?.cancelButtonText || '';
  }

  get submitButtonText(): string {
    return this.data?.submitButtonText || '';
  }
}

@Component({
  selector: 'ppg-confirmation-action-dialog',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ResourceValuePipe],
  template: `@if (body().key) {
      <p data-test-id="confirmationDialog.body" [innerHtml]="body().key! | resourceValue: body().args"></p>
    }
    @if (!body().key) {
      <p data-test-id="confirmationDialog.body" [innerHtml]="body().rawText"></p>
    }`,
  styles: `
    p {
      margin: 0;
    }
  `,
})
export class ConfirmationDialogComponent extends ConfirmationDialog implements AfterViewInit {
  readonly #document: Document = inject(DOCUMENT);
  readonly #renderer: Renderer2 = inject(RendererFactory2).createRenderer(null, null);

  readonly body = computed(() => {
    return this.#isResourceDialogBody(this.data?.body)
      ? {
          rawText: null,
          key: this.data?.body?.resourceKey ?? '',
          args: this.data?.body?.argsToReplace ?? undefined,
        }
      : {
          rawText: this.data?.body ?? '',
          key: null,
          args: null,
        };
  });

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig<ConfirmationDialogData>,
  ) {
    super();

    this.data = config.data;

    config.header = config.data?.headerText;
    config.templates = {
      footer: ConfirmationDialogFooterComponent,
      ...config.templates,
    };
    config.dismissableMask = config.dismissableMask ?? true;
  }

  ngAfterViewInit(): void {
    this.#AddDataTestIdToTitle();
  }

  #AddDataTestIdToTitle() {
    const titleElement = this.#document.querySelector('p-dynamicdialog .p-dialog-title');
    if (titleElement) {
      this.#renderer.setAttribute(titleElement, 'data-test-id', 'confirmationDialog.header');
    }
  }

  #isResourceDialogBody(value: string | ResourceDialogBody | undefined): value is ResourceDialogBody {
    return typeof value === 'object' && !!value && 'resourceKey' in value;
  }
}
