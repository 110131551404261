<div class="social-sharing">
  <h2 class="social-sharing-headline">{{ content().headline }}</h2>
  <div class="social-sharing-icons">
    @if (content().facebookLink) {
      <a tabindex="-1" [href]="content().facebookLink" [target]="urlTarget.BLANK">
        <ppg-facebook />
      </a>
    }
    @if (content().twitterLink) {
      <a tabindex="-1" [href]="content().twitterLink" [target]="urlTarget.BLANK">
        <ppg-twitter-x />
      </a>
    }
    @if (content().instagramLink) {
      <a tabindex="-1" [href]="content().instagramLink" [target]="urlTarget.BLANK">
        <ppg-instagram />
      </a>
    }
    @if (content().youtubeLink) {
      <a tabindex="-1" [href]="content().youtubeLink" [target]="urlTarget.BLANK">
        <ppg-youtube />
      </a>
    }
    @if (content().linkedinLink) {
      <a tabindex="-1" [href]="content().linkedinLink" [target]="urlTarget.BLANK">
        <ppg-linkedin />
      </a>
    }
    @if (content().pinterestLink) {
      <a tabindex="-1" [href]="content().pinterestLink" [target]="urlTarget.BLANK">
        <ppg-pinterest />
      </a>
    }
    @if (content().tiktokLink) {
      <a tabindex="-1" [href]="content().tiktokLink" [target]="urlTarget.BLANK">
        <ppg-tiktok />
      </a>
    }
  </div>
</div>
