import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'ppg-facebook',
  standalone: true,
  template: `<svg
    tabindex="0"
    class="icon"
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none">
    <rect width="40" height="40" rx="6" fill="var(--icon-background, white)" />
    <rect x="0.5" y="0.5" width="39" height="39" rx="5.5" stroke="var(--icon-border, white)" />
    <path
      d="M33.3333 20.0833C33.3021 17.5425 32.5454 15.0634 31.1525 12.9382C29.7595 10.813 27.7883 9.13002 25.4709 8.08757C23.1535 7.04513 20.5865 6.68658 18.0722 7.05417C15.5579 7.42177 13.2009 8.50018 11.279 10.1624C9.35701 11.8247 7.95006 14.0015 7.22382 16.4365C6.49758 18.8716 6.48229 21.4635 7.17975 23.9069C7.87721 26.3504 9.25838 28.5437 11.1606 30.2285C13.0628 31.9133 15.4068 33.0194 17.9167 33.4167V23.9667H14.5833V20.0833H17.9167V17.1333C17.8395 16.4474 17.9139 15.7529 18.1345 15.0988C18.3551 14.4447 18.7166 13.8471 19.1934 13.348C19.6703 12.8489 20.2508 12.4605 20.8942 12.2103C21.5375 11.9602 22.2279 11.8542 22.9167 11.9C23.9164 11.9137 24.9137 12.0028 25.9 12.1667V15.5H24.2333C23.9459 15.4638 23.6541 15.4926 23.3793 15.5843C23.1045 15.676 22.8539 15.8283 22.6459 16.0299C22.4379 16.2315 22.2778 16.4772 22.1776 16.749C22.0773 17.0208 22.0394 17.3116 22.0667 17.6V20.1167H25.7667L25.1667 24H22.0833V33.3333C25.2332 32.8351 28.1001 31.2244 30.1642 28.7935C32.2282 26.3625 33.3526 23.2723 33.3333 20.0833Z"
      fill="var(--icon-color, black)" />
  </svg> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FacebookComponent {}
