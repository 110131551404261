<div class="mx-auto page-section page-section-x-padded">
  <p-accordion expandIcon="pi pi-plus" collapseIcon="pi pi-minus">
    <p-accordionTab>
      <p-header>
        {{ content().headline }}
      </p-header>

      @for (richTextItem of links(); track $index) {
        <div>
          <ppg-rich-text [content]="richTextItem.content"></ppg-rich-text>
        </div>
      }
    </p-accordionTab>
  </p-accordion>
</div>
