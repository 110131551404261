type ImageSizes = 'Large600' | 'LargeOptimized240' | 'Main320' | 'MediumOptimized102' | 'SmallOptimized54';

const imageSizeParam = 'prid';

export function appendImageSize(imageUrl: string | null, imageSize: ImageSizes = 'Large600'): string | null {
  if (imageUrl) {
    try {
      const url = new URL(imageUrl);

      url.searchParams.append(imageSizeParam, imageSize);

      return url.toString();
    } catch {
      return imageUrl;
    }
  }

  return imageUrl;
}
