"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QEntityPath = void 0;
class QEntityPath {
  constructor(path, qEntityFn) {
    this.path = path;
    this.qEntityFn = qEntityFn;
    if (!path || !path.trim()) {
      throw new Error("Path must be supplied!");
    }
    if (!qEntityFn || typeof qEntityFn !== "function") {
      throw new Error("Function which returns query object must be supplied!");
    }
  }
  getPath() {
    return this.path;
  }
  getEntity(withPrefix = false) {
    return new (this.qEntityFn())(withPrefix ? this.path : undefined);
  }
  isCollectionType() {
    return false;
  }
  get props() {
    return new (this.qEntityFn())(this.path);
  }
}
exports.QEntityPath = QEntityPath;
