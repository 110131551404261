<div class="flex flex-column md:flex-row md:align-items-center gap-3">
  <p-button
    class="popover-toggler"
    styleClass="font-bold text-sm w-full md:w-auto"
    [outlined]="true"
    (onClick)="toggleOpen($event)">
    <div class="flex align-items-center w-full gap-2">
      @if (showAppliedFiltersIndicator()) {
        <div class="filters-selected-indicator pi pi-circle-on text-sm"></div>
      }

      <span class="p-button-label">{{ resources().open }}</span>

      <i class="p-button-icon pi text-sm" [ngClass]="open() ? 'pi-angle-up' : 'pi-angle-down'"></i>
    </div>
  </p-button>

  @if (selectedFilters().length; as count) {
    <p-chip label="{{ count }} {{ resources().activeFilters }}" class="active-count" styleClass="neutral" />
  }
</div>

@if (template(); as template) {
  <ng-container *ngTemplateOutlet="template" />
}

<ng-template #overlayPanel>
  <p-overlayPanel styleClass="filter-popover w-20rem p-0" (onHide)="open.set(false)">
    <ng-container *ngTemplateOutlet="menu"></ng-container>
  </p-overlayPanel>
</ng-template>

<ng-template #sidebar>
  <p-sidebar
    position="bottom"
    styleClass="filter-popover filter-sidebar h-auto"
    [blockScroll]="true"
    [(visible)]="open">
    <ng-template pTemplate="headless">
      <ng-container *ngTemplateOutlet="menu"></ng-container>
    </ng-template>
  </p-sidebar>
</ng-template>

<ng-template #menu>
  <p-menu styleClass="w-full border-none p-3" [class.with-filters-header]="isHeaderVisible()" [model]="menuItems()">
    @if (isHeaderVisible()) {
      <ng-template pTemplate="start">
        <div class="flex flex-row justify-content-between mb-3">
          <h4 class="header m-0 text-sm">{{ resources().title }}</h4>

          @if (isCloseVisible()) {
            <p-button
              class="close-button"
              styleClass="flex p-0 w-1rem h-1rem ml-3"
              icon="pi pi-times"
              severity="secondary"
              size="small"
              [rounded]="true"
              [text]="true"
              (onClick)="toggleOpen($event)" />
          }
        </div>
      </ng-template>
    }

    <ng-template pTemplate="submenuheader" let-item>
      @if (type() === 'checkbox') {
        <h5 class="header mb-3 text-sm">{{ item.label }}</h5>
      }
    </ng-template>

    <ng-template pTemplate="item" let-item>
      <div class="flex mb-3">
        <ng-template #badge>
          @if (item.badge) {
            <p-badge class="ml-auto" severity="secondary" badgeSize="large" [value]="item.badge" />
          }
        </ng-template>
        @if (item.checked !== undefined) {
          @switch (type()) {
            @case ('checkbox') {
              <p-checkbox
                binary="true"
                [value]="item.value"
                [label]="item.label"
                (onChange)="selectItem(item, $event.checked)"
                [(ngModel)]="item.checked" />
              <ng-container *ngTemplateOutlet="badge" />
            }
            @case ('radio') {
              <p-radioButton
                labelStyleClass="w-full"
                [label]="item.label"
                name="category"
                [value]="true"
                [ngModel]="item.checked"
                (ngModelChange)="selectItem(item, true)" />
              <ng-container *ngTemplateOutlet="badge" />
            }
            @case ('link') {
              <a
                class="p-button p-button-link menu-link"
                (click)="selectItem(item, true)"
                (keydown.enter)="selectItem(item, true)"
                tabindex="0"
                data-test-id="productCategories.category">
                {{ item.label }}
              </a>
            }
          }
        } @else {
          <p-button styleClass="h-auto p-0" [label]="item.label" [link]="true" (onClick)="toggleSeeMore(item.value)" />
        }
      </div>
    </ng-template>

    @if (isFooterVisible()) {
      <ng-template pTemplate="end">
        <p-button
          class="clear-button"
          styleClass="w-full mt-3"
          [label]="resources().clear"
          [outlined]="true"
          (onClick)="clearSelection.emit()" />
      </ng-template>
    }
  </p-menu>
</ng-template>
