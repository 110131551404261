"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QGuidPath = void 0;
const QBasePath_1 = require("../base/QBasePath");
const IdentityFormatter_1 = require("./IdentityFormatter");
class QGuidPath extends QBasePath_1.QBasePath {
  constructor() {
    super(...arguments);
    this.formatValue = IdentityFormatter_1.identityFormatter;
  }
}
exports.QGuidPath = QGuidPath;
