import { CommonModule } from '@angular/common';
import { Component, computed, input } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';

import { PpgErrorDefinition } from './constants/error-definition.constant';
import { PpgErrorDictionary } from './types/ppg-error-dictionaary.type';
import { PpgValidationError } from './types/ppg-validation-error.type';

const defaultErrorDictionary: PpgErrorDictionary = {
  [PpgErrorDefinition.Required]: {
    contentType: 'string',
    content: 'This field is required',
  },
  [PpgErrorDefinition.Min]: {
    contentType: 'function',
    content: (errors) => `The minimum value is ${errors![PpgErrorDefinition.Min].min}`,
  },
  [PpgErrorDefinition.Max]: {
    contentType: 'function',
    content: (errors) => `The maximum value is ${errors![PpgErrorDefinition.Max].max}`,
  },
  [PpgErrorDefinition.MaxLength]: {
    contentType: 'function',
    content: (errors) =>
      `The maximum length is ${errors![PpgErrorDefinition.MaxLength].requiredLength} characters, you entered ${errors![PpgErrorDefinition.MaxLength].actualLength}`,
  },
  [PpgErrorDefinition.MinLength]: {
    contentType: 'function',
    content: (errors) =>
      `The minimum length is ${errors![PpgErrorDefinition.MinLength].requiredLength} characters, you entered ${errors![PpgErrorDefinition.MinLength].actualLength}`,
  },
};

@Component({
  selector: 'ppg-control-validator',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './control-validator.component.html',
})
export class ControlValidatorComponent {
  readonly control = input.required<AbstractControl>();
  readonly errorOverrides = input<PpgErrorDictionary>();

  readonly registeredErrorCodes = computed(() => Object.keys(this.errorMessageDictionary()));
  readonly errorMessageDictionary = computed(() => ({
    ...defaultErrorDictionary,
    ...this.errorOverrides(),
  }));

  public buildValidationMessage(error?: PpgValidationError): string | undefined {
    if (!error) {
      return undefined;
    }

    if (error.contentType === 'string') {
      return error.content?.toString();
    }

    if (error.contentType === 'function') {
      const validationMessageResolver = error.content as (error: ValidationErrors | null) => string;
      return validationMessageResolver(this.control().errors);
    }

    return undefined;
  }
}
