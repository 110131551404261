import { computed, inject, Injectable } from '@angular/core';

import { CurrentSiteState } from '@ppg/configuration';
import { ProductApiType } from '@ppg/core/enums';
import { LanguagesService } from '@ppg/core/language';
import { ProductApiSettings } from '@ppg/core/models';

@Injectable({ providedIn: 'root' })
export class ProductApiSettingsService {
  readonly #currentSiteState = inject(CurrentSiteState);
  readonly #languageService = inject(LanguagesService);

  readonly standardCultureLanguage = computed<string | null>(
    () =>
      this.#currentSiteState
        .siteLanguages()
        .find(({ languageCulture }) => languageCulture === this.#languageService.language())?.standardCulture ?? null,
  );

  readonly #productApiSettings = computed<ProductApiSettings[]>(
    () => this.#currentSiteState.currentSite()?.productApiSettings ?? [],
  );

  readonly productJourneySettings = computed<ProductApiSettings | undefined>(() =>
    this.#filterSettingsByType(ProductApiType.PRODUCT_JOURNEY).find(({ languageCode }) =>
      languageCode.includes(this.standardCultureLanguage()!),
    ),
  );

  readonly productLineSettings = computed<ProductApiSettings | undefined>(() => {
    const productLines = this.#filterSettingsByType(ProductApiType.PRODUCT_LINE);
    return (
      productLines.find(({ languageCode }) => languageCode.includes(this.standardCultureLanguage()!)) ?? productLines[0]
    );
  });

  readonly colorJourneySettings = computed<ProductApiSettings | undefined>(
    () => this.#filterSettingsByType(ProductApiType.COLOR_JOURNEY)[0],
  );

  #filterSettingsByType(type: ProductApiType): ProductApiSettings[] {
    return this.#productApiSettings().filter(({ productApiType }) => productApiType === type);
  }
}
