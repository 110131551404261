"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QSingleV2Param = void 0;
const QStringNumberV2Param_1 = require("./QStringNumberV2Param");
class QSingleV2Param extends QStringNumberV2Param_1.QStringNumberV2Param {
  getTypeSuffix() {
    return "f";
  }
}
exports.QSingleV2Param = QSingleV2Param;
