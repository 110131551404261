import { MenuItem as PrimeMenuItem } from 'primeng/api';

import { FilterGroup, FilterCheckbox } from '@ppg/core/models';

export interface MenuItem extends PrimeMenuItem {
  value?: unknown;
  checked?: boolean;
  items?: MenuItem[];
}

export class MenuBuilder {
  private readonly menu: MenuItem[] = [];

  addSeparator(): MenuBuilder {
    this.menu.push({ separator: true });

    return this;
  }

  addFilterGroup(
    { label, value }: FilterGroup<unknown, unknown>,
    buildNestedFilters: (builder: MenuBuilder) => void,
  ): MenuBuilder {
    const builder = new MenuBuilder();

    buildNestedFilters(builder);

    this.menu.push({ label, value, items: builder.build() });

    return this;
  }

  addFilter({ value, label, checked, count }: FilterCheckbox<unknown>): MenuBuilder {
    this.menu.push({
      value,
      label,
      checked,
      ...(typeof count === 'number' && {
        badge: count.toString(),
      }),
    });

    return this;
  }

  addSeeCapToggler(value: unknown, label: string): MenuBuilder {
    this.menu.push({ value, label });

    return this;
  }

  build(): MenuItem[] {
    return [...this.menu];
  }
}
