import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'ppg-circle',
  standalone: true,
  template: `<svg
    tabindex="0"
    class="icon"
    xmlns="http://www.w3.org/2000/svg"
    width="46"
    height="46"
    viewBox="0 0 46 46"
    fill="none">
    <g filter="url(#filter0_bi_14820_6639)">
      <circle cx="23" cy="23" r="22" fill="black" fill-opacity="0.25" />
      <circle
        cx="23"
        cy="23"
        r="22.2292"
        stroke="url(#paint0_linear_14820_6639)"
        stroke-opacity="0.2"
        stroke-width="0.458333" />
    </g>
    <defs>
      <filter
        id="filter0_bi_14820_6639"
        x="-8.62492"
        y="-8.62467"
        width="63.2498"
        height="63.2493"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="4.58333" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_14820_6639" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_14820_6639" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha" />
        <feMorphology radius="0.669375" operator="dilate" in="SourceAlpha" result="effect2_innerShadow_14820_6639" />
        <feOffset dx="-0.334687" dy="0.334687" />
        <feGaussianBlur stdDeviation="0.167344" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.35 0" />
        <feBlend mode="normal" in2="shape" result="effect2_innerShadow_14820_6639" />
      </filter>
      <linearGradient
        id="paint0_linear_14820_6639"
        x1="39.8548"
        y1="4.01613"
        x2="23"
        y2="45"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CircleComponent {}
