"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QStringPath = void 0;
const QStringBasePath_1 = require("../base/QStringBasePath");
const QNumberPath_1 = require("./QNumberPath");
class QStringPath extends QStringBasePath_1.QStringBasePath {
  create(path) {
    return new QStringPath(path, this.converter);
  }
  indexOf(value) {
    return new QNumberPath_1.QNumberPath(this.getFunctionExpression("indexof" /* StringFilterFunctions.INDEX_OF */, value));
  }
  length() {
    const pathExpression = this.buildNoValueFunc("length" /* StringFilterFunctions.LENGTH */);
    return new QNumberPath_1.QNumberPath(pathExpression.getPath());
  }
  contains(value) {
    return this.buildFunctionFilter("contains" /* StringFilterFunctions.CONTAINS */, value);
  }
  matchesPattern(value) {
    return this.buildFunctionFilter("matchesPattern" /* StringFilterFunctions.MATCHES_PATTERN */, value);
  }
}
exports.QStringPath = QStringPath;
