"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QNoopPath = void 0;
class QNoopPath {
  constructor(path) {
    this.path = path;
    if (!path || !path.trim()) {
      throw new Error("Path must be supplied!");
    }
  }
  getPath() {
    return this.path;
  }
}
exports.QNoopPath = QNoopPath;
