import { Directive, effect, ElementRef, inject, input, output } from '@angular/core';

import { ColorUtils } from '@ppg/shared/color/services';

@Directive({
  standalone: true,
  selector: '[ppgFontColorBasedOnBackground]',
})
export class FontColorBasedOnBackgroundDirective {
  readonly backgroundRGB = input<string>();
  readonly darkFontColorValue = input<string>('var(--fg-primary)');
  readonly element = inject(ElementRef);
  #colorUtils = inject(ColorUtils);

  readonly fontSwitchedToLight = output<boolean>();

  constructor() {
    effect(() => {
      const rgb = this.backgroundRGB();
      this.#setFontStyle(rgb);
    });
  }

  #setFontStyle(rgb: string | undefined): void {
    let color;

    if (this.#colorUtils.isLight(rgb)) {
      this.fontSwitchedToLight.emit(false);
      color = this.darkFontColorValue();
    } else {
      this.fontSwitchedToLight.emit(true);
      color = 'var(--fg-white)';
    }
    this.element.nativeElement.style.color = color;
  }
}
