@if (content().imageWithColorsUsed; as imageWithColorsUsed) {
  <ppg-image-with-colors-used
    [content]="imageWithColorsUsed.content"
    [imageStyle]="imageStyle"
    [buttonBottomOffset]="captionOffsetHeight()"
    [loading]="imageLoading" />
} @else {
  @if (content().image; as image) {
    <ppg-image
      [url]="image.url"
      [isResponsive]="true"
      [width]="image.width"
      [height]="image.height"
      [imageStyle]="imageStyle"
      [loading]="imageLoading" />
  } @else {
    <div class="error flex flex-column align-items-center justify-content-center">
      <img [ngSrc]="imageUnavailableAsset.url" [alt]="imageUnavailableAsset.description" width="32" height="32" />
      <p class="error-message">{{ imageUnavailableAsset.description }}</p>
    </div>
  }
}

@if (showCaption()) {
  <section #caption class="caption">
    @if (content().headline; as headline) {
      <h5 class="headline" [class.mb-3]="hasDescription()">{{ headline }}</h5>
    }

    @if (content().description; as description) {
      <p class="description">{{ description }}</p>
    }
  </section>
}
