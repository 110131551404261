"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QDateTimeV2Param = exports.DATE_TIME_V2_TYPE_PREFIX = void 0;
const QParam_1 = require("../QParam");
const UrlParamHelper_1 = require("../UrlParamHelper");
exports.DATE_TIME_V2_TYPE_PREFIX = "datetime";
const getUrlConformValue = value => {
  return (0, UrlParamHelper_1.formatParamWithTypePrefix)(exports.DATE_TIME_V2_TYPE_PREFIX, value);
};
const parseValueFromUrl = urlConformValue => {
  return (0, UrlParamHelper_1.parseWithTypePrefix)(exports.DATE_TIME_V2_TYPE_PREFIX, urlConformValue);
};
class QDateTimeV2Param extends QParam_1.QParam {
  constructor() {
    super(...arguments);
    this.getUrlConformValue = getUrlConformValue;
    this.parseValueFromUrl = parseValueFromUrl;
  }
}
exports.QDateTimeV2Param = QDateTimeV2Param;
