import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { MessageModule } from 'primeng/message';
import { SkeletonModule } from 'primeng/skeleton';

import { AvailabilityStatus, ResourceItem } from '@ppg/core/enums';
import { ResourceService } from '@ppg/shared/resource';
@Component({
  selector: 'ppg-inventory-status',
  standalone: true,
  imports: [MessageModule, SkeletonModule],
  templateUrl: './inventory-status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InventoryStatusComponent {
  readonly #resourceService = inject(ResourceService);

  status = input<AvailabilityStatus>();
  comment = input<string>('');
  inProgress = input<boolean>(true);

  readonly staticContent = computed(() => ({
    [AvailabilityStatus.AVAILABLE]: {
      text: this.#resourceService.getResourceByKey(ResourceItem.INVENTORY_DETAILS_AVAILABLE),
      severity: 'success',
    },
    [AvailabilityStatus.TO_BE_CONFIRMED]: {
      text: this.#resourceService.getResourceByKey(ResourceItem.INVENTORY_DETAILS_TO_BE_CONFIRMED),
      severity: 'warn',
    },
    [AvailabilityStatus.NOT_AVAILABLE]: {
      text: this.#resourceService.getResourceByKey(ResourceItem.INVENTORY_DETAILS_OUT_OF_STOCK),
      severity: 'error',
    },
  }));
}
