import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  computed,
  inject,
  input,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { distinctUntilChanged } from 'rxjs';

import { CodeIframeWidgetContent } from '@ppg/core/models';

import { SafeUrlPipe } from '../../../lib/common/pipes/safe-youtube-url/safe-url.pipe';
import { MediaQueryService } from '../../../lib/common/services/media-query.service';

@Component({
  selector: 'ppg-iframe-widget',
  standalone: true,
  imports: [SafeUrlPipe],
  templateUrl: './iframe-widget.component.html',
  styleUrl: './iframe-widget.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IFrameWidgetComponent implements AfterViewInit {
  readonly content = input.required<CodeIframeWidgetContent>();
  readonly destroyRef = inject(DestroyRef);

  mediaService = inject(MediaQueryService);
  height = signal('');

  ngAfterViewInit(): void {
    this.mediaService
      .mediaQuery('max', 'MIN_WIDTH_DESKTOP')
      .pipe(takeUntilDestroyed(this.destroyRef), distinctUntilChanged())
      .subscribe((isMobile) => {
        const currentHeight = isMobile ? this.content().heightMobile : this.content().heightDesktop;
        this.height.set(currentHeight);
      });
  }

  public hasBorderHidden = computed(() => this.content().frameBorder === 0);
}
