import { CommonModule, DOCUMENT } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  RendererFactory2,
  inject,
  Renderer2,
  AfterViewInit,
  ElementRef,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { RadioButtonModule } from 'primeng/radiobutton';

import { SortOption } from '@ppg/core/models';

import { SortByDialogInput } from '../models/sort-by-dialog-input.model';

@Component({
  selector: 'ppg-sort-by-dialog',
  imports: [CommonModule, RadioButtonModule, FormsModule],
  templateUrl: './sort-by-dialog.component.html',
  styleUrls: ['./sort-by-dialog.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SortByDialogComponent implements AfterViewInit {
  readonly sortingOptions: SortOption[] | undefined;
  readonly #document: Document = inject(DOCUMENT);
  readonly #renderer: Renderer2 = inject(RendererFactory2).createRenderer(null, null);

  protected selectedValue: SortOption | undefined;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig<SortByDialogInput>,
  ) {
    this.sortingOptions = config.data?.sortOptions;
    this.selectedValue = config.data?.selectedSortOption;
    this.#setupDialog(config);
    this.#handleOverflowIfNeeded(config);
  }

  ngAfterViewInit(): void {
    this.#wrapTheDialogWithUniqueId();
    this.#wrapTheDialogWithRelativeContainer(this.config);
  }

  switchSortingOption(option: SortOption) {
    this.ref.close(option);
  }

  #setupDialog(config: DynamicDialogConfig<SortByDialogInput>) {
    this.#setupHeader(config);
    this.#handleStyling(config);
  }

  #setupHeader(config: DynamicDialogConfig<SortByDialogInput>) {
    if (config.data?.header) {
      config.templates = {
        header: SortByDialogHeaderComponent,
        ...config.templates,
      };
    }
  }

  #handleStyling(config: DynamicDialogConfig<SortByDialogInput>) {
    config.focusOnShow = false;
    config.maskStyleClass = 'transparent-mask';

    this.#handleAppendTo(config);
  }

  #handleAppendTo(config: DynamicDialogConfig<SortByDialogInput>) {
    const appendTo = this.#document.querySelector(config.data?.appendToQuerySelector ?? '');
    const minWidth = config.data?.minWidth ?? '288px';
    const defaultStyleClass = `ppg-sort-by-dialog ${config.styleClass ?? ''}`;

    if (!appendTo) {
      config.styleClass = defaultStyleClass;
      this.config.style = { ...config.style, 'min-width': minWidth };

      return;
    }

    config.styleClass = `ppg-sort-by-dialog-absolute ${defaultStyleClass}`;
    config.style = {
      ...config.style,
      'z-index': '1001',
      'min-width': minWidth,
      top: '8px',
      left: 0,
      right: 0,
    };
  }

  #handleOverflowIfNeeded(config: DynamicDialogConfig<SortByDialogInput>) {
    if (config.data?.appendToQuerySelector) {
      config.contentStyle = { ...config.contentStyle, 'overflow-y': 'visible' };
    }
  }

  #wrapTheDialogWithRelativeContainer(config: DynamicDialogConfig<SortByDialogInput>) {
    const dialog = this.#document.querySelector('p-dynamicdialog.p-element');
    const dialogBodyContainer = this.#document.querySelector('.ppg-sort-by-dialog-absolute')?.parentNode;
    const appendToElement = this.#document.querySelector(config.data?.appendToQuerySelector ?? '');

    if (dialog && appendToElement && dialogBodyContainer) {
      const wrapper = appendToElement.parentNode;
      this.#renderer.setAttribute(wrapper, 'ppg-sort-by-dialog-wrapper', '');

      this.#renderer.setStyle(dialogBodyContainer, 'position', 'relative');
      this.#renderer.setStyle(dialogBodyContainer, 'z-index', '1000');

      this.#renderer.appendChild(wrapper, dialog);
    }
  }

  #wrapTheDialogWithUniqueId() {
    const dialog = this.#document.querySelector('p-dynamicdialog.p-element');

    if (dialog) {
      this.#renderer.setAttribute(dialog, 'id', 'ppg-sort-by-dialog');
    }
  }
}

@Component({
  selector: 'ppg-sort-by-dialog-header',
  standalone: true,
  imports: [DividerModule, ButtonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="flex flex-column">
      <div class="flex flex-row justify-content-between align-items-center">
        <h4 class="flex my-0">{{ header }}</h4>
        <p-button
          icon="flex pi pi-times"
          [rounded]="true"
          [text]="true"
          severity="secondary"
          (onClick)="closeDialog()" />
      </div>
      <p-divider class="flex"></p-divider>
    </div>
  `,
})
export class SortByDialogHeaderComponent implements AfterViewInit {
  readonly #headerElement: HTMLElement = inject(ElementRef).nativeElement;
  readonly #renderer: Renderer2 = inject(RendererFactory2).createRenderer(null, null);

  header: string;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig<SortByDialogInput>,
  ) {
    this.header = config.data?.header ?? '';
  }

  ngAfterViewInit(): void {
    this.#renderer.addClass(this.#headerElement, 'w-full');
  }

  closeDialog() {
    this.ref.close();
  }
}
