"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QNumberV2Path = void 0;
const UrlParamHelper_1 = require("../../param/UrlParamHelper");
const QNumberV2Base_1 = require("./QNumberV2Base");
class QNumberV2Path extends QNumberV2Base_1.QNumberV2Base {
  createNewFunctionPath(func) {
    return new QNumberV2Path((0, UrlParamHelper_1.buildFunctionExpression)(func, this.path), this.converter);
  }
  createNewOperationPath(operator, value) {
    const converted = this.convertInput(value);
    return new QNumberV2Path((0, UrlParamHelper_1.buildOperatorExpression)(this.path, operator, converted), this.converter);
  }
}
exports.QNumberV2Path = QNumberV2Path;
