import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';

import { InlineImageContentComponent } from '@ppg/core/models';

import { ImageComponent } from '../image/image.component';
import { ImageWithColorsUsedComponent } from '../image-with-colors-used/image-with-colors-used.component';

@Component({
  selector: 'ppg-inline-image',
  standalone: true,
  imports: [CommonModule, ImageComponent, ImageWithColorsUsedComponent],
  templateUrl: './inline-image.component.html',
  styleUrl: './inline-image.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InlineImageComponent {
  readonly content = input.required<InlineImageContentComponent>();
}
