import { ReadyMixedColor } from '@ppg/shared/ecommerce-api';

export enum PaintTypes {
  Base = 'Base',
  CustomMixed = 'Custom Mixed',
  ReadyMixed = 'Ready Mixed',
  White = 'White',
}

export enum PaintType {
  Base = 'Base',
  CustomMixed = 'CustomMixed',
  ReadyMixed = 'ReadyMixed',
  White = 'White',
}

export enum AssetQuality {
  SmallOptimized54 = 'SmallOptimized54',
  MediumOptimized102 = 'MediumOptimized102',
  LargeOptimized240 = 'LargeOptimized240',
  OriginalImage = 'OriginalImage',
  Large600x600 = 'Large600x600',
}

export enum AssetType {
  DataSheet = 'DataSheet',
  Other = 'Other',
  Background = 'Background',
  BrandLogo = 'BrandLogo',
  CaseStudy = 'CaseStudy',
  Certificate = 'Certificate',
  CertificationLogo = 'CertificationLogo',
  ColorImage = 'ColorImage',
  EPD = 'EPD',
  Back = 'Back',
  Front = 'Front',
  FrontAngle = 'FrontAngle',
  Guide = 'Guide',
  InformationSheet = 'InformationSheet',
  Left = 'Left',
  Literature = 'Literature',
  ProductDeclaration = 'ProductDeclaration',
  Right = 'Right',
  SafetyDataSheet = 'SafetyDataSheet',
  SafetySymbol = 'SafetySymbol',
  SystemSheet = 'SystemSheet',
  UsageLogo = 'UsageLogo',
  USPLogo = 'USPLogo',
  Video = 'Video',
  Ambiance = 'Ambiance',
  Top = 'Top',
  Front2 = 'Front2',
}

export enum ReferenceType {
  Empty = 'Empty',
  CrossSelling = 'CrossSelling',
  UpSelling = 'UpSelling',
  Combideal = 'Combideal',
  ProductFamily = 'ProductFamily',
  AvailableProduct = 'AvailableProduct',
}

export enum NodeType {
  Leaf = 'Leaf',
  Node = 'Node',
  Root = 'Root',
}

export interface ExtendedTransactableProduct extends Product {
  readyMixedColors: ReadyMixedColor[];
}

export interface Product {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Id` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  Id: string;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ProductNumber` |
   * | Type | `Edm.String` |
   */
  ProductNumber: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `CategoryIds` |
   * | Type | `Collection(Edm.String)` |
   */
  CategoryIds: Array<string>;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ParentProductCode` |
   * | Type | `Edm.String` |
   */
  ParentProductCode: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `MarketingData` |
   * | Type | `Ppg.Enterprise.Api.Pim.Outbound.Contracts.Models.ProductMarketingData` |
   */
  MarketingData: ProductMarketingData | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `TechnicalData` |
   * | Type | `Ppg.Enterprise.Api.Pim.Outbound.Contracts.Models.TechnicalData` |
   */
  TechnicalData: TechnicalData | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `EhsData` |
   * | Type | `Ppg.Enterprise.Api.Pim.Outbound.Contracts.Models.EhsData` |
   */
  EhsData: EhsData | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `StatusData` |
   * | Type | `Ppg.Enterprise.Api.Pim.Outbound.Contracts.Models.StatusData` |
   */
  StatusData: StatusData | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `References` |
   * | Type | `Collection(Ppg.Enterprise.Api.Pim.Outbound.Contracts.Models.Reference)` |
   */
  References: Array<Reference>;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Attributes` |
   * | Type | `Collection(Ppg.Enterprise.Api.Pim.Outbound.Contracts.Models.Attribute)` |
   */
  Attributes: Array<Attribute>;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Items` |
   * | Type | `Collection(Ppg.Enterprise.Api.Pim.Outbound.Contracts.Models.Item)` |
   */
  Items?: Array<Item>;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Assets` |
   * | Type | `Collection(Ppg.Enterprise.Api.Pim.Outbound.Contracts.Models.MediaAsset)` |
   */
  Assets?: Array<MediaAsset>;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Icons` |
   * | Type | `Collection(Edm.String)` |
   */
  Icons?: Array<string>;
}

export type ProductId = string | { Id: string };

export interface EditableProduct extends Partial<Pick<Product, 'ProductNumber' | 'CategoryIds' | 'ParentProductCode'>> {
  MarketingData?: EditableProductMarketingData | null;
  TechnicalData?: EditableTechnicalData | null;
  EhsData?: EditableEhsData | null;
  StatusData?: EditableStatusData | null;
  References?: Array<EditableReference>;
  Attributes?: Array<EditableAttribute>;
}

export interface Category {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Id` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  Id: string;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Name` |
   * | Type | `Collection(Ppg.Enterprise.Api.Pim.Outbound.Contracts.Models.TranslatedText)` |
   */
  Name: Array<TranslatedText>;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Description` |
   * | Type | `Collection(Ppg.Enterprise.Api.Pim.Outbound.Contracts.Models.TranslatedText)` |
   */
  Description: Array<TranslatedText>;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `MetaTitle` |
   * | Type | `Collection(Ppg.Enterprise.Api.Pim.Outbound.Contracts.Models.TranslatedText)` |
   */
  MetaTitle: Array<TranslatedText>;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Identifier` |
   * | Type | `Edm.String` |
   */
  Identifier: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ParentId` |
   * | Type | `Edm.String` |
   */
  ParentId: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `NodeType` |
   * | Type | `Ppg.Enterprise.Api.Pim.Outbound.Contracts.Enumerators.NodeType` |
   * | Nullable | `false` |
   */
  NodeType: NodeType;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `WebHierarchyNumber` |
   * | Type | `Edm.String` |
   */
  WebHierarchyNumber: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `WebHierarchyLevel` |
   * | Type | `Edm.Int32` |
   */
  WebHierarchyLevel: number | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `StatusData` |
   * | Type | `Ppg.Enterprise.Api.Pim.Outbound.Contracts.Models.StatusData` |
   */
  StatusData: StatusData | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Assets` |
   * | Type | `Collection(Ppg.Enterprise.Api.Pim.Outbound.Contracts.Models.MediaAsset)` |
   */
  Assets?: Array<MediaAsset>;
}

export type CategoryId = string | { Id: string };

export interface EditableCategory
  extends Pick<Category, 'NodeType'>,
    Partial<Pick<Category, 'Identifier' | 'ParentId' | 'WebHierarchyNumber' | 'WebHierarchyLevel'>> {
  Name?: Array<EditableTranslatedText>;
  Description?: Array<EditableTranslatedText>;
  MetaTitle?: Array<EditableTranslatedText>;
  StatusData?: EditableStatusData | null;
}

export interface Item {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Id` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  Id: string;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ProductId` |
   * | Type | `Edm.String` |
   */
  ProductId: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ArticleNumber` |
   * | Type | `Edm.String` |
   */
  ArticleNumber: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `KMatReference` |
   * | Type | `Edm.String` |
   */
  KMatReference: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `SkuCode` |
   * | Type | `Edm.String` |
   */
  SkuCode: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `SkuNumber` |
   * | Type | `Edm.String` |
   */
  SkuNumber: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `SalesText` |
   * | Type | `Collection(Ppg.Enterprise.Api.Pim.Outbound.Contracts.Models.TranslatedText)` |
   */
  SalesText: Array<TranslatedText>;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Warning2` |
   * | Type | `Edm.String` |
   */
  Warning2: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `MarketingData` |
   * | Type | `Ppg.Enterprise.Api.Pim.Outbound.Contracts.Models.ItemMarketingData` |
   */
  MarketingData: ItemMarketingData | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `LogisticsData` |
   * | Type | `Ppg.Enterprise.Api.Pim.Outbound.Contracts.Models.LogisticsData` |
   */
  LogisticsData: LogisticsData | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `StatusData` |
   * | Type | `Ppg.Enterprise.Api.Pim.Outbound.Contracts.Models.StatusData` |
   */
  StatusData: StatusData | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `References` |
   * | Type | `Collection(Ppg.Enterprise.Api.Pim.Outbound.Contracts.Models.Reference)` |
   */
  References: Array<Reference>;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Attributes` |
   * | Type | `Collection(Ppg.Enterprise.Api.Pim.Outbound.Contracts.Models.Attribute)` |
   */
  Attributes: Array<Attribute>;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Prices` |
   * | Type | `Collection(Ppg.Enterprise.Api.Pim.Outbound.Contracts.Models.ItemPrice)` |
   */
  Prices: Array<ItemPrice>;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Assets` |
   * | Type | `Collection(Ppg.Enterprise.Api.Pim.Outbound.Contracts.Models.MediaAsset)` |
   */
  Assets?: Array<MediaAsset>;
}

export type ItemId = string | { Id: string };

export interface EditableItem
  extends Partial<Pick<Item, 'ProductId' | 'ArticleNumber' | 'KMatReference' | 'SkuCode' | 'SkuNumber' | 'Warning2'>> {
  SalesText?: Array<EditableTranslatedText>;
  MarketingData?: EditableItemMarketingData | null;
  LogisticsData?: EditableLogisticsData | null;
  StatusData?: EditableStatusData | null;
  References?: Array<EditableReference>;
  Attributes?: Array<EditableAttribute>;
  Prices?: Array<EditableItemPrice>;
}

export interface MediaAsset {
  /**
   * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Id` |
   * | Type | `Edm.String` |
   * | Nullable | `false` |
   */
  Id: string;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `LanguageName` |
   * | Type | `Edm.String` |
   */
  LanguageName: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Name` |
   * | Type | `Edm.String` |
   */
  Name: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Title` |
   * | Type | `Edm.String` |
   */
  Title: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Description` |
   * | Type | `Edm.String` |
   */
  Description: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Channel` |
   * | Type | `Edm.String` |
   */
  Channel: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Url` |
   * | Type | `Edm.String` |
   */
  Url: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Quality` |
   * | Type | `Ppg.Enterprise.Api.Pim.Outbound.Contracts.Enumerators.AssetQuality` |
   */
  Quality: AssetQuality | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Type` |
   * | Type | `Ppg.Enterprise.Api.Pim.Outbound.Contracts.Enumerators.AssetType` |
   */
  Type: AssetType | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Sequence` |
   * | Type | `Edm.Int32` |
   */
  Sequence: number;
}

export type MediaAssetId = string | { Id: string };

export interface EditableMediaAsset
  extends Partial<
    Pick<MediaAsset, 'LanguageName' | 'Name' | 'Title' | 'Description' | 'Channel' | 'Url' | 'Quality' | 'Type'>
  > {}

export interface ProductMarketingData {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Name` |
   * | Type | `Edm.String` |
   */
  Name: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `PrimarySKU` |
   * | Type | `Edm.String` |
   */
  PrimarySKU: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `FunctionalName` |
   * | Type | `Collection(Ppg.Enterprise.Api.Pim.Outbound.Contracts.Models.TranslatedText)` |
   */
  FunctionalName: Array<TranslatedText>;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `OverviewDescription` |
   * | Type | `Collection(Ppg.Enterprise.Api.Pim.Outbound.Contracts.Models.TranslatedText)` |
   */
  OverviewDescription: Array<TranslatedText>;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `LongMarketingText` |
   * | Type | `Collection(Ppg.Enterprise.Api.Pim.Outbound.Contracts.Models.TranslatedText)` |
   */
  LongMarketingText: Array<TranslatedText>;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ShortMarketingText` |
   * | Type | `Collection(Ppg.Enterprise.Api.Pim.Outbound.Contracts.Models.TranslatedText)` |
   */
  ShortMarketingText: Array<TranslatedText>;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `MetaTitle` |
   * | Type | `Edm.String` |
   */
  MetaTitle: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `MetaDescription` |
   * | Type | `Collection(Ppg.Enterprise.Api.Pim.Outbound.Contracts.Models.TranslatedText)` |
   */
  MetaDescription: Array<TranslatedText>;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Usp` |
   * | Type | `Edm.String` |
   */
  Usp: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Banner` |
   * | Type | `Edm.String` |
   */
  Banner: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `BrandId` |
   * | Type | `Edm.String` |
   */
  BrandId: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `BrandName` |
   * | Type | `Edm.String` |
   */
  BrandName: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ProductSorting` |
   * | Type | `Edm.String` |
   */
  ProductSorting: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ProfileForProductFiltering` |
   * | Type | `Collection(Edm.String)` |
   */
  ProfileForProductFiltering: Array<string>;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `KeywordsTags` |
   * | Type | `Edm.String` |
   */
  KeywordsTags: string | null;
}

export interface EditableProductMarketingData
  extends Partial<
    Pick<
      ProductMarketingData,
      | 'Name'
      | 'PrimarySKU'
      | 'MetaTitle'
      | 'Usp'
      | 'Banner'
      | 'BrandId'
      | 'BrandName'
      | 'ProductSorting'
      | 'ProfileForProductFiltering'
      | 'KeywordsTags'
    >
  > {
  FunctionalName?: Array<EditableTranslatedText>;
  OverviewDescription?: Array<EditableTranslatedText>;
  LongMarketingText?: Array<EditableTranslatedText>;
  ShortMarketingText?: Array<EditableTranslatedText>;
  MetaDescription?: Array<EditableTranslatedText>;
}

export interface TranslatedText {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Language` |
   * | Type | `Edm.String` |
   */
  Language: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Translation` |
   * | Type | `Edm.String` |
   */
  Translation: string | null;
}

export interface EditableTranslatedText extends Partial<Pick<TranslatedText, 'Language' | 'Translation'>> {}

export interface TechnicalData {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ColourbankProductCode` |
   * | Type | `Edm.String` |
   */
  ColourbankProductCode: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ConditionsForUse` |
   * | Type | `Edm.String` |
   */
  ConditionsForUse: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `DryingTime` |
   * | Type | `Edm.Decimal` |
   */
  DryingTime: number | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `OverpaintableAfter` |
   * | Type | `Edm.Decimal` |
   */
  OverpaintableAfter: number | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `CoverageText` |
   * | Type | `Edm.String` |
   */
  CoverageText: string | null;
}

export interface EditableTechnicalData
  extends Partial<
    Pick<
      TechnicalData,
      'ColourbankProductCode' | 'ConditionsForUse' | 'DryingTime' | 'OverpaintableAfter' | 'CoverageText'
    >
  > {}

export interface EhsData {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `UrlSafetyDataSheet` |
   * | Type | `Edm.String` |
   */
  UrlSafetyDataSheet: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Disclaimer` |
   * | Type | `Edm.String` |
   */
  Disclaimer: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `SignalWordEu` |
   * | Type | `Edm.String` |
   */
  SignalWordEu: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `GhsSymbolsEu` |
   * | Type | `Edm.String` |
   */
  GhsSymbolsEu: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `realVOCValue` |
   * | Type | `Edm.String` |
   */
  RealVOCValue: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Hphrases` |
   * | Type | `Collection(Edm.String)` |
   */
  Hphrases: string[];
}

export interface EditableEhsData
  extends Partial<
    Pick<EhsData, 'UrlSafetyDataSheet' | 'Disclaimer' | 'SignalWordEu' | 'GhsSymbolsEu' | 'RealVOCValue'>
  > {}

export interface StatusData {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `IsActive` |
   * | Type | `Edm.Boolean` |
   */
  IsActive: boolean | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `IsSellable` |
   * | Type | `Edm.Boolean` |
   */
  IsSellable: boolean | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Status` |
   * | Type | `Edm.String` |
   */
  Status: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `AvailabilityStartDate` |
   * | Type | `Edm.DateTimeOffset` |
   */
  AvailabilityStartDate: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `AvailabilityEndDate` |
   * | Type | `Edm.DateTimeOffset` |
   */
  AvailabilityEndDate: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `LastModifiedDate` |
   * | Type | `Edm.DateTimeOffset` |
   * | Nullable | `false` |
   */
  LastModifiedDate: string;
}

export interface EditableStatusData
  extends Pick<StatusData, 'LastModifiedDate'>,
    Partial<Pick<StatusData, 'IsActive' | 'IsSellable' | 'Status' | 'AvailabilityStartDate' | 'AvailabilityEndDate'>> {}

export interface ItemMarketingData {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Description` |
   * | Type | `Collection(Ppg.Enterprise.Api.Pim.Outbound.Contracts.Models.TranslatedText)` |
   */
  Description: Array<TranslatedText>;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `DescriptionSAP` |
   * | Type | `Edm.String` |
   */
  DescriptionSAP: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `MaterialType` |
   * | Type | `Edm.String` |
   */
  MaterialType: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `PaintTypes` |
   * | Type | `Collection(Ppg.Enterprise.Api.Pim.Outbound.Contracts.Models.PaintTypes)` |
   * | Nullable | `false` |
   */
  PaintTypes: Array<PaintTypes>;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `PaintType` |
   * | Type | `Edm.String` |
   */
  PaintType: string | null | PaintType;
}

export interface EditableItemMarketingData
  extends Pick<ItemMarketingData, 'PaintTypes'>,
    Partial<Pick<ItemMarketingData, 'DescriptionSAP' | 'MaterialType' | 'PaintType'>> {
  Description?: Array<EditableTranslatedText>;
}

export interface LogisticsData {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Gtin` |
   * | Type | `Edm.String` |
   */
  Gtin: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `InnerPackQuantity` |
   * | Type | `Edm.String` |
   */
  InnerPackQuantity: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `SizeDimensions` |
   * | Type | `Edm.String` |
   */
  SizeDimensions: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `UpcBarcode` |
   * | Type | `Edm.String` |
   */
  UpcBarcode: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `GrossWeight` |
   * | Type | `Edm.Decimal` |
   */
  GrossWeight: number | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `NetWeight` |
   * | Type | `Edm.Decimal` |
   */
  NetWeight: number | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `NetVolume` |
   * | Type | `Edm.Decimal` |
   */
  NetVolume: number | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `WeightUOM` |
   * | Type | `Edm.String` |
   */
  WeightUOM: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `VolumeUOM` |
   * | Type | `Edm.String` |
   */
  VolumeUOM: string | null;
}

export interface EditableLogisticsData
  extends Partial<
    Pick<
      LogisticsData,
      | 'Gtin'
      | 'InnerPackQuantity'
      | 'SizeDimensions'
      | 'UpcBarcode'
      | 'GrossWeight'
      | 'NetWeight'
      | 'NetVolume'
      | 'WeightUOM'
      | 'VolumeUOM'
    >
  > {}

export interface Reference {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Items` |
   * | Type | `Collection(Ppg.Enterprise.Api.Pim.Outbound.Contracts.Models.ItemReference)` |
   */
  Items: Array<ItemReference>;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Products` |
   * | Type | `Collection(Ppg.Enterprise.Api.Pim.Outbound.Contracts.Models.ProductReference)` |
   */
  Products: Array<ProductReference>;
}

export interface EditableReference {
  Items?: Array<EditableItemReference>;
  Products?: Array<EditableProductReference>;
}

export interface ItemReference {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ItemId` |
   * | Type | `Edm.String` |
   */
  ItemId: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ItemNumber` |
   * | Type | `Edm.String` |
   */
  ItemNumber: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Type` |
   * | Type | `Ppg.Enterprise.Api.Pim.Outbound.Contracts.Enumerators.ReferenceType` |
   * | Nullable | `false` |
   */
  Type: ReferenceType;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Sequence` |
   * | Type | `Edm.Int32` |
   */
  Sequence: number | null;
}

export interface EditableItemReference
  extends Pick<ItemReference, 'Type'>,
    Partial<Pick<ItemReference, 'ItemId' | 'ItemNumber' | 'Sequence'>> {}

export interface ProductReference {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ProductId` |
   * | Type | `Edm.String` |
   */
  ProductId: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ProductNumber` |
   * | Type | `Edm.String` |
   */
  ProductNumber: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Type` |
   * | Type | `Ppg.Enterprise.Api.Pim.Outbound.Contracts.Enumerators.ReferenceType` |
   * | Nullable | `false` |
   */
  Type: ReferenceType;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Sequence` |
   * | Type | `Edm.Int32` |
   */
  Sequence: number | null;
}

export interface EditableProductReference
  extends Pick<ProductReference, 'Type'>,
    Partial<Pick<ProductReference, 'ProductId' | 'ProductNumber' | 'Sequence'>> {}

export interface Attribute {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Name` |
   * | Type | `Edm.String` |
   */
  Name: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Values` |
   * | Type | `Collection(Edm.String)` |
   */
  Values: Array<string>;
}

export interface EditableAttribute extends Partial<Pick<Attribute, 'Name' | 'Values'>> {}

export interface ItemPrice {
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Amount` |
   * | Type | `Edm.Decimal` |
   * | Nullable | `false` |
   */
  Amount: number;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Currency` |
   * | Type | `Edm.String` |
   */
  Currency: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Territory` |
   * | Type | `Edm.String` |
   */
  Territory: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `Type` |
   * | Type | `Edm.String` |
   */
  Type: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `UnitUOM` |
   * | Type | `Edm.String` |
   */
  UnitUOM: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ValidFrom` |
   * | Type | `Edm.String` |
   */
  ValidFrom: string | null;
  /**
   *
   * OData Attributes:
   * |Attribute Name | Attribute Value |
   * | --- | ---|
   * | Name | `ValidTo` |
   * | Type | `Edm.String` |
   */
  ValidTo: string | null;
}

export interface EditableItemPrice
  extends Pick<ItemPrice, 'Amount'>,
    Partial<Pick<ItemPrice, 'Currency' | 'Territory' | 'Type' | 'UnitUOM' | 'ValidFrom' | 'ValidTo'>> {}
