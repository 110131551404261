"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QBasePath = void 0;
const IdentityConverter_1 = require("../../IdentityConverter");
const UrlParamHelper_1 = require("../../param/UrlParamHelper");
const BaseFunctions_1 = require("./BaseFunctions");
class QBasePath {
  constructor(path, converter = (0, IdentityConverter_1.getIdentityConverter)(), options = {
    nativeIn: false
  }) {
    this.path = path;
    this.converter = converter;
    this.options = options;
    this.convertInput = value => {
      if ((0, UrlParamHelper_1.isPathValue)(value)) {
        return value.getPath();
      }
      const converted = this.converter.convertTo(value);
      if (converted === null || converted === undefined) {
        throw new Error(`Value "${value}" converted to ${converted}!`);
      }
      return this.formatValue(converted);
    };
    this.ascending = (0, BaseFunctions_1.orderAscending)(this.path);
    this.asc = this.ascending;
    this.descending = (0, BaseFunctions_1.orderDescending)(this.path);
    this.desc = this.descending;
    this.isNull = (0, BaseFunctions_1.filterIsNull)(this.path);
    this.isNotNull = (0, BaseFunctions_1.filterIsNotNull)(this.path);
    this.equals = (0, BaseFunctions_1.filterEquals)(this.path, this.convertInput);
    this.eq = this.equals;
    this.notEquals = (0, BaseFunctions_1.filterNotEquals)(this.path, this.convertInput);
    this.ne = this.notEquals;
    this.lowerThan = (0, BaseFunctions_1.filterLowerThan)(this.path, this.convertInput);
    this.lt = this.lowerThan;
    this.lowerEquals = (0, BaseFunctions_1.filterLowerEquals)(this.path, this.convertInput);
    this.le = this.lowerEquals;
    this.greaterThan = (0, BaseFunctions_1.filterGreaterThan)(this.path, this.convertInput);
    this.gt = this.greaterThan;
    this.greaterEquals = (0, BaseFunctions_1.filterGreaterEquals)(this.path, this.convertInput);
    this.ge = this.greaterEquals;
    this.in = this.options.nativeIn ? (0, BaseFunctions_1.filterIn)(this.path, this.convertInput) : (0, BaseFunctions_1.filterInEmulated)(this.path, this.convertInput);
    if (!path || !path.trim()) {
      throw new Error("Path must be supplied!");
    }
  }
  /**
   * Get the path to this property.
   *
   * @returns this property path
   */
  getPath() {
    return this.path;
  }
}
exports.QBasePath = QBasePath;
