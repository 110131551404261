@if (warranty()) {
  <p-card styleClass="noPaddings noBorder">
    <div
      data-test-id="warranty.mainContentSection"
      class="color-warranty-container flex gap-4 flex-column md:flex-row justify-content-start p-5 md:p-9">
      <div class="text-center text-white">
        <p-image
          data-test-id="warranty.image"
          [src]="image()?.url"
          [alt]="image()?.description"
          [width]="image()?.width?.toString()"
          [height]="image()?.height?.toString()"
          class="flex" />
      </div>

      <div>
        <h3 class="my-1 mx-0 u-break-word-overflow" data-test-id="warranty.headline">
          {{ warranty()?.content?.headline }}
        </h3>

        <div
          class="pb-1 u-break-word-overflow"
          data-test-id="warranty.content"
          [innerHTML]="warranty()?.content?.content?.[0]?.content"></div>

        <div class="flex gap-2">
          <p-button
            styleClass="text-left p-0"
            data-test-id="warranty.ctaButton"
            [link]="true"
            severity="secondary"
            [label]="ctaContent()?.text"
            (onClick)="navigateTo(cta())"></p-button>
        </div>
      </div>
    </div>
  </p-card>
}
