<p-dialog [(visible)]="visible" [dismissableMask]="true" [modal]="true">
  <ng-template pTemplate="headless">
    <section class="dialog-container flex flex-start align-items-center">
      <div class="dialog-close-button">
        <ng-container *ngTemplateOutlet="closeButton"></ng-container>
      </div>

      <section class="image-frame-wrapper flex flex-column flex-start gap-2 h-full">
        @if (content().image; as image) {
          <ppg-image
            class="h-full"
            [url]="image.url"
            [width]="image.width"
            [height]="image.height"
            [imageStyle]="{
              display: 'block',
              width: '100%',
              height: '100%',
              'object-fit': 'contain',
            }" />
        }
      </section>

      <section class="content-wrapper flex flex-column flex-start align-self-stretch gap-3">
        <section class="flex flex-column flex-start gap-3">
          <div class="flex justify-content-end align-items-start gap-2">
            <div class="content-close-button">
              <ng-container *ngTemplateOutlet="closeButton"></ng-container>
            </div>
          </div>

          @if (content().headline; as headline) {
            <h4 class="headline m-0">{{ headline }}</h4>
          }
          @if (content().description; as description) {
            <p class="description m-0">{{ description }}</p>
          }
        </section>

        <section class="colors-list-wrapper flex-grow-1">
          <ppg-colors-used-list class="h-full" [productNumbers]="content().productNumbers"></ppg-colors-used-list>
        </section>
      </section>

      <ng-template #closeButton>
        <p-button severity="secondary" size="small" outlined="true" (onClick)="closeDialog()">
          <svg class="pds-icon">
            <use href="assets/pds-icons.svg#close" />
          </svg>
        </p-button>
      </ng-template>
    </section>
  </ng-template>
</p-dialog>
