import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({ name: 'safeUrl', standalone: true })
export class SafeUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(url: string): SafeResourceUrl {
    if (this.isValidUrl(url) && this.isSafeUrl(url)) {
      //NOSONAR : Bypassing sanitization is safe here
      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
    return '';
  }

  private isSafeUrl(url: string) {
    try {
      const parsedUrl = new URL(url);
      return ['http:', 'https:'].includes(parsedUrl.protocol);
    } catch (error) {
      return false;
    }
  }

  private isValidUrl(url: string) {
    try {
      return !!new URL(url);
    } catch (error) {
      return false;
    }
  }
}
