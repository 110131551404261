import { DOCUMENT } from '@angular/common';
import { Directive, HostListener, inject, input } from '@angular/core';

@Directive({
  standalone: true,
  selector: 'p-paginator[ppgScrollOnPageChange], p-table[ppgScrollOnPageChange]',
})
export class ScrollOnPageChangeDirective {
  readonly #document = inject(DOCUMENT);

  target = input<HTMLElement | string | undefined>(undefined, { alias: 'ppgScrollOnPageChange' });

  @HostListener('onPageChange')
  handlePaginatorPageChange() {
    this.handlePageChange();
  }

  @HostListener('onPage')
  handleTablePageChange() {
    this.handlePageChange();
  }

  handlePageChange() {
    const target = this.target();
    let scrollTarget: HTMLElement | null = null;

    if (target instanceof HTMLElement) {
      scrollTarget = target;
    } else if (typeof target === 'string' && !!target) {
      scrollTarget = this.#document.querySelector(target);
    }

    if (scrollTarget) {
      scrollTarget.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    } else {
      this.#document.body.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }
}
