"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QEntityCollectionPath = void 0;
const QFilterExpression_1 = require("../QFilterExpression");
class QEntityCollectionPath {
  constructor(path, qEntityFn) {
    this.path = path;
    this.qEntityFn = qEntityFn;
    if (!path || !path.trim()) {
      throw new Error("Path must be supplied!");
    }
    if (!qEntityFn || typeof qEntityFn !== "function") {
      throw new Error("Function which returns query object must be supplied!");
    }
  }
  getPath() {
    return this.path;
  }
  getEntity(withPrefix = false) {
    return new (this.qEntityFn())(withPrefix ? this.path : undefined);
  }
  isCollectionType() {
    return true;
  }
  lambdaFunction(operationName, fn, prefix = "a") {
    // create new qObject with given prefix
    const qEntity = new (this.qEntityFn())(prefix);
    const expression = fn ? fn(qEntity) : undefined;
    // if no expression was provided => function call without args
    if (!expression || !expression.toString()) {
      return new QFilterExpression_1.QFilterExpression(`${this.path}/${operationName}()`);
    }
    return new QFilterExpression_1.QFilterExpression(`${this.path}/${operationName}(${prefix}:${expression})`);
  }
  any(fn, prefix) {
    return this.lambdaFunction("any" /* LambdaFunctions.ANY */, fn, prefix);
  }
  all(fn, prefix) {
    return this.lambdaFunction("all" /* LambdaFunctions.ALL */, fn, prefix);
  }
}
exports.QEntityCollectionPath = QEntityCollectionPath;
