import { ContentComponentType } from '@ppg/core/enums';

import { ComponentImageWithColorsUsed } from './component-image-with-colors-used.model';
import { CTA } from './cta.model';
import { ImageComponentModel } from './image.model';
import { RichTextModel } from './rich-text.model';

export interface ComponentContentBanner {
  type: ContentComponentType.CONTENT_BANNER;
  content: ComponentContentBannerContent;
}

export interface ComponentContentBannerContent {
  showLogo: boolean;
  headline?: string;
  subheading?: string;
  image?: ImageComponentModel;
  imageWithColorsUsed?: ComponentImageWithColorsUsed;
  content: RichTextModel[];
  cta?: CTA;
  colors?: ContentBannerColors;
}

export enum ThemeColor {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
}

export interface ContentBannerColors {
  backgroundColor?: ThemeColor;
  textColor?: ThemeColor;
}
