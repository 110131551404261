"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QStringParam = void 0;
const QParam_1 = require("../QParam");
const UrlParamHelper_1 = require("../UrlParamHelper");
class QStringParam extends QParam_1.QParam {
  constructor() {
    super(...arguments);
    this.getUrlConformValue = UrlParamHelper_1.formatParamWithQuotes;
    this.parseValueFromUrl = UrlParamHelper_1.parseWithQuotes;
  }
}
exports.QStringParam = QStringParam;
