"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QTimeOfDayPath = void 0;
const QBasePath_1 = require("../base/QBasePath");
const DateTimeFunctions_1 = require("./DateTimeFunctions");
const IdentityFormatter_1 = require("./IdentityFormatter");
class QTimeOfDayPath extends QBasePath_1.QBasePath {
  constructor() {
    super(...arguments);
    this.formatValue = IdentityFormatter_1.identityFormatter;
    this.hour = (0, DateTimeFunctions_1.hourFn)(this.path);
    this.minute = (0, DateTimeFunctions_1.minuteFn)(this.path);
    this.second = (0, DateTimeFunctions_1.secondFn)(this.path);
  }
}
exports.QTimeOfDayPath = QTimeOfDayPath;
