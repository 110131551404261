@if (isValid()) {
  <a class="color valid" [routerLink]="link()" [target]="urlTarget">
    @if (canPreviewColor()) {
      <div
        class="color-preview"
        ppgBackground
        [rgb]="rgb()"
        [imageUrl]="image()"
        (onImageLoadError)="imageError.set(true)"
        (onImageLoaded)="imageError.set(false)"></div>
    } @else {
      <ng-container [ngTemplateOutlet]="noColor"></ng-container>
    }

    <div class="color-caption flex flex-column justify-content-center align-content-start">
      @if (colorName()) {
        <p class="color-name">{{ colorName() }}</p>
      }
      @if (showColorCode && colorCode()) {
        <p class="color-code">{{ colorCode() }}</p>
      }
    </div>
  </a>
} @else {
  <div class="color invalid">
    <ng-container [ngTemplateOutlet]="noColor"></ng-container>

    <span>{{ colorUnavailableAsset.description }}</span>
  </div>
}

<ng-template #noColor>
  <div class="color-preview invalid">
    <img [ngSrc]="colorUnavailableAsset.url" [alt]="colorUnavailableAsset.description" width="48" height="48" />
  </div>
</ng-template>
