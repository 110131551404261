"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QCollectionPath = void 0;
const QFilterExpression_1 = require("../QFilterExpression");
class QCollectionPath {
  constructor(path, qEntityFn, __converter) {
    this.path = path;
    this.qEntityFn = qEntityFn;
    this.__converter = __converter;
    if (!path || !path.trim()) {
      throw new Error("Path must be supplied!");
    }
    if (!qEntityFn || typeof qEntityFn !== "function") {
      throw new Error("Function which returns query object must be supplied!");
    }
  }
  getPath() {
    return this.path;
  }
  getEntity(withPrefix = false) {
    return new (this.qEntityFn())(withPrefix ? this.path : undefined, this.__converter);
  }
  isCollectionType() {
    return true;
  }
  lambdaFunction(operationName, fn, prefix = "a") {
    // no prefix here => because $it needs to be replaced
    const expression = fn ? fn(this.getEntity()) : undefined;
    // if no expression was provided => function call without args
    if (!expression || !expression.toString()) {
      return new QFilterExpression_1.QFilterExpression(`${this.path}/${operationName}()`);
    }
    // $it is a constant for any primitive collection => just replace it within the string
    const replacedExpression = expression.toString().replace(/\$it/g, prefix);
    return new QFilterExpression_1.QFilterExpression(`${this.path}/${operationName}(${prefix}:${replacedExpression})`);
  }
  any(fn, prefix) {
    return this.lambdaFunction("any" /* LambdaFunctions.ANY */, fn, prefix);
  }
  all(fn, prefix) {
    return this.lambdaFunction("all" /* LambdaFunctions.ALL */, fn, prefix);
  }
}
exports.QCollectionPath = QCollectionPath;
