<p-accordion expandIcon="''" collapseIcon="''" [activeIndex]="0" id="ppg-sort-by" class="block w-full">
  <p-accordionTab iconPos="end" [header]="header()">
    <div class="flex flex-column gap-3">
      @for (option of sortingOptions(); track option.id) {
        <div class="flex">
          <p-radioButton
            [attr.data-test-id]="'sortByAccordion.radioButton.' + option.id"
            [(ngModel)]="selectedValue"
            [value]="option"
            [inputId]="'sort-by-accordion-btn-' + option.id"
            (onClick)="switchSortingOption(option)" />
          <label
            [attr.data-test-id]="'sortByAccordion.label.' + option.id"
            class="ml-3 text-overflow-ellipsis u-break-word"
            id="sort-by-label"
            for="{{ option.id }}"
            >{{ option.label }}</label
          >
        </div>
      }
    </div>
  </p-accordionTab>
</p-accordion>
