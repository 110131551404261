import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';

import { StoreAddress, StoreAddressResponse } from '@ppg/shared/ecommerce-api';

@Component({
  selector: 'ppg-store-location-information',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './store-location-information.component.html',
  styleUrls: ['./store-location-information.component.scss'],
})
export class StoreLocationInformationComponent {
  readonly storeAddress = input.required<StoreAddressResponse | StoreAddress>();
  readonly header = input.required<string>();
  readonly headerClass = input<string>();
  readonly headerStyle = input<string>();

  readonly state = computed(() => this.storeAddress()?.stateCode?.split('-')[1] ?? this.storeAddress()?.state ?? '');
}
