@if (content().image; as image) {
  @if (isRoot()) {
    <div class="w-full justify-content-center">
      <section class="page-section page-section-x-padded general-section gap-4">
        <div class="image-root-container">
          <ng-container *ngTemplateOutlet="imageWithColorUsed"></ng-container>
        </div>
      </section>
    </div>
  } @else {
    <ng-container *ngTemplateOutlet="imageWithColorUsed"></ng-container>
  }
  <ng-template #imageWithColorUsed>
    <ppg-image
      [url]="image.url"
      [width]="image.width"
      [height]="image.height"
      [isResponsive]="isResponsive()"
      [imageStyle]="imageStyle()"
      [loading]="loading()" />

    <p-button
      class="button-colors-used"
      severity="secondary"
      [style]="{
        'margin-bottom': buttonBottomOffset() + 'px',
      }"
      (onClick)="showDialog()">
      <svg class="pds-icon">
        <use href="assets/pds-icons.svg#apps" />
      </svg>
      <ppg-resource-item class="button-label" [resourceKey]="resourceItem.SEE_COLORS_USED" />
    </p-button>

    <ppg-colors-used-dialog [content]="content()" [(visible)]="visible" />
  </ng-template>
}
