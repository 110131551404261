import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, signal } from '@angular/core';

import { CurrentSiteState } from '@ppg/configuration';
import { StaticContentService } from '@ppg/core/content';
import { ClientSiteSettingsKeys } from '@ppg/core/enums';
import { LoggerService } from '@ppg/core/logger';
import { ColorInfoValid, Component603010Model } from '@ppg/core/models';

import { BackgroundDirective } from '../../../lib/common/directives/background.directive';
import { ColorUsedComponent } from '../image-with-colors-used/color-used/color-used.component';

@Component({
  selector: 'ppg-component-603010',
  standalone: true,
  imports: [ColorUsedComponent, BackgroundDirective, NgOptimizedImage],
  templateUrl: './component-603010.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrl: './component-603010.component.scss',
})
export class Component603010Component {
  readonly #staticContentService = inject(StaticContentService);
  readonly #currentSiteState = inject(CurrentSiteState);
  readonly #loggerService = inject(LoggerService);

  readonly model = input.required<Component603010Model>();

  protected readonly primaryColorImageLoadFailed = signal(false);
  protected readonly secondaryColorImageLoadFailed = signal(false);
  protected readonly accentColorImageLoadFailed = signal(false);

  readonly primaryColor = computed(() => this.model().primaryColor as ColorInfoValid);
  readonly secondaryColor = computed(() => this.model().secondaryColor as ColorInfoValid);
  readonly accentColor = computed(() => this.model().accentColor as ColorInfoValid);
  readonly validColors = computed(() => [this.primaryColor(), this.secondaryColor(), this.accentColor()]);

  readonly showEmptyState = computed<boolean>(() => {
    return (
      this.#isColorInfoIncomplete(this.primaryColor(), this.primaryColorImageLoadFailed()) ||
      this.#isColorInfoIncomplete(this.secondaryColor(), this.secondaryColorImageLoadFailed()) ||
      this.#isColorInfoIncomplete(this.accentColor(), this.accentColorImageLoadFailed())
    );
  });

  readonly imageUnavailableAsset = computed(() => {
    const asset = this.#staticContentService.getStaticAsset(ClientSiteSettingsKeys.AssetsGlobalColorsUnavailable);
    if (!asset) {
      this.#loggerService.error('[Component603010ColorsComponent] Error: Failed to retrieve image unavailable asset.');
      return;
    }
    return asset;
  });

  readonly url = computed(() => {
    const assetUrl = this.imageUnavailableAsset()?.url;
    if (!assetUrl) {
      this.#loggerService.error('[Component603010ColorsComponent] Error: URL for image unavailable asset is missing.');
      return '/';
    }
    return assetUrl;
  });

  readonly description = computed(() => {
    const description = this.imageUnavailableAsset()?.description;
    if (!description) {
      this.#loggerService.error(
        '[Component603010ColorsComponent] Error: DESCRIPTION for image unavailable asset is missing.',
      );
      return '';
    }
    return description;
  });

  #isColorInfoIncomplete(color: ColorInfoValid, imageLoadFailed: boolean): boolean {
    const isColorCodeHidden = this.#currentSiteState.getSiteFlag(ClientSiteSettingsKeys.ColorJourneyColorCodeHidden);

    return (
      !color ||
      !color.productNumber ||
      (isColorCodeHidden && !color.colorName) ||
      !(color.colorCode || color.colorName) ||
      !(color.rgb || (color.image && !imageLoadFailed))
    );
  }
}
