import { NgClass, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, ElementRef, inject, input, output } from '@angular/core';
import { AnimateOnScrollModule } from 'primeng/animateonscroll';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ImageModule } from 'primeng/image';

import { FALLBACK_LOGO_SRC } from '@ppg/core/constants';
import { StaticContentService } from '@ppg/core/content';
import { ClientSiteSettingsKeys } from '@ppg/core/enums';
import { ComponentContentBannerContent, ImagePosition } from '@ppg/core/models';
import { RichTextComponent } from '@ppg/shared/rich-text';
import { UrlCtaService } from '@ppg/shared/utils';

import { ImageComponent } from '../image/image.component';
import { ImageWithColorsUsedComponent } from '../image-with-colors-used/image-with-colors-used.component';

@Component({
  selector: 'ppg-content-banner',
  standalone: true,
  imports: [
    CardModule,
    ButtonModule,
    RichTextComponent,
    ImageModule,
    ImageComponent,
    AnimateOnScrollModule,
    NgClass,
    ImageWithColorsUsedComponent,
    NgOptimizedImage,
  ],
  templateUrl: './content-banner.component.html',
  styleUrl: './content-banner.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBannerComponent {
  readonly content = input.required<ComponentContentBannerContent>();

  private elem: ElementRef = inject(ElementRef);
  readonly #urlCtaService: UrlCtaService = inject(UrlCtaService);
  private readonly staticContentService = inject(StaticContentService);

  readonly ctaClicked = output<void>();

  readonly imgUrl = computed(() => this.content().image?.url ?? '');
  readonly imgHeight = computed(() => this.content().image?.height ?? 0);
  readonly imgWidth = computed(() => this.content().image?.width ?? 0);
  readonly imgDescription = computed(() => this.content().image?.description ?? '');
  readonly isImagePositionRight = computed(() => this.imagePositionRight());
  readonly backgroundClass = computed(() =>
    this.content().colors?.backgroundColor ? `theme-background-${this.content().colors?.backgroundColor}` : '',
  );
  readonly textColorClass = computed(() =>
    this.content().colors?.textColor ? `theme-text-${this.content().colors?.textColor}` : '',
  );

  readonly brandingAssetsHeaderLogo = computed(
    () =>
      this.staticContentService.getStaticAsset(ClientSiteSettingsKeys.BrandingAssetsHeaderLogo)?.url ??
      FALLBACK_LOGO_SRC ??
      '',
  );

  // logoUrl can be set from component render
  // default in html if empty is set value from StaticAsset - BrandingAssetsHeaderLogo
  readonly logoUrl = input<string>();

  readonly hasImage = computed(() => this.content().image || this.content().imageWithColorsUsed);

  isTargetBlank = computed(() => this.#urlCtaService.isTargetBlank(this.content()?.cta));

  onCtaClicked(): void {
    this.ctaClicked.emit();
    this.#navigateTo();
  }

  #navigateTo(): void {
    this.#urlCtaService.navigate(this.content()?.cta);
  }

  private imagePositionRight(): boolean {
    if (this.content().image?.imagePosition) {
      return this.content().image?.imagePosition === ImagePosition.Right;
    }

    let result = false;
    const sectionElement = this.elem.nativeElement.parentElement.parentElement as HTMLElement;
    const contentBlockElement = sectionElement.querySelectorAll('ppg-content-banner');
    contentBlockElement.forEach((sub, index) => {
      const subElem = sub.querySelector('p-card');
      if (subElem?.getAttribute('title') === this.content().headline) {
        result = index % 2 === 0;
      }
    });
    return result;
  }
}
