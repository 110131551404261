import { ChangeDetectionStrategy, Component, computed, ElementRef, inject, input, signal } from '@angular/core';
import { ButtonModule } from 'primeng/button';

import { HeroBannerTextAreaPositionEnum } from '@ppg/core/enums';
import { HeroBannerContent } from '@ppg/core/models';
import { RichTextComponent } from '@ppg/shared/rich-text';
import { UrlCtaService } from '@ppg/shared/utils';

import { CircleComponent } from '../../components/icons/circle.component';
import { PauseComponent } from '../../components/icons/pause.component';
import { PlayComponent } from '../../components/icons/play.component';
import { ImageComponent } from '../../components/image/image.component';
import { ImageWithColorsUsedComponent } from '../../components/image-with-colors-used/image-with-colors-used.component';

@Component({
  selector: 'ppg-hero-banner',
  standalone: true,
  imports: [
    ImageComponent,
    ButtonModule,
    PlayComponent,
    CircleComponent,
    PauseComponent,
    ImageWithColorsUsedComponent,
    RichTextComponent,
  ],
  templateUrl: './hero-banner.component.html',
  styleUrls: ['./hero-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeroBannerComponent {
  readonly content = input.required<HeroBannerContent>();

  readonly #elementRef = inject(ElementRef);
  readonly #urlCtaService = inject(UrlCtaService);

  public $isVideoPaused = signal(false);
  public $videoSourceHasError = signal(false);

  readonly imgUrl = computed(() => this.content().image.url ?? '');
  readonly imgHeight = computed(() => this.content().image.height ?? 0);
  readonly imgWidth = computed(() => this.content().image.width ?? 0);
  readonly video = computed(() => this.content().video);
  readonly headline = computed(() => this.content().headline ?? '');
  readonly description = computed(() => this.content().description ?? '');
  readonly cta = computed(() => this.content().cta);
  readonly videoHtmlElement = computed(
    () => this.#elementRef.nativeElement.getElementsByTagName('video')?.item(0) as HTMLVideoElement,
  );

  readonly isContentCentered = computed(
    () => this.content().textAreaPosition === HeroBannerTextAreaPositionEnum.CENTER,
  );
  readonly isContentBottomCentered = computed(
    () => this.content().textAreaPosition === HeroBannerTextAreaPositionEnum.BOTTOM_CENTER,
  );
  readonly isContentLeftCentered = computed(
    () => this.content().textAreaPosition === HeroBannerTextAreaPositionEnum.LEFT_CENTER,
  );

  navigateTo(): void {
    this.#urlCtaService.navigate(this.cta());
  }

  onPlayButtonClick(): void {
    if (this.videoHtmlElement()) {
      const videoPaused = this.videoHtmlElement().paused;
      this.$isVideoPaused.set(!videoPaused);
      videoPaused ? this.videoHtmlElement().play() : this.videoHtmlElement().pause();
    }
  }

  onVideoSourceError(): void {
    this.$videoSourceHasError.set(true);
  }
}
