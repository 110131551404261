"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QTimeV2Path = void 0;
const UrlParamHelper_1 = require("../../param/UrlParamHelper");
const QTimeV2Param_1 = require("../../param/v2/QTimeV2Param");
const QBasePath_1 = require("../base/QBasePath");
const DateTimeFunctions_1 = require("./DateTimeFunctions");
class QTimeV2Path extends QBasePath_1.QBasePath {
  constructor() {
    super(...arguments);
    this.hour = (0, DateTimeFunctions_1.hourFn)(this.getPath());
    this.minute = (0, DateTimeFunctions_1.minuteFn)(this.getPath());
    this.second = (0, DateTimeFunctions_1.secondFn)(this.getPath());
  }
  formatValue(value) {
    return (0, UrlParamHelper_1.formatWithTypePrefix)(QTimeV2Param_1.TIME_V2_TYPE_PREFIX, value);
  }
}
exports.QTimeV2Path = QTimeV2Path;
