import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'ppg-pinterest',
  standalone: true,
  template: `<svg
    tabindex="0"
    class="icon"
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none">
    <rect width="40" height="40" rx="6" fill="var(--icon-background, white)" />
    <rect x="0.5" y="0.5" width="39" height="39" rx="5.5" stroke="var(--icon-border, white)" />
    <path
      d="M6.66667 20C6.66667 25.6922 10.2344 30.5522 15.2556 32.4644C15.1333 31.4233 15.0033 29.7067 15.2833 28.5022C15.5244 27.4667 16.84 21.9044 16.84 21.9044C16.84 21.9044 16.4433 21.11 16.4433 19.9333C16.4433 18.0889 17.5122 16.7111 18.8444 16.7111C19.9778 16.7111 20.5244 17.5611 20.5244 18.58C20.5244 19.7189 19.7989 21.4211 19.4244 23C19.1122 24.3211 20.0878 25.3989 21.39 25.3989C23.7489 25.3989 25.5633 22.9111 25.5633 19.32C25.5633 16.1411 23.2789 13.92 20.0178 13.92C16.2422 13.92 14.0256 16.7522 14.0256 19.68C14.0256 20.8211 14.4644 22.0433 15.0133 22.7089C15.0601 22.7591 15.0932 22.8206 15.1094 22.8874C15.1255 22.9541 15.1242 23.0239 15.1056 23.09C15.0044 23.51 14.78 24.4111 14.7367 24.5955C14.6778 24.8378 14.5444 24.89 14.2922 24.7722C12.6344 24.0011 11.5989 21.5778 11.5989 19.6311C11.5989 15.4433 14.64 11.5989 20.3678 11.5989C24.9722 11.5989 28.5511 14.88 28.5511 19.2644C28.5511 23.8389 25.6678 27.5211 21.6633 27.5211C20.3178 27.5211 19.0544 26.8211 18.6211 25.9955C18.6211 25.9955 17.9556 28.5311 17.7944 29.1511C17.4811 30.3555 16.6122 31.88 16.0733 32.7455C17.3156 33.1278 18.6333 33.3333 20 33.3333C27.3633 33.3333 33.3333 27.3633 33.3333 20C33.3333 12.6367 27.3633 6.66666 20 6.66666C12.6367 6.66666 6.66667 12.6367 6.66667 20Z"
      fill="var(--icon-color, black)" />
  </svg>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PinterestComponent {}
