import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, input, output, effect } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DividerModule } from 'primeng/divider';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SidebarModule } from 'primeng/sidebar';

import { Option } from '@ppg/core/models';

@Component({
  selector: 'ppg-option-selector-sidebar',
  imports: [CommonModule, SidebarModule, RadioButtonModule, FormsModule, DividerModule],
  templateUrl: './option-selector-sidebar.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionSelectorSidebarComponent<T extends Option> {
  readonly options = input.required<T[]>();
  readonly selectedOption = input.required<T>();
  readonly valueChange = output<T>();
  readonly onHide = output<void>();

  readonly header = input<string>();
  readonly position = input<'left' | 'right' | 'bottom' | 'top'>('bottom');
  readonly style = input<{ [key: string]: string }>({});
  readonly styleClass = input<string>('');
  readonly visible = input<boolean>(false);

  protected selectedValue: T | undefined;

  constructor() {
    effect(() => {
      const selectedOption = this.selectedOption();
      if (selectedOption) {
        this.selectedValue = selectedOption;
      }
    });
  }

  switchOption(option: T) {
    this.valueChange.emit(option);
  }

  handleOnHide() {
    this.onHide.emit();
  }
}
