import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, model } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';

import { ComponentImageWithColorsUsedContent } from '@ppg/core/models';

import { ImageComponent } from '../../image/image.component';
import { ColorsUsedListComponent } from '../colors-used-list/colors-used-list.component';

@Component({
  selector: 'ppg-colors-used-dialog',
  standalone: true,
  imports: [DialogModule, ButtonModule, ImageComponent, ColorsUsedListComponent, NgTemplateOutlet],
  templateUrl: './colors-used-dialog.component.html',
  styleUrl: './colors-used-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColorsUsedDialogComponent {
  readonly content = input.required<ComponentImageWithColorsUsedContent>();
  readonly visible = model(false);

  closeDialog(): void {
    this.visible.set(false);
  }
}
