@if (colorInfoRes$ | async; as res) {
  @if (res.error) {
    <div class="error flex flex-column align-items-center justify-content-center">
      <img [ngSrc]="colorsUnavailableAsset.url" [alt]="colorsUnavailableAsset.description" width="32" height="32" />
      <p class="error-message">{{ colorsUnavailableAsset.description }}</p>
    </div>
  } @else {
    <ul class="colors-list">
      @if (res.loading) {
        @for (color of productNumbers(); track $index) {
          <li class="item loading-item">
            <p-skeleton width="100%" height="64px" borderRadius="0" />
          </li>
        }
      } @else {
        @for (color of res.data; track $index) {
          <li class="item color-item">
            <ppg-color-used [color]="color"></ppg-color-used>
          </li>
        }
      }
    </ul>
  }
}
