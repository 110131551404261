import { UrlTarget } from '@ppg/core/enums';

import { Link } from './link.model';
import { SocialSharingModel } from './social-sharing.model';

export enum FooterMainType {
  navigation = 'item_navigation',
}

export enum FooterChildItemType {
  link = 'link_url',
  button = 'button_url',
}

export interface FooterChildNavigationItem {
  name: string;
  type: FooterChildItemType;
  url: string;
  target: UrlTarget;
}

export enum FooterChildTextItemType {
  richText = 'rich_text',
}

export interface FooterChildTextItem {
  type: FooterChildTextItemType;
  value: string;
}

export interface FooterMainItem {
  type: FooterMainType.navigation;
  name: string;
  items: FooterChildNavigationItem[];
}

export interface Footer {
  items: FooterMainItem[];
  social: SocialSharingModel | null;
}

export interface FooterLinks {
  label: string;
  items: Link[];
}
