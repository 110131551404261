import { ChangeDetectionStrategy, Component, input } from '@angular/core';

import { UrlTarget } from '@ppg/core/enums';
import { SocialSharingContent } from '@ppg/core/models';

import {
  FacebookComponent,
  TwitterXComponent,
  InstagramComponent,
  LinkedinComponent,
  PinterestComponent,
  TiktokComponent,
  YoutubeComponent,
} from '../../../lib/components/icons';

@Component({
  selector: 'ppg-social-sharing',
  standalone: true,
  imports: [
    FacebookComponent,
    TwitterXComponent,
    InstagramComponent,
    YoutubeComponent,
    LinkedinComponent,
    PinterestComponent,
    TiktokComponent,
  ],
  templateUrl: './social-sharing.component.html',
  styleUrl: './social-sharing.component.scss',

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialSharingComponent {
  readonly content = input.required<SocialSharingContent>();

  readonly urlTarget = UrlTarget;
}
