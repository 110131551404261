"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QParam = void 0;
const IdentityConverter_1 = require("../IdentityConverter");
class QParam {
  constructor(name, mappedName, converter = (0, IdentityConverter_1.getIdentityConverter)()) {
    this.name = name;
    this.mappedName = mappedName;
    this.converter = converter;
    if (!name) {
      throw new Error("Name is required for QParam objects!");
    }
  }
  getName() {
    return this.name;
  }
  getMappedName() {
    var _a;
    return (_a = this.mappedName) !== null && _a !== void 0 ? _a : this.getName();
  }
  getConverter() {
    return this.converter;
  }
  convertFrom(value) {
    return Array.isArray(value) ? value.map(this.converter.convertFrom) : this.converter.convertFrom(value);
  }
  convertTo(value) {
    return Array.isArray(value) ? value.map(this.converter.convertTo) : this.converter.convertTo(value);
  }
  formatUrlValue(value) {
    const converted = this.convertTo(value);
    return Array.isArray(value) ? JSON.stringify(converted) : this.getUrlConformValue(converted);
  }
  parseUrlValue(value) {
    const parsed = this.parseValueFromUrl(value);
    if (value && parsed === undefined) {
      try {
        const jsonParsed = JSON.parse(value);
        if (Array.isArray(jsonParsed)) {
          return this.convertFrom(jsonParsed);
        }
      } catch (e) {}
    }
    return this.convertFrom(parsed);
  }
}
exports.QParam = QParam;
