"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QOrderByExpression = void 0;
class QOrderByExpression {
  constructor(expression) {
    this.expression = expression;
    if (!expression || !expression.trim()) {
      throw new Error("Expression must be supplied!");
    }
  }
  toString() {
    return this.expression;
  }
}
exports.QOrderByExpression = QOrderByExpression;
