"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QGuidV2Path = void 0;
const UrlParamHelper_1 = require("../../param/UrlParamHelper");
const QGuidV2Param_1 = require("../../param/v2/QGuidV2Param");
const QBasePath_1 = require("../base/QBasePath");
class QGuidV2Path extends QBasePath_1.QBasePath {
  formatValue(value) {
    return (0, UrlParamHelper_1.formatWithTypePrefix)(QGuidV2Param_1.GUID_V2_TYPE_PREFIX, value);
  }
}
exports.QGuidV2Path = QGuidV2Path;
