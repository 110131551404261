<div class="flex flex-column gap-3 mb-4">
  @for (option of sortingOptions; track option.id) {
    <div class="flex">
      <p-radioButton
        [attr.data-test-id]="'sortByDialog.radioButton.' + option.key + option.type"
        [(ngModel)]="selectedValue"
        [value]="option"
        [inputId]="'sort-by-dialog-btn-' + option.id"
        (onClick)="switchSortingOption(option)" />
      <label
        [attr.data-test-id]="'sortByDialog.label.' + option.key + option.type"
        class="ml-3"
        id="sort-by-label"
        for="{{ option.id }}"
        >{{ option.label }}</label
      >
    </div>
  }
</div>
