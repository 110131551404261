<div class="mx-auto page-section page-section-x-padded">
  <p-card [title]="content().stepHeadline" class="flex steps-component w-full">
    <div class="inline-flex w-full flex-1 justify-content-start align-items-center flex-column-lg-flex-row">
      @if (content().image) {
        <div
          class="inline-flex flex-column step-image h-full"
          [class]="stepNumber() % 2 === 0 ? 'even-step-image' : 'odd-step-image'">
          <ppg-image
            [url]="imageUrl()"
            [height]="imageHeight()"
            [width]="imageWidth()"
            [description]="imageDescription()"
            class="flex w-full h-full align-items-center" />
        </div>
      }

      <div
        class="text-block-body flex flex-column"
        [ngClass]="{
          'even-step-text-block': stepNumber() % 2 === 0,
          'odd-step-text-block': stepNumber() % 2 !== 0,
          'text-block-body-with-image': content().image,
          'text-block-body-without-image': !content().image,
        }">
        @if (content().stepHeadline) {
          <h2 class="step-headline">
            {{ content().stepHeadline }}
          </h2>
        }

        <h3 class="step-subhead">
          @if (content().stepSubHead) {
            {{ content().stepSubHead }} -
          }
          <span style="white-space: nowrap"> {{ stepNumberLabel() }}</span>
        </h3>

        <div class="step-instructions">
          @for (instruction of content().stepInstructions; track $index) {
            <ppg-rich-text [content]="instruction.content"></ppg-rich-text>
          }
        </div>
        @if (content().cta) {
          <div class="cta-container">
            <p-button
              class="cta-content-button"
              [label]="content().cta?.content?.text"
              iconPos="right"
              [outlined]="false"
              [icon]="isLinkTargetBlank() ? 'pi pi-arrow-up-right' : ''"
              (onClick)="navigateTo()"></p-button>
          </div>
        }
      </div>
    </div>
  </p-card>
</div>
