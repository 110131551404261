export enum FilterNames {
  'Finish type (Gloss grade)' = 'PRODUCT_LIST_PAGE_FINISH_TYPE',
  'Application method' = 'PRODUCT_LIST_PAGE_APPLICATION_METHOD',
  'Indoor/outdoor use' = 'PRODUCT_LIST_PAGE_INDOOR_OUTDOOR_USE',
  'Composition base' = 'PRODUCT_LIST_PAGE_COMPOSITION_BASE',
  'Certification labels' = 'PRODUCT_LIST_PAGE_CERTIFICATION_LABELS',
  'Indoor area' = 'PRODUCT_LIST_PAGE_INDOOR_AREA',
  'Painted object' = 'PRODUCT_LIST_PAGE_PAINTED_OBJECT',
  'Painted surface material' = 'PRODUCT_LIST_PAGE_PAINTED_SURFACE_MATERIAL',
  'Brand' = 'PRODUCT_LIST_PAGE_BRAND',
  'Special characteristics' = 'PRODUCT_LIST_PAGE_SPECIAL_CHARACTERISTICS',
  'ProductLines' = 'PRODUCT_LIST_PAGE_PRODUCT_LINES',
}
