import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, input, output, effect, inject, ChangeDetectorRef } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AccordionModule } from 'primeng/accordion';
import { RadioButtonModule } from 'primeng/radiobutton';

import { SortOption } from '@ppg/core/models';

@Component({
  selector: 'ppg-sort-by-accordion',
  imports: [CommonModule, AccordionModule, RadioButtonModule, FormsModule],
  templateUrl: './sort-by-accordion.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SortByAccordionComponent {
  readonly sortingOptions = input.required<SortOption[]>();
  readonly selectedOption = input<SortOption>();
  readonly header = input<string>();
  readonly sortingValueChange = output<SortOption>();
  readonly changeDetector = inject(ChangeDetectorRef);

  protected selectedValue: SortOption | undefined;

  constructor() {
    effect(() => {
      const selectedOption = this.selectedOption();
      if (selectedOption) {
        this.selectedValue = selectedOption;
        this.changeDetector.detectChanges();
      }
    });
  }

  switchSortingOption(option: SortOption) {
    this.sortingValueChange.emit(option);
  }
}
