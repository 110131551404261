"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QBooleanPath = void 0;
const UrlParamHelper_1 = require("../param/UrlParamHelper");
const QBasePath_1 = require("./base/QBasePath");
class QBooleanPath extends QBasePath_1.QBasePath {
  formatValue(value) {
    return String(value);
  }
  isTrue() {
    return (0, UrlParamHelper_1.buildQFilterOperation)(this.path, "eq" /* StandardFilterOperators.EQUALS */, "true");
  }
  isFalse() {
    return (0, UrlParamHelper_1.buildQFilterOperation)(this.path, "eq" /* StandardFilterOperators.EQUALS */, "false");
  }
}
exports.QBooleanPath = QBooleanPath;
