<div class="flex align-items-center gap-2 text-sm header" data-test-id="storeLocationInformation.header">
  <span [class]="headerClass()" [style]="headerStyle()" data-test-id="storeLocationInformation.header.label">
    {{ header() }}
  </span>
  <ng-content select="[tooltipContent]"></ng-content>
</div>

<div class="flex flex-column text-sm mt-0 info">
  <span data-test-id="storeLocationInformation.name">
    {{ storeAddress().name }}
  </span>
  <span data-test-id="storeLocationInformation.address1">
    {{ storeAddress().address1 }}
  </span>
  @if (storeAddress().address2) {
    <span data-test-id="storeLocationInformation.address2">{{ storeAddress().address2 }}</span>
  }
  <span data-test-id="storeLocationInformation.details">
    {{ storeAddress().city }}, {{ state() }} {{ storeAddress().postcode }}
  </span>
  <a class="link underline" href="tel:{{ storeAddress().phoneNumber }}" data-test-id="storeLocationInformation.phone">
    {{ storeAddress().phoneNumber }}
  </a>
</div>
