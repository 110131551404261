import { isPlatformBrowser } from '@angular/common';
import {
  Directive,
  effect,
  EffectCleanupRegisterFn,
  ElementRef,
  inject,
  input,
  output,
  PLATFORM_ID,
} from '@angular/core';

import { ColorUtils } from '@ppg/shared/color/services';

@Directive({
  standalone: true,
  selector: '[ppgBackground]',
})
export class BackgroundDirective {
  readonly imageUrl = input<string>();
  readonly rgb = input<string>();
  readonly onImageLoaded = output();
  readonly onImageLoadError = output();
  readonly element = inject(ElementRef);
  readonly #colorUtils = inject(ColorUtils);
  readonly #isBrowser = isPlatformBrowser(inject(PLATFORM_ID));

  constructor() {
    effect((onCleanup) => {
      const imageUrl = this.imageUrl();
      const rgb = this.rgb();

      if (imageUrl || rgb) {
        this.#setBackgroundStyle(onCleanup);
      }
    });
  }

  #setBackgroundStyle(onCleanup: EffectCleanupRegisterFn): void {
    const imageUrl = this.imageUrl();
    if (imageUrl) {
      this.element.nativeElement.style.backgroundImage = `url('${imageUrl}')`;
      this.element.nativeElement.style.backgroundSize = 'cover';
      this.element.nativeElement.style.backgroundPosition = 'center';
      this.#checkImageLoadState(imageUrl, onCleanup);
    }

    const rgb = this.rgb();
    if (rgb) {
      const transformedRgb = this.#colorUtils.stringToRgb(rgb);
      this.element.nativeElement.style.backgroundColor = `${this.#colorUtils.rgbToHex(transformedRgb)}`;
      this.element.nativeElement.style.backgroundPosition = 'center';
      this.element.nativeElement.style.backgroundRepeat = 'no-repeat';
    }
  }

  #checkImageLoadState(imageUrl: string, onCleanup: EffectCleanupRegisterFn): void {
    if (!this.#isBrowser) {
      return;
    }
    const image = new Image();
    image.src = imageUrl;
    image.onerror = () => {
      this.onImageLoadError.emit();
    };
    image.onload = () => {
      this.onImageLoaded.emit();
    };
    onCleanup(() => {
      image.onload = null;
      image.onerror = null;
    });
  }
}
