import { computed, inject, Injectable } from '@angular/core';
import { defaultIfEmpty, filter, forkJoin, map, Observable, of, switchMap } from 'rxjs';

import {
  ColorFamily,
  ColorInfoDataModel,
  ColorInfoType,
  ColorInfoValid,
  ColorCollectionDataModel,
  ColorCollection,
  ColorCollectionWithColors,
  ColorFamilyNaming,
} from '@ppg/core/models';

import { ColorFamilyInfoService } from './color-family-info.service';
import { ColorInfoApiService } from './color-info-api.service';

@Injectable({ providedIn: 'root' })
export class ColorInfoService {
  private readonly assetNotFoundCode = 'notfound';

  private readonly colorApiService = inject(ColorInfoApiService);
  private readonly colorFamilyInfoService = inject(ColorFamilyInfoService);
  productApiLanguage = this.colorApiService.productApiLanguage;

  readonly areMultipleFamilyTypesEnabled = computed(() => {
    return this.colorFamilyInfoService.colorFamilyTypes().length > 1;
  });

  getColorInfo(colorProductNumbers: string[]) {
    return this.colorApiService
      .getColorInfo(colorProductNumbers)
      .pipe(map((colorInfoDataModels) => this.mapToColorInfos(colorInfoDataModels)));
  }

  getColorInfosWithFamilies(colorProductNumbers: string[]) {
    return forkJoin([
      this.colorApiService.getColorInfo(colorProductNumbers),
      this.colorFamilyInfoService.getColorFamiliesGroupedByType(),
    ]).pipe(
      map(([colorInfos, familiesGroupedByType]) => {
        return colorInfos.map((colorInfo) => this.mapToColorInfo(colorInfo, familiesGroupedByType));
      }),
    );
  }

  getColorDetails(colorProductNumber: string) {
    return this.colorApiService.getColorDetails(colorProductNumber).pipe(
      switchMap((colorDetails) => {
        return forkJoin([
          of(colorDetails),
          this.colorFamilyInfoService.getColorFamiliesGroupedByType(),
          this.getColorCollections(),
        ]);
      }),
      map((colorFullData) => {
        const [details, familiesGroupedByType, collections] = colorFullData;

        return details != null ? this.mapToColorInfo(details, familiesGroupedByType, collections) : null;
      }),
    );
  }

  getColorCollections() {
    return this.colorApiService
      .getColorCollections()
      .pipe(map((collections) => this.mapToColorCollections(collections)));
  }

  getColorCollectionWithColors(collectionName: string): Observable<ColorCollectionWithColors | undefined> {
    return this.getColorCollectionByName(collectionName).pipe(
      filter((colorCollection) => !!colorCollection),
      switchMap((colorCollection) =>
        this.getColorsPerCollection(colorCollection!.id).pipe(
          map((colors) => this.mapToColorCollectionWithColors(colorCollection!, colors)),
        ),
      ),
      defaultIfEmpty(undefined),
    );
  }

  private getColorsPerCollection(collectionid: string) {
    return forkJoin([
      this.colorApiService.getColorsPerCategory(collectionid),
      this.colorFamilyInfoService.getColorFamiliesGroupedByType(),
    ]).pipe(
      map(([colorInfoDataModels, allFamiliesGroupedByType]) =>
        this.mapToColorInfos(colorInfoDataModels, allFamiliesGroupedByType),
      ),
    );
  }

  getColorsPerFamily(familyId: string): Observable<ColorInfoValid[]> {
    return forkJoin([
      this.colorApiService.getColorsPerCategory(familyId),
      this.colorFamilyInfoService.getColorFamiliesGroupedByType(),
    ]).pipe(
      map(([colorInfoDataModels, allFamiliesGroupedByType]) => {
        const colors = this.mapToColorInfos(colorInfoDataModels);
        const family = this.findFamily(familyId, allFamiliesGroupedByType);
        const colorsWithFamilies = colors.map(
          (color) =>
            ({
              ...color,
              colorFamilies: [family],
            }) as unknown as ColorInfoValid,
        );
        return colorsWithFamilies;
      }),
    );
  }

  private getColorCollectionByName(collectionName: string): Observable<ColorCollection | null> {
    return this.getColorCollections().pipe(
      map((allColorCollections) => {
        const targetName = collectionName?.toLowerCase();
        return allColorCollections.find((collection) => collection.name?.toLowerCase() === targetName) ?? null;
      }),
    );
  }

  private mapToColorCollectionWithColors(
    colorCollection: ColorCollection,
    colors: ColorInfoValid[],
  ): ColorCollectionWithColors {
    return {
      ...colorCollection,
      colors: colors,
    };
  }

  private mapToColorCollections(colorCollectionsModels: ColorCollectionDataModel[]) {
    return colorCollectionsModels.map((colorCollectionDataModel) => {
      const name = colorCollectionDataModel.name.find((name) => name.language === this.productApiLanguage)?.translation;

      const colorCollectionAssets = colorCollectionDataModel.assets?.filter(
        (asset) => this.removeWhitespace(asset.id.toLowerCase()) !== this.assetNotFoundCode,
      );
      const descriptionImage = colorCollectionAssets?.[0]?.url; // temporarily taking first assets as description image. When the Product API is updated, it will be determined by attribute type

      return {
        id: colorCollectionDataModel.id,
        name: name,
        imageUrl: descriptionImage,
        description: this.getDescription(colorCollectionDataModel),
      } as ColorCollection;
    });
  }

  private mapToColorInfos = (
    colorInfoDataModels: ColorInfoDataModel[],
    allColorFamiliesGroupedByType?: Record<string, ColorFamily[]>,
  ): ColorInfoValid[] => {
    return colorInfoDataModels.map((colorInfoDataModel) => {
      return this.mapToColorInfo(colorInfoDataModel, allColorFamiliesGroupedByType);
    });
  };

  private mapToColorInfo = (
    colorInfoDataModel: ColorInfoDataModel,
    allColorFamiliesGroupedByType?: Record<string, ColorFamily[]>,
    allColorCollections?: ColorCollection[],
  ): ColorInfoValid => {
    const colorNameAttribute = 'colorname';
    const colorCodeAttribute = 'colorcode';
    const rgbValueAttribute = 'rgbvalue';
    const colorImageAttribute = 'colorimage';
    const lrvAttribute = 'lrv';
    const ncsAttribute = 'ncscolorcode';
    const colorTypeAttribute = 'colortype';
    const isBestSellerAttribute = 'isbestsellerflag';

    const rgb = this.formatRgb(
      colorInfoDataModel.attributes.find((attr) => this.removeWhitespace(attr.name.toLowerCase()) === rgbValueAttribute)
        ?.values,
    );

    const productNumber = colorInfoDataModel.productNumber;
    const description = colorInfoDataModel.marketingData.longMarketingText;
    const isFavorite = false;
    const addedToFavoritesAt = undefined;
    const colorCode =
      colorInfoDataModel.attributes.find(
        (attr) => this.removeWhitespace(attr.name.toLowerCase()) === colorCodeAttribute,
      )?.values[0] ?? '';
    const colorName =
      colorInfoDataModel.attributes.find(
        (attr) => this.removeWhitespace(attr.name.toLowerCase()) === colorNameAttribute,
      )?.values[0] ?? '';
    const lrvAttributeValue = colorInfoDataModel.attributes.find(
      (attr) => this.removeWhitespace(attr.name.toLowerCase()) === lrvAttribute,
    )?.values[0];
    const ncsAttributeValue = colorInfoDataModel.attributes.find(
      (attr) => this.removeWhitespace(attr.name.toLowerCase()) === ncsAttribute,
    )?.values[0];
    const colorTypeAttributeValue = colorInfoDataModel.attributes.find(
      (attr) => this.removeWhitespace(attr.name.toLowerCase()) === colorTypeAttribute,
    )?.values[0];

    const isBestSellerTextValue =
      colorInfoDataModel.attributes.find(
        (attr) => this.removeWhitespace(attr.name.toLowerCase()) === isBestSellerAttribute,
      )?.values[0] ?? '';
    const isBestSeller = this.removeWhitespace(isBestSellerTextValue).toLowerCase() === 'true';

    const lrv = lrvAttributeValue ? parseFloat(lrvAttributeValue) : undefined;
    const ncs = ncsAttributeValue ?? '';
    const colorType = this.parseColorType(colorTypeAttributeValue);

    const colorFamilies = this.findFamilies(colorInfoDataModel.categoryIds, allColorFamiliesGroupedByType);

    const colorCollections = this.findCollections(colorInfoDataModel.categoryIds, allColorCollections).map(
      (collection) => collection?.name || '',
    );

    const colorAssets = colorInfoDataModel.assets.filter(
      (asset) => this.removeWhitespace(asset.id.toLowerCase()) !== this.assetNotFoundCode,
    );

    const image = colorAssets.find(
      (attr) => this.removeWhitespace(attr.type.toLowerCase()) === colorImageAttribute,
    )?.url;

    const metaTitle = colorInfoDataModel.marketingData.metaTitle;
    const metaDescription = colorInfoDataModel.marketingData.metaDescription;

    return new ColorInfoValid(
      productNumber,
      isFavorite,
      addedToFavoritesAt,
      colorCode,
      colorName,
      rgb,
      image,
      colorAssets,
      isBestSeller,
      lrv,
      ncs,
      colorType,
      description?.length > 0 ? description[0].translation : undefined,
      colorCollections,
      colorFamilies,
      metaTitle,
      metaDescription !== undefined && metaDescription?.length > 0 ? metaDescription[0]?.translation : undefined,
    );
  };

  private formatRgb(rgbElements: string[] | undefined) {
    return rgbElements ? `${rgbElements.join(',')}`.replace(/\s+/g, '') : '';
  }

  private removeWhitespace(input: string) {
    return input ? input.replace(/\s+/g, '') : input;
  }

  private getDescription(colorCollectionDataModel: ColorCollectionDataModel) {
    return colorCollectionDataModel.description
      ?.find((desc) => desc.language === this.productApiLanguage)
      ?.translation.replaceAll('<CRLF>', '<br>');
  }

  private parseColorType(colorType?: string): ColorInfoType | undefined {
    switch (colorType) {
      case 'Paint':
        return ColorInfoType.Paint;
      case 'Metallic':
        return ColorInfoType.Metallic;
      case 'Stain':
        return ColorInfoType.Stain;
      default:
        return undefined;
    }
  }

  private findCollections(ids: string[] = [], collections: ColorCollection[] = []) {
    const collectionIds: string[] = ids.flatMap((id) => id.split(','));

    return collectionIds.map((id) => collections.find((data) => data.id === id)).filter(Boolean);
  }

  private findFamilies(
    categoryIds: string[],
    allColorFamiliesGroupedByType?: Record<string, ColorFamily[]>,
  ): ColorFamilyNaming[] {
    if (!allColorFamiliesGroupedByType) {
      return [];
    }
    const categoryIdsSet = new Set(categoryIds.flatMap((id) => id.split(',')));
    const matchingFamilies: ColorFamilyNaming[] = [];

    for (const families of Object.values(allColorFamiliesGroupedByType)) {
      matchingFamilies.push(
        ...families
          .filter((family) => categoryIdsSet.has(family.id))
          .map((family) => {
            return { name: family.name, typeLabel: family.typeLabel };
          }),
      );
    }

    return matchingFamilies;
  }

  private findFamily(
    categoryId: string,
    allColorFamiliesGroupedByType?: Record<string, ColorFamily[]>,
  ): ColorFamilyNaming | undefined {
    if (!allColorFamiliesGroupedByType) {
      return undefined;
    }

    for (const families of Object.values(allColorFamiliesGroupedByType)) {
      const matchingFamily = families.find((family) => family.id === categoryId);
      if (matchingFamily) {
        return { name: matchingFamily.name, typeLabel: matchingFamily.typeLabel };
      }
    }

    return undefined;
  }
}
