"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QStringBasePath = void 0;
const UrlParamHelper_1 = require("../../param/UrlParamHelper");
const QFilterExpression_1 = require("../../QFilterExpression");
const QBasePath_1 = require("./QBasePath");
class QStringBasePath extends QBasePath_1.QBasePath {
  constructor() {
    super(...arguments);
    this.formatValue = UrlParamHelper_1.formatWithQuotes;
  }
  getFunctionExpression(func, value) {
    const converted = this.convertInput(value);
    return (0, UrlParamHelper_1.buildFunctionExpression)(func, this.path, converted);
  }
  buildFunctionFilter(func, value) {
    return new QFilterExpression_1.QFilterExpression(this.getFunctionExpression(func, value));
  }
  buildNoValueFunc(func) {
    return this.create((0, UrlParamHelper_1.buildFunctionExpression)(func, this.path));
  }
  concatPrefix(value) {
    const converted = this.convertInput(value);
    return this.create((0, UrlParamHelper_1.buildFunctionExpression)("concat" /* StringFilterFunctions.CONCAT */, converted, this.path));
  }
  concatSuffix(value) {
    return this.create(this.getFunctionExpression("concat" /* StringFilterFunctions.CONCAT */, value));
  }
  startsWith(value) {
    return this.buildFunctionFilter("startswith" /* StringFilterFunctions.STARTS_WITH */, value);
  }
  endsWith(value) {
    return this.buildFunctionFilter("endswith" /* StringFilterFunctions.ENDS_WITH */, value);
  }
  toLower() {
    return this.buildNoValueFunc("tolower" /* StringFilterFunctions.TO_LOWER */);
  }
  toUpper() {
    return this.buildNoValueFunc("toupper" /* StringFilterFunctions.TO_UPPER */);
  }
  trim() {
    return this.buildNoValueFunc("trim" /* StringFilterFunctions.TRIM */);
  }
}
exports.QStringBasePath = QStringBasePath;
