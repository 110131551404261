import { Component, input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'ppg-input-group',
  template: `<label [attr.data-test-id]="dataTestId()" [for]="for()" class="p-label">
    @if (required()) {
      <span class="p-error">*</span>
    }
    {{ label() }}
    <ng-content />
  </label>`,
})
export class InputGroupComponent {
  readonly for = input<string>('');
  readonly dataTestId = input<string>('');
  readonly label = input.required<string>();
  readonly required = input<boolean>(false);
}
