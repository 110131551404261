import { Injectable } from '@angular/core';
import { Observable, fromEvent, map, startWith } from 'rxjs';

import { AppBreakpoints } from '@ppg/core/enums';

@Injectable({
  providedIn: 'root',
})
export class MediaQueryService {
  private activeMediaQueries: { [key: string]: Observable<boolean> } = {};

  mediaQuery(type: 'min' | 'max', breakPoint: keyof typeof AppBreakpoints): Observable<boolean> {
    const mediaId = `${type}-${breakPoint}`;
    if (mediaId in this.activeMediaQueries) {
      return this.activeMediaQueries[mediaId];
    }

    const mqText = `(${type}-width: ${this.mapPxToRem(AppBreakpoints[breakPoint])})`;
    const mediaQuery = window.matchMedia(mqText);

    const dynamicMediaQuery = fromEvent<MediaQueryList>(mediaQuery, 'change').pipe(
      startWith(mediaQuery),
      map((query: MediaQueryList) => query.matches),
    );

    this.activeMediaQueries[mediaId] = dynamicMediaQuery;
    return dynamicMediaQuery;
  }

  private mapPxToRem = (px: number) => (1 / 16) * px + 'rem';
}
