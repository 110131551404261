import { inject, Injectable } from '@angular/core';

import { Color, ColorInfoValid } from '@ppg/core/models';
import { CustomGtmTagService, GoogleAnalyticsCustomerJourney, GoogleAnalyticsPagePrefix } from '@ppg/shared/analytics';

import { ColorInfoService } from '../color-info/color-info.service';

@Injectable({
  providedIn: 'root',
})
export class ColorGtmService {
  readonly #gtmService = inject(CustomGtmTagService);
  readonly #colorInfoService = inject(ColorInfoService);

  public removeColorFromFavoritesGTMtag(color: Color, pagePrefix?: GoogleAnalyticsPagePrefix) {
    const event = `${pagePrefix ?? ''}remove_color_from_favorites`;
    this.pushColorDetailsGTMTag(event, color);
  }

  public addColorToFavoritesGTMtag(color: Color, pagePrefix?: GoogleAnalyticsPagePrefix) {
    const event = `${pagePrefix ?? ''}add_color_to_favorites`;
    this.pushColorDetailsGTMTag(event, color);
  }

  public changeGalleriaItemGTMTag(color: Color | null | undefined, imageIndex: number) {
    const colorInfo: ColorInfoValid | null | undefined = color as ColorInfoValid;

    this.#gtmService.pushTag({
      event: 'cdp_alt_image_click',
      cdpAltImageSlot: imageIndex,
      customerJourneyInformation: {
        customerJourney: GoogleAnalyticsCustomerJourney.Inspiration,
      },
      colorDetailInformation: {
        colorName: color?.colorName,
        colorCode: colorInfo?.colorCode,
        colorFamily: this.getFormattedColorFamilyNameForColor(colorInfo),
      },
    });
  }

  public pushColorCollectionViewGTMTag(colletionName: string) {
    this.#gtmService.pushTag({
      event: 'collection_view',
      customerJourneyInformation: {
        customerJourney: GoogleAnalyticsCustomerJourney.Inspiration,
      },
      colorDetailInformation: {
        collectionName: colletionName,
      },
    });
  }

  public pushColorDetailsGTMTag(
    event: string,
    color: Color | null | undefined,
    customerJourney: GoogleAnalyticsCustomerJourney = GoogleAnalyticsCustomerJourney.Inspiration,
  ) {
    const colorInfo: ColorInfoValid | null | undefined = color as ColorInfoValid;

    this.#gtmService.pushTag({
      event: event,
      customerJourneyInformation: {
        customerJourney: customerJourney,
      },
      colorDetailInformation: {
        colorName: color?.colorName,
        colorCode: colorInfo?.colorCode,
        colorFamily: this.getFormattedColorFamilyNameForColor(colorInfo),
      },
    });
  }

  public pushColorFamilyGTMTag(event: string, familyName?: string, familyTypeName?: string) {
    this.#gtmService.pushTag({
      event: event,
      customerJourneyInformation: {
        customerJourney: GoogleAnalyticsCustomerJourney.Inspiration,
      },
      colorDetailInformation: {
        colorFamily: this.getFormattedColorFamilyName(familyName, familyTypeName),
      },
    });
  }

  public pushColorFamilyWithShadeGTMTag(event: string, familyName?: string, familyTypeName?: string, shade?: string) {
    this.#gtmService.pushTag({
      event: event,
      customerJourneyInformation: {
        customerJourney: GoogleAnalyticsCustomerJourney.Inspiration,
      },
      colorDetailInformation: {
        colorFamily: this.getFormattedColorFamilyName(familyName, familyTypeName),
        colorFamily_shade: shade,
      },
    });
  }

  public pushColorListGTMTag(
    event: string,
    customerJourney: GoogleAnalyticsCustomerJourney = GoogleAnalyticsCustomerJourney.Inspiration,
  ) {
    this.#gtmService.pushTag({
      event: event,
      customerJourneyInformation: {
        customerJourney: customerJourney,
      },
    });
  }

  public pushSelectFilterGTMTag(
    event: string,
    filterName?: string,
    customerJourney: GoogleAnalyticsCustomerJourney = GoogleAnalyticsCustomerJourney.Inspiration,
  ) {
    this.#gtmService.pushTag({
      event: event,
      customerJourneyInformation: {
        customerJourney: customerJourney,
      },
      filter_selected: filterName,
    });
  }

  getFormattedColorFamilyNameForColor(colorInfo?: ColorInfoValid | null): string | undefined {
    const colorFamilies = colorInfo?.colorFamilies;

    if (!colorFamilies || colorFamilies.length === 0) {
      return undefined;
    }
    return this.#colorInfoService.areMultipleFamilyTypesEnabled()
      ? `${colorFamilies[0].typeLabel} - ${colorFamilies[0].name}`
      : colorFamilies[0].name;
  }

  getFormattedColorFamilyName(familyName?: string, familyTypeName?: string) {
    return this.#colorInfoService.areMultipleFamilyTypesEnabled() ? `${familyTypeName} - ${familyName}` : familyName;
  }
}
