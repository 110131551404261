import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';

import { CenteredContentBlockContent, CTA } from '@ppg/core/models';
import { RichTextComponent } from '@ppg/shared/rich-text';
import { UrlCtaService } from '@ppg/shared/utils';

import { HorizontalDividerComponent } from '../horizontal-divider/horizontal-divider.component';
import { ImageComponent } from '../image/image.component';
import { ImageWithColorsUsedComponent } from '../image-with-colors-used/image-with-colors-used.component';

@Component({
  selector: 'ppg-centered-content-block',
  standalone: true,
  imports: [
    CardModule,
    ButtonModule,
    RichTextComponent,
    ImageComponent,
    HorizontalDividerComponent,
    ImageWithColorsUsedComponent,
  ],
  templateUrl: './centered-content-block.component.html',
  styleUrl: './centered-content-block.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CenteredContentBlockComponent {
  readonly content = input.required<CenteredContentBlockContent>();

  readonly headline = computed(() => this.content().headline ?? '');
  readonly image = computed(() => this.content().image ?? undefined);

  readonly ctaItems = computed(() => this.content().ctaItems?.filter((item) => item) ?? []);
  readonly richTexts = computed(() => this.content().content?.filter((item) => item) ?? []);

  readonly pageSectionBackground = computed(() => this.content().backgroundColorRGB ?? '');

  readonly #urlCtaService: UrlCtaService = inject(UrlCtaService);

  navigateTo(cta: CTA): void {
    this.#urlCtaService.navigate(cta);
  }

  getArrowIconBaseOnTarget(cta: CTA): string | undefined {
    return this.#urlCtaService.isTargetBlank(cta) ? 'pi pi-arrow-up-right' : undefined;
  }
}
