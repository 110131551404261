import { computed, inject, Injectable } from '@angular/core';

import { CurrentSiteState } from '@ppg/configuration';
import { ClientSiteSettingsKeys, ResourceItem } from '@ppg/core/enums';
import { ResourceService } from '@ppg/shared/resource';
import { stringKeyOfEnum } from '@ppg/shared/utils';

@Injectable({ providedIn: 'root' })
export class ColorFamilyTypeService {
  readonly #currentSiteState = inject(CurrentSiteState);
  readonly #resourceService = inject(ResourceService);

  getFamilyTypeLabel(colorFamilyTypeName: string) {
    return this.colorFamilyTypeLabelsByTypeName()[colorFamilyTypeName] ?? '';
  }

  /**
   * ColorJourneyFamilyTypeNames is a setting in Config Manager that contains names for family types in PIM/Product API
   * ColorJourneyFamilyTypeLabelCodeNames is a setting in Config Manager that contains codenames for family type labels in Kontent AI
   *
   * The order of types in ColorJourneyFamilyTypeNames corresponds to the order of types in ColorJourneyFamilyTypeLabelCodeNames
   */
  private readonly colorFamilyTypeLabelsByTypeName = computed(() => {
    const typeLabelsByTypeName: Record<string, string> = {};

    this.colorFamilyTypeNames().forEach((colorFamilyTypeName, index) => {
      const resourceItem =
        ResourceItem[
          stringKeyOfEnum(ResourceItem, this.colorFamilyTypeLabelCodeNames()[index]) as keyof typeof ResourceItem
        ];
      const colorFamilyTypeLabel = resourceItem ? this.#resourceService.getResourceByKey(resourceItem) : '';
      typeLabelsByTypeName[colorFamilyTypeName] = colorFamilyTypeLabel;
    });
    return typeLabelsByTypeName;
  });

  private readonly colorFamilyTypeLabelCodeNames = computed(() => {
    return (
      this.#currentSiteState
        .getSiteSettingValueByKey(ClientSiteSettingsKeys.ColorJourneyFamilyTypeLabelCodeNames)
        .split(',') ?? []
    );
  });

  private readonly colorFamilyTypeNames = computed(() => {
    return (
      this.#currentSiteState.getSiteSettingValueByKey(ClientSiteSettingsKeys.ColorJourneyFamilyTypeNames)?.split(',') ??
      []
    );
  });
}
