import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'ppg-youtube',
  standalone: true,
  template: `<svg
    tabindex="0"
    class="icon"
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none">
    <rect width="40" height="40" rx="6" fill="var(--icon-background, white)" />
    <rect x="0.5" y="0.5" width="39" height="39" rx="5.5" stroke="var(--icon-border, white)" />
    <path
      d="M32.7833 13.5667C32.6351 12.9955 32.3379 12.4739 31.9221 12.0551C31.5063 11.6364 30.9868 11.3356 30.4167 11.1833C26.9599 10.7756 23.4804 10.5919 20 10.6333C16.5196 10.5919 13.0401 10.7756 9.58333 11.1833C9.01319 11.3356 8.49368 11.6364 8.07787 12.0551C7.66207 12.4739 7.36488 12.9955 7.21667 13.5667C6.83801 15.6899 6.65392 17.8433 6.66667 20C6.65328 22.1678 6.83737 24.3323 7.21667 26.4667C7.37256 27.0286 7.67316 27.5399 8.08846 27.9493C8.50375 28.3588 9.0192 28.6521 9.58333 28.8C13.0401 29.2077 16.5196 29.3914 20 29.35C23.4804 29.3914 26.9599 29.2077 30.4167 28.8C30.9808 28.6521 31.4962 28.3588 31.9115 27.9493C32.3268 27.5399 32.6274 27.0286 32.7833 26.4667C33.1626 24.3323 33.3467 22.1678 33.3333 20C33.3461 17.8433 33.162 15.6899 32.7833 13.5667ZM17.2667 23.9833V16.05L24.25 20L17.2667 23.9667V23.9833Z"
      fill="var(--icon-color, black)" />
  </svg>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YoutubeComponent {}
