import { ResourceItem } from '@ppg/core/enums';

export type ResourceDialogBody = { resourceKey: ResourceItem; argsToReplace?: { [key: string]: string } };

export interface ConfirmationDialogData {
  body: ResourceDialogBody | string;
  headerText: string;
  submitButtonText: string;
  cancelButtonText: string;
}

export abstract class ConfirmationDialog {
  protected data: ConfirmationDialogData | undefined;
}
