import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';

import { TextAlignment } from '@ppg/core/enums';
import { Container603010Model } from '@ppg/core/models';

import { Component603010Component } from '../component-603010/component-603010.component';

@Component({
  selector: 'ppg-container-603010',
  standalone: true,
  templateUrl: './container-603010.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [Component603010Component],
  styleUrl: './container-603010.component.scss',
})
export class Container603010Component {
  readonly model = input.required<Container603010Model>();
  readonly fullWidth = input<boolean>(false);

  readonly shouldShowTexts = computed(
    () => !!this.model().headline || !!this.model().subheadline || !!this.model().description,
  );
  readonly areTextsCentered = computed(() => this.model().textAlignment === TextAlignment.CENTER);
}
