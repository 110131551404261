import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, ElementRef, inject, input, viewChild } from '@angular/core';

import { StaticContentService } from '@ppg/core/content';
import { ClientSiteSettingsKeys, ImageLoading } from '@ppg/core/enums';
import { ComponentImageGalleryContent } from '@ppg/core/models';

import { ImageComponent } from '../../../components/image/image.component';
import { ImageWithColorsUsedComponent } from '../../../components/image-with-colors-used/image-with-colors-used.component';

@Component({
  selector: 'ppg-component-image-gallery',
  standalone: true,
  imports: [ImageComponent, ImageWithColorsUsedComponent, NgOptimizedImage],
  templateUrl: './component-image-gallery.component.html',
  styleUrl: './component-image-gallery.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComponentImageGalleryComponent {
  readonly content = input.required<ComponentImageGalleryContent>();

  private readonly staticContentService = inject(StaticContentService);

  readonly hasHeadline = computed(() => !!this.content().headline);
  readonly hasDescription = computed(() => !!this.content().description);
  readonly showCaption = computed(() => this.hasHeadline() || this.hasDescription());

  readonly imageStyle = {
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  };
  readonly imageLoading = ImageLoading.LAZY;

  private readonly captionRef = viewChild<ElementRef>('caption');
  readonly captionOffsetHeight = computed(() => this.captionRef()?.nativeElement?.offsetHeight ?? 0);

  readonly imageUnavailableAsset = this.staticContentService.getStaticAsset(
    ClientSiteSettingsKeys.AssetsImageUnavailable,
  )!;
}
