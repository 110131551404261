import { Component, computed, ElementRef, inject, input, signal } from '@angular/core';
import { ButtonModule } from 'primeng/button';

import { EdgeToEdgeContent } from '@ppg/core/models';
import { UrlCtaService } from '@ppg/shared/utils';

import { CircleComponent } from '../../components/icons/circle.component';
import { PauseComponent } from '../../components/icons/pause.component';
import { PlayComponent } from '../../components/icons/play.component';
import { ImageComponent } from '../../components/image/image.component';

@Component({
  selector: 'ppg-edge-to-edge',
  standalone: true,
  imports: [ImageComponent, ButtonModule, PlayComponent, CircleComponent, PauseComponent],
  templateUrl: './edge-to-edge.component.html',
  styleUrls: ['./edge-to-edge.component.scss'],
})
export class EdgeToEdgeComponent {
  readonly content = input.required<EdgeToEdgeContent>();

  public $isVideoPaused = signal(false);
  public $videoSourceHasError = signal(false);

  readonly #elementRef = inject(ElementRef);

  readonly #urlCtaService: UrlCtaService = inject(UrlCtaService);

  readonly imgUrl = computed(() => this.content().image.url ?? '');
  readonly imgHeight = computed(() => this.content().image.height ?? 0);
  readonly imgWidth = computed(() => this.content().image.width ?? 0);
  readonly video = computed(() => this.content().video);
  readonly headline = computed(() => this.content().headline ?? '');
  readonly description = computed(() => this.content().description ?? '');
  readonly cta = computed(() => this.content().cta);
  readonly textAreaPosition = computed(() => this.content().textAreaPosition.toLocaleLowerCase());

  readonly videoHtmlElement = computed(
    () => this.#elementRef.nativeElement.getElementsByTagName('video')?.item(0) as HTMLVideoElement,
  );

  navigateTo(): void {
    this.#urlCtaService.navigate(this.cta());
  }

  onPlayButtonClick(): void {
    if (this.videoHtmlElement()) {
      const videoPaused = this.videoHtmlElement().paused;
      this.$isVideoPaused.set(!videoPaused);
      videoPaused ? this.videoHtmlElement().play() : this.videoHtmlElement().pause();
    }
  }

  onVideoSourceError(): void {
    this.$videoSourceHasError.set(true);
  }
}
