import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash';

@Pipe({
  name: 'sortBy',
  standalone: true,
})
export class SortByPipe implements PipeTransform {
  transform<T>(value: T[], property: string, order: boolean | 'asc' | 'desc' = 'asc'): T[] {
    if (!value || value.length <= 1) {
      return value;
    }

    if (!property || property === '') {
      return order === 'asc' ? value.sort() : value.sort().reverse();
    }

    return orderBy(value, [property], [order]);
  }
}
