"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QNumberParam = exports.parseValueFromUrl = void 0;
const QParam_1 = require("../QParam");
const UrlParamHelper_1 = require("../UrlParamHelper");
const parseValueFromUrl = urlConformValue => {
  const value = (0, UrlParamHelper_1.parseLiteral)(urlConformValue);
  return typeof value === "string" ? Number(urlConformValue) : value;
};
exports.parseValueFromUrl = parseValueFromUrl;
class QNumberParam extends QParam_1.QParam {
  constructor() {
    super(...arguments);
    this.getUrlConformValue = UrlParamHelper_1.formatLiteralParam;
    this.parseValueFromUrl = exports.parseValueFromUrl;
  }
}
exports.QNumberParam = QNumberParam;
