<div class="w-full flex justify-content-center" [style.background-color]="pageSectionBackground()">
  <p-card [header]="headline()" class="mx-auto" styleClass="noBorder">
    @if (content().imageWithColorsUsed; as imageWithColorsUsed) {
      <ng-template pTemplate="header">
        <ppg-image-with-colors-used [content]="imageWithColorsUsed.content" />
      </ng-template>
    } @else {
      @if (image(); as image) {
        <ng-template pTemplate="header">
          <ppg-image [url]="image.url" [width]="image.width" [height]="image.height" />
        </ng-template>
      }
    }
    @if (headline()) {
      <ppg-horizontal-divider class="content-divider"></ppg-horizontal-divider>
    }
    @if (richTexts().length > 0) {
      <div class="content-container">
        @for (richText of richTexts(); track $index) {
          <ppg-rich-text [content]="richText.content"> </ppg-rich-text>
        }
      </div>
    }
    @if (ctaItems().length > 0) {
      <ng-template pTemplate="footer">
        <div class="cta-container">
          @for (cta of ctaItems(); track $index) {
            <p-button
              class="cta-content-button"
              [label]="cta.content?.text"
              iconPos="right"
              [outlined]="false"
              [icon]="getArrowIconBaseOnTarget(cta)"
              (onClick)="navigateTo(cta)"></p-button>
          }
        </div>
      </ng-template>
    }
  </p-card>
</div>
