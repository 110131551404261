import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'ppg-play',
  standalone: true,
  template: `<svg xmlns="http://www.w3.org/2000/svg" width="58" height="58" viewBox="0 0 58 58" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M46.496 32.5942C48.4975 31.4398 48.4975 28.5513 46.496 27.3968L20.4989 12.4017C18.4989 11.2481 16 12.6916 16 15.0004V44.9906C16 47.2995 18.4989 48.7429 20.4989 47.5893L46.496 32.5942Z"
      fill="white" />
  </svg>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlayComponent {}
