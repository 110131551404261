"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getIdentityConverter = exports.IdentityConverterImpl = void 0;
class IdentityConverterImpl {
  constructor() {
    this.id = "Identity";
    this.from = "any";
    this.to = "any";
  }
  convertFrom(value) {
    return value;
  }
  convertTo(value) {
    return value;
  }
}
exports.IdentityConverterImpl = IdentityConverterImpl;
const identityConverter = new IdentityConverterImpl();
function getIdentityConverter() {
  return identityConverter;
}
exports.getIdentityConverter = getIdentityConverter;
