import {
  QueryObject,
  QStringPath,
  QCollectionPath,
  QStringCollection,
  QEntityPath,
  QEntityCollectionPath,
  QId,
  QStringParam,
  QEnumPath,
  QNumberPath,
  QBooleanPath,
  QDateTimeOffsetPath,
  QEnumCollectionPath,
} from '@odata2ts/odata-query-objects';

import type { ProductId, CategoryId, ItemId, MediaAssetId } from './PpgEnterpriseApiPimOutboundContractsModelsModel';
import {
  NodeType,
  AssetQuality,
  AssetType,
  PaintTypes,
  ReferenceType,
} from './PpgEnterpriseApiPimOutboundContractsModelsModel';

export class QProduct extends QueryObject {
  public readonly Id = new QStringPath(this.withPrefix('Id'));
  public readonly ProductNumber = new QStringPath(this.withPrefix('ProductNumber'));
  public readonly CategoryIds = new QCollectionPath(this.withPrefix('CategoryIds'), () => QStringCollection);
  public readonly ParentProductCode = new QStringPath(this.withPrefix('ParentProductCode'));
  public readonly MarketingData = new QEntityPath(this.withPrefix('MarketingData'), () => QProductMarketingData);
  public readonly LogisticsData = new QEntityPath(this.withPrefix('LogisticsData'), () => QLogisticsData);
  public readonly TechnicalData = new QEntityPath(this.withPrefix('TechnicalData'), () => QTechnicalData);
  public readonly EhsData = new QEntityPath(this.withPrefix('EhsData'), () => QEhsData);
  public readonly StatusData = new QEntityPath(this.withPrefix('StatusData'), () => QStatusData);
  public readonly References = new QEntityCollectionPath(this.withPrefix('References'), () => QReference);
  public readonly Attributes = new QEntityCollectionPath(this.withPrefix('Attributes'), () => QAttribute);
  public readonly Items = new QEntityCollectionPath(this.withPrefix('Items'), () => QItem);
  public readonly Assets = new QEntityCollectionPath(this.withPrefix('Assets'), () => QMediaAsset);
  public readonly Icons = new QCollectionPath(this.withPrefix('Icons'), () => QStringCollection);
}

export const qProduct = new QProduct();

export class QProductId extends QId<ProductId> {
  private readonly params = [new QStringParam('Id')];

  getParams() {
    return this.params;
  }
}

export class QCategory extends QueryObject {
  public readonly Id = new QStringPath(this.withPrefix('Id'));
  public readonly Name = new QEntityCollectionPath(this.withPrefix('Name'), () => QTranslatedText);
  public readonly Description = new QEntityCollectionPath(this.withPrefix('Description'), () => QTranslatedText);
  public readonly MetaTitle = new QEntityCollectionPath(this.withPrefix('MetaTitle'), () => QTranslatedText);
  public readonly Identifier = new QStringPath(this.withPrefix('Identifier'));
  public readonly ParentId = new QStringPath(this.withPrefix('ParentId'));
  public readonly NodeType = new QEnumPath(this.withPrefix('NodeType'), NodeType);
  public readonly WebHierarchyNumber = new QStringPath(this.withPrefix('WebHierarchyNumber'));
  public readonly WebHierarchyLevel = new QNumberPath(this.withPrefix('WebHierarchyLevel'));
  public readonly StatusData = new QEntityPath(this.withPrefix('StatusData'), () => QStatusData);
  public readonly Assets = new QEntityCollectionPath(this.withPrefix('Assets'), () => QMediaAsset);
}

export const qCategory = new QCategory();

export class QCategoryId extends QId<CategoryId> {
  private readonly params = [new QStringParam('Id')];

  getParams() {
    return this.params;
  }
}

export class QItem extends QueryObject {
  public readonly Id = new QStringPath(this.withPrefix('Id'));
  public readonly ProductId = new QStringPath(this.withPrefix('ProductId'));
  public readonly ArticleNumber = new QStringPath(this.withPrefix('ArticleNumber'));
  public readonly KMatReference = new QStringPath(this.withPrefix('KMatReference'));
  public readonly SkuCode = new QStringPath(this.withPrefix('SkuCode'));
  public readonly SkuNumber = new QStringPath(this.withPrefix('SkuNumber'));
  public readonly SalesText = new QEntityCollectionPath(this.withPrefix('SalesText'), () => QTranslatedText);
  public readonly Warning2 = new QStringPath(this.withPrefix('Warning2'));
  public readonly MarketingData = new QEntityPath(this.withPrefix('MarketingData'), () => QItemMarketingData);
  public readonly LogisticsData = new QEntityPath(this.withPrefix('LogisticsData'), () => QLogisticsData);
  public readonly StatusData = new QEntityPath(this.withPrefix('StatusData'), () => QStatusData);
  public readonly References = new QEntityCollectionPath(this.withPrefix('References'), () => QReference);
  public readonly Attributes = new QEntityCollectionPath(this.withPrefix('Attributes'), () => QAttribute);
  public readonly Prices = new QEntityCollectionPath(this.withPrefix('Prices'), () => QItemPrice);
  public readonly Assets = new QEntityCollectionPath(this.withPrefix('Assets'), () => QMediaAsset);
}

export const qItem = new QItem();

export class QItemId extends QId<ItemId> {
  private readonly params = [new QStringParam('Id')];

  getParams() {
    return this.params;
  }
}

export class QMediaAsset extends QueryObject {
  public readonly Id = new QStringPath(this.withPrefix('Id'));
  public readonly LanguageName = new QStringPath(this.withPrefix('LanguageName'));
  public readonly Name = new QStringPath(this.withPrefix('Name'));
  public readonly Title = new QStringPath(this.withPrefix('Title'));
  public readonly Description = new QStringPath(this.withPrefix('Description'));
  public readonly Channel = new QStringPath(this.withPrefix('Channel'));
  public readonly Url = new QStringPath(this.withPrefix('Url'));
  public readonly Quality = new QEnumPath(this.withPrefix('Quality'), AssetQuality);
  public readonly Type = new QEnumPath(this.withPrefix('Type'), AssetType);
}

export const qMediaAsset = new QMediaAsset();

export class QMediaAssetId extends QId<MediaAssetId> {
  private readonly params = [new QStringParam('Id')];

  getParams() {
    return this.params;
  }
}

export class QProductMarketingData extends QueryObject {
  public readonly Name = new QStringPath(this.withPrefix('Name'));
  public readonly PrimarySKU = new QStringPath(this.withPrefix('PrimarySKU'));
  public readonly FunctionalName = new QEntityCollectionPath(this.withPrefix('FunctionalName'), () => QTranslatedText);
  public readonly OverviewDescription = new QEntityCollectionPath(
    this.withPrefix('OverviewDescription'),
    () => QTranslatedText,
  );
  public readonly LongMarketingText = new QEntityCollectionPath(
    this.withPrefix('LongMarketingText'),
    () => QTranslatedText,
  );
  public readonly ShortMarketingText = new QEntityCollectionPath(
    this.withPrefix('ShortMarketingText'),
    () => QTranslatedText,
  );
  public readonly MetaTitle = new QStringPath(this.withPrefix('MetaTitle'));
  public readonly MetaDescription = new QEntityCollectionPath(
    this.withPrefix('MetaDescription'),
    () => QTranslatedText,
  );
  public readonly Usp = new QStringPath(this.withPrefix('Usp'));
  public readonly Banner = new QStringPath(this.withPrefix('Banner'));
  public readonly BrandId = new QStringPath(this.withPrefix('BrandId'));
  public readonly BrandName = new QStringPath(this.withPrefix('BrandName'));
  public readonly ProductSorting = new QStringPath(this.withPrefix('ProductSorting'));
  public readonly ProfileForProductFiltering = new QCollectionPath(
    this.withPrefix('ProfileForProductFiltering'),
    () => QStringCollection,
  );
  public readonly KeywordsTags = new QStringPath(this.withPrefix('KeywordsTags'));
}

export const qProductMarketingData = new QProductMarketingData();

export class QTranslatedText extends QueryObject {
  public readonly Language = new QStringPath(this.withPrefix('Language'));
  public readonly Translation = new QStringPath(this.withPrefix('Translation'));
}

export const qTranslatedText = new QTranslatedText();

export class QTechnicalData extends QueryObject {
  public readonly ColourbankProductCode = new QStringPath(this.withPrefix('ColourbankProductCode'));
  public readonly ConditionsForUse = new QStringPath(this.withPrefix('ConditionsForUse'));
  public readonly DryingTime = new QNumberPath(this.withPrefix('DryingTime'));
  public readonly OverpaintableAfter = new QNumberPath(this.withPrefix('OverpaintableAfter'));
  public readonly CoverageText = new QStringPath(this.withPrefix('CoverageText'));
}

export const qTechnicalData = new QTechnicalData();

export class QEhsData extends QueryObject {
  public readonly UrlSafetyDataSheet = new QStringPath(this.withPrefix('UrlSafetyDataSheet'));
  public readonly Disclaimer = new QStringPath(this.withPrefix('Disclaimer'));
  public readonly SignalWordEu = new QStringPath(this.withPrefix('SignalWordEu'));
  public readonly GhsSymbolsEu = new QStringPath(this.withPrefix('GhsSymbolsEu'));
  public readonly Hphrases = new QCollectionPath(this.withPrefix('Hphrases'), () => QStringCollection);
}

export const qEhsData = new QEhsData();

export class QStatusData extends QueryObject {
  public readonly IsActive = new QBooleanPath(this.withPrefix('IsActive'));
  public readonly IsSellable = new QBooleanPath(this.withPrefix('IsSellable'));
  public readonly Status = new QStringPath(this.withPrefix('Status'));
  public readonly AvailabilityStartDate = new QDateTimeOffsetPath(this.withPrefix('AvailabilityStartDate'));
  public readonly AvailabilityEndDate = new QDateTimeOffsetPath(this.withPrefix('AvailabilityEndDate'));
  public readonly LastModifiedDate = new QDateTimeOffsetPath(this.withPrefix('LastModifiedDate'));
}

export const qStatusData = new QStatusData();

export class QItemMarketingData extends QueryObject {
  public readonly Description = new QEntityCollectionPath(this.withPrefix('Description'), () => QTranslatedText);
  public readonly DescriptionSAP = new QStringPath(this.withPrefix('DescriptionSAP'));
  public readonly MaterialType = new QStringPath(this.withPrefix('MaterialType'));
  public readonly PaintTypes = new QEnumCollectionPath(this.withPrefix('PaintTypes'), PaintTypes);
  public readonly PaintType = new QStringPath(this.withPrefix('PaintType'));
}

export const qItemMarketingData = new QItemMarketingData();

export class QLogisticsData extends QueryObject {
  public readonly Gtin = new QStringPath(this.withPrefix('Gtin'));
  public readonly InnerPackQuantity = new QStringPath(this.withPrefix('InnerPackQuantity'));
  public readonly SizeDimensions = new QStringPath(this.withPrefix('SizeDimensions'));
  public readonly UpcBarcode = new QStringPath(this.withPrefix('UpcBarcode'));
  public readonly GrossWeight = new QNumberPath(this.withPrefix('GrossWeight'));
  public readonly NetWeight = new QNumberPath(this.withPrefix('NetWeight'));
  public readonly NetVolume = new QNumberPath(this.withPrefix('NetVolume'));
  public readonly WeightUOM = new QStringPath(this.withPrefix('WeightUOM'));
  public readonly VolumeUOM = new QStringPath(this.withPrefix('VolumeUOM'));
}

export const qLogisticsData = new QLogisticsData();

export class QReference extends QueryObject {
  public readonly Items = new QEntityCollectionPath(this.withPrefix('Items'), () => QItemReference);
  public readonly Products = new QEntityCollectionPath(this.withPrefix('Products'), () => QProductReference);
}

export const qReference = new QReference();

export class QItemReference extends QueryObject {
  public readonly ItemId = new QStringPath(this.withPrefix('ItemId'));
  public readonly ItemNumber = new QStringPath(this.withPrefix('ItemNumber'));
  public readonly Type = new QEnumPath(this.withPrefix('Type'), ReferenceType);
  public readonly Sequence = new QNumberPath(this.withPrefix('Sequence'));
}

export const qItemReference = new QItemReference();

export class QProductReference extends QueryObject {
  public readonly ProductId = new QStringPath(this.withPrefix('ProductId'));
  public readonly ProductNumber = new QStringPath(this.withPrefix('ProductNumber'));
  public readonly Type = new QEnumPath(this.withPrefix('Type'), ReferenceType);
  public readonly Sequence = new QNumberPath(this.withPrefix('Sequence'));
}

export const qProductReference = new QProductReference();

export class QAttribute extends QueryObject {
  public readonly Name = new QStringPath(this.withPrefix('Name'));
  public readonly Values = new QCollectionPath(this.withPrefix('Values'), () => QStringCollection);
}

export const qAttribute = new QAttribute();

export class QItemPrice extends QueryObject {
  public readonly Amount = new QNumberPath(this.withPrefix('Amount'));
  public readonly Currency = new QStringPath(this.withPrefix('Currency'));
  public readonly Territory = new QStringPath(this.withPrefix('Territory'));
  public readonly Type = new QStringPath(this.withPrefix('Type'));
  public readonly UnitUOM = new QStringPath(this.withPrefix('UnitUOM'));
  public readonly ValidFrom = new QStringPath(this.withPrefix('ValidFrom'));
  public readonly ValidTo = new QStringPath(this.withPrefix('ValidTo'));
}

export const qItemPrice = new QItemPrice();
