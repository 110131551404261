import { inject, Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Subject, take } from 'rxjs';

import { ResourceItem } from '@ppg/core/enums';
import { Color } from '@ppg/core/models';
import { ConfirmationDialogComponent, DialogSize } from '@ppg/shared/confirmation-dialog';
import { ResourceService } from '@ppg/shared/resource';

import { FavoriteColorLocalStorageService } from '../favorite-colors-local-storage/favorite-colors-local-storage.service';

@Injectable({ providedIn: 'root' })
export class FavoriteColorsService {
  readonly #favoriteColorStorageService = inject(FavoriteColorLocalStorageService);
  readonly #messageService = inject(MessageService);
  readonly #dialogService = inject(DialogService);
  readonly #resourceService = inject(ResourceService);

  readonly #favoriteColorAddedSubject = new Subject<Color>();
  readonly favoriteColorAdded$ = this.#favoriteColorAddedSubject.asObservable();
  readonly #favoriteColorRemovedSubject = new Subject<Color>();
  readonly favoriteColorRemoved$ = this.#favoriteColorRemovedSubject.asObservable();

  readonly resources = {
    toastSummary: ResourceItem.TOAST_REMOVE_FAVORITE_SUCCESS_CONFIRMATION_TITLE,
    toastDetailRemove: ResourceItem.TOAST_REMOVE_FAVORITE_SUCCESS_CONFIRMATION_MESSAGE,
    toastDetailAdd: ResourceItem.TOAST_ADD_FAVORITE_SUCCESS_CONFIRMATION_MESSAGE,
    confirmationDialogHeaderText: ResourceItem.CONFIRMATION_DIALOG_REMOVE_FAVORITE_TITLE,
    confirmationDialogBodyText: ResourceItem.CONFIRMATION_DIALOG_REMOVE_FAVORITE_MESSAGE,
    cancelButton: ResourceItem.CONFIRMATION_DIALOG_REMOVE_FAVORITE_CANCEL_BUTTON,
    submitButton: ResourceItem.CONFIRMATION_DIALOG_REMOVE_FAVORITE_CONFIRM_BUTTON,
  };

  addToFavorites(color: Color): void {
    this.#favoriteColorStorageService.addFavoriteColor(color);
    this.#favoriteColorAddedSubject.next(color);
    this.showSuccessToast(this.resources.toastDetailAdd);
  }

  removeFromFavorites(color: Color): void {
    const dialogRef = this.#dialogService.open(ConfirmationDialogComponent, {
      data: {
        headerText: this.#resourceService.getResourceByKey(this.resources.confirmationDialogHeaderText),
        body: {
          resourceKey: this.resources.confirmationDialogBodyText,
          argsToReplace: { colorName: color.colorName },
        },
        cancelButtonText: this.#resourceService.getResourceByKey(this.resources.cancelButton),
        submitButtonText: this.#resourceService.getResourceByKey(this.resources.submitButton),
      },
      width: DialogSize.Medium,
      dismissableMask: true,
    });

    dialogRef.onClose.pipe(take(1)).subscribe((confirmationResult: boolean) => {
      if (confirmationResult) {
        this.#favoriteColorStorageService.removeFavoriteColor(color.productNumber);
        this.#favoriteColorRemovedSubject.next(color);
        this.showSuccessToast(this.resources.toastDetailRemove);
      }
    });
  }

  private showSuccessToast(toastDetailResourceKey: ResourceItem): void {
    this.#messageService.add({
      severity: 'success',
      summary: this.#resourceService.getResourceByKey(this.resources.toastSummary),
      detail: this.#resourceService.getResourceByKey(toastDetailResourceKey),
    });
  }
}
