import { Component, computed, inject, input } from '@angular/core';
import { RouterLink } from '@angular/router';

import { UrlTarget } from '@ppg/core/enums';
import { ComponentHighImageStrip } from '@ppg/core/models';
import { UrlCtaService } from '@ppg/shared/utils';

import { ImageComponent } from '../../../components/image/image.component';
import { ImageWithColorsUsedComponent } from '../../../components/image-with-colors-used/image-with-colors-used.component';

@Component({
  selector: 'ppg-high-image-strip-component',
  standalone: true,
  imports: [ImageComponent, RouterLink, ImageWithColorsUsedComponent],
  templateUrl: './high-image-strip.component.html',
  styleUrls: ['./high-image-strip.component.scss'],
})
export class HighImageStripComponent {
  readonly card = input.required<ComponentHighImageStrip>();

  readonly #urlCtaService: UrlCtaService = inject(UrlCtaService);

  readonly imgUrl = computed(() => this.card().image?.url ?? '');
  readonly imgHeight = computed(() => this.card().image?.height ?? 0);
  readonly imgWidth = computed(() => this.card().image?.width ?? 0);
  readonly title = computed(() => this.card()?.title ?? '');
  readonly description = computed(() => this.card()?.description ?? '');
  readonly url = computed(() => this.card()?.cta?.content?.url ?? '');

  readonly urlTarget = UrlTarget;

  isAbsoluteUrlPath(): boolean {
    return this.#urlCtaService.isAbsoluteUrlPath(this.url());
  }

  #urlParts = computed(() => this.#urlCtaService.parseUrl(this.url()));
  readonly relativePathUrl = computed(() => this.#urlParts().path ?? '');
  readonly relativePathAnchor = computed(() => this.#urlParts().anchor);
}
