<div class="edge-to-edge-container">
  @if (!video() || $videoSourceHasError()) {
    <ppg-image [url]="imgUrl()" [height]="imgHeight()" [width]="imgWidth()" />
  } @else {
    <video [src]="video()?.url" type="video/mp4" autoplay [muted]="'muted'" loop (error)="onVideoSourceError()">
      <track />
      <ppg-image [url]="imgUrl()" [height]="imgHeight()" [width]="imgWidth()"> </ppg-image>
    </video>
  }
  <div class="edge-to-edge-content-container" [class]="textAreaPosition()">
    <div class="edge-to-edge-content">
      <h1 class="m-0 p-0 headline">{{ headline() }}</h1>
      <h5 class="m-0 p-0 description">{{ description() }}</h5>
      @if (cta()) {
        <p-button
          class="cta-button"
          [label]="cta()?.content?.text"
          [outlined]="false"
          (onClick)="navigateTo()"
          tabindex="0"></p-button>
      }
    </div>
  </div>
  @if (video() && !$videoSourceHasError()) {
    <div class="icons-container">
      <div class="icons" (click)="onPlayButtonClick()" (keydown)="onPlayButtonClick()" tabindex="1">
        @if (this.$isVideoPaused()) {
          <ppg-play></ppg-play>
        } @else {
          <ppg-pause></ppg-pause>
        }
        <ppg-circle></ppg-circle>
      </div>
    </div>
  }
</div>
