<div class="hero-banner-container">
  @if (!video() || $videoSourceHasError()) {
    @if (content().imageWithColorsUsed; as imageWithColorsUsed) {
      <ppg-image-with-colors-used [content]="imageWithColorsUsed.content" />
    } @else if (content().image) {
      <ppg-image [url]="imgUrl()" [height]="imgHeight()" [width]="imgWidth()" />
    }
  } @else {
    <video [src]="video()?.url" type="video/mp4" autoplay [muted]="'muted'" loop (error)="onVideoSourceError()">
      <track />
      <ppg-image [url]="imgUrl()" [height]="imgHeight()" [width]="imgWidth()"> </ppg-image>
    </video>
  }
  <div
    class="hero-banner-content-container"
    [class]="{
      'content-centered': isContentCentered(),
      'content-bottom-centered': isContentBottomCentered(),
      'content-left-centered': isContentLeftCentered(),
    }">
    <div class="hero-banner-content">
      <h1 class="headline m-0 p-0">{{ headline() }}</h1>
      <ppg-rich-text class="description" [content]="description()"></ppg-rich-text>
      @if (cta()) {
        <p-button
          class="cta-button"
          [label]="cta()?.content?.text"
          [outlined]="false"
          (onClick)="navigateTo()"
          tabindex="0"></p-button>
      }
    </div>
  </div>
  @if (video() && !$videoSourceHasError()) {
    <div class="icons-container">
      <div class="icons" (click)="onPlayButtonClick()" (keydown)="onPlayButtonClick()" tabindex="1">
        @if (this.$isVideoPaused()) {
          <ppg-play></ppg-play>
        } @else {
          <ppg-pause></ppg-pause>
        }
        <ppg-circle></ppg-circle>
      </div>
    </div>
  }
</div>
