<div class="high-image-strip">
  <div class="image-container">
    @if (card().imageWithColorsUsed; as imageWithColorsUsed) {
      <ppg-image-with-colors-used [content]="imageWithColorsUsed.content" [isResponsive]="false" />
    } @else if (card().image) {
      <ppg-image [url]="imgUrl()" [height]="imgHeight()" [width]="imgWidth()" [isResponsive]="false" />
    }
  </div>
  <div class="flex flex-column justify-content-flex-start align-self-stretch text-container">
    @if (url() && isAbsoluteUrlPath()) {
      <h5 class="p-0 m-0 flex-1"><a class="title" [href]="url()" [text]="title()" [target]="urlTarget.SELF"></a></h5>
    } @else if (url() && !isAbsoluteUrlPath()) {
      <h5 class="p-0 m-0 flex-1">
        <a
          class="title"
          [text]="title()"
          [routerLink]="relativePathUrl()"
          [fragment]="relativePathAnchor()"
          [target]="urlTarget.SELF"></a>
      </h5>
    } @else {
      <h5 class="p-0 m-0 flex-1 title" [innerHTML]="title()"></h5>
    }

    @if (description()) {
      <div class="flex-1 description" [innerHTML]="description()"></div>
    }
  </div>
</div>
