"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QNumericEnumParam = void 0;
const NumericEnumConverter_1 = require("../../enum/NumericEnumConverter");
const BaseEnumParam_1 = require("./BaseEnumParam");
class QNumericEnumParam extends BaseEnumParam_1.BaseEnumParam {
  constructor(theEnum, name, mappedName) {
    super(name, mappedName);
    this.theEnum = theEnum;
    if (!theEnum) {
      throw new Error("Enum must be supplied!");
    }
    this.converter = new NumericEnumConverter_1.NumericEnumConverter(theEnum);
  }
  mapValue(value) {
    return this.converter.convertFrom(value);
  }
  mapValueBack(value) {
    return this.converter.convertTo(value);
  }
}
exports.QNumericEnumParam = QNumericEnumParam;
