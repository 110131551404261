"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QDateTimeOffsetPath = void 0;
const UrlParamHelper_1 = require("../../param/UrlParamHelper");
const QBasePath_1 = require("../base/QBasePath");
const DateTimeFunctions_1 = require("./DateTimeFunctions");
const IdentityFormatter_1 = require("./IdentityFormatter");
const QDatePath_1 = require("./QDatePath");
const QTimeOfDayPath_1 = require("./QTimeOfDayPath");
class QDateTimeOffsetPath extends QBasePath_1.QBasePath {
  constructor() {
    super(...arguments);
    this.formatValue = IdentityFormatter_1.identityFormatter;
    this.year = (0, DateTimeFunctions_1.yearFn)(this.path);
    this.month = (0, DateTimeFunctions_1.monthFn)(this.path);
    this.day = (0, DateTimeFunctions_1.dayFn)(this.path);
    this.hour = (0, DateTimeFunctions_1.hourFn)(this.path);
    this.minute = (0, DateTimeFunctions_1.minuteFn)(this.path);
    this.second = (0, DateTimeFunctions_1.secondFn)(this.path);
  }
  date() {
    const pathExpression = (0, UrlParamHelper_1.buildFunctionExpression)("date" /* DateTimeFilterFunctions.DATE */, this.path);
    return new QDatePath_1.QDatePath(pathExpression);
  }
  time() {
    const pathExpression = (0, UrlParamHelper_1.buildFunctionExpression)("time" /* DateTimeFilterFunctions.TIME */, this.path);
    return new QTimeOfDayPath_1.QTimeOfDayPath(pathExpression);
  }
}
exports.QDateTimeOffsetPath = QDateTimeOffsetPath;
