import { NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, signal } from '@angular/core';
import { RouterLink } from '@angular/router';

import { CurrentSiteState } from '@ppg/configuration';
import { StaticContentService } from '@ppg/core/content';
import { ClientSiteSettingsKeys, UrlTarget } from '@ppg/core/enums';
import { ColorInfoValid } from '@ppg/core/models';
import { FeatureRoutePathService } from '@ppg/core/navigation';

import { BackgroundDirective } from '../../../../lib/common/directives/background.directive';

@Component({
  selector: 'ppg-color-used',
  standalone: true,
  imports: [RouterLink, NgTemplateOutlet, BackgroundDirective, NgOptimizedImage],
  templateUrl: './color-used.component.html',
  styleUrl: './color-used.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColorUsedComponent {
  readonly color = input.required<ColorInfoValid>();

  private readonly currentSiteState = inject(CurrentSiteState);
  private readonly staticContentService = inject(StaticContentService);
  private readonly featureRoutePathService = inject(FeatureRoutePathService);

  readonly imageError = signal(false);

  readonly colorName = computed(() => this.color().colorName);
  readonly colorCode = computed(() => this.color().colorCode);
  readonly rgb = computed(() => this.color().rgb);
  readonly image = computed(() => this.color().image);
  readonly isValid = computed(
    () => this.color().isValid && (!!this.colorName() || (this.showColorCode && !!this.colorCode())),
  );
  readonly canPreviewColor = computed(() => !!(this.rgb() || (this.image() && !this.imageError())));

  readonly urlTarget = UrlTarget.BLANK;
  readonly link = computed(() => this.featureRoutePathService.getColorDetailsPath(this.color()));

  readonly showColorCode = !this.currentSiteState.getSiteFlag(ClientSiteSettingsKeys.ColorJourneyColorCodeHidden);
  readonly colorUnavailableAsset = this.staticContentService.getStaticAsset(
    ClientSiteSettingsKeys.AssetsColorUnavailable,
  )!;
}
