import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'ppg-twitter-x',
  standalone: true,
  template: `<svg
    tabindex="0"
    class="icon"
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none">
    <rect width="40" height="40" rx="6" fill="var(--icon-background, white)" />
    <rect x="0.5" y="0.5" width="39" height="39" rx="5.5" stroke="var(--icon-border, white)" />
    <path
      d="M22.5369 17.9582L32.4642 6.66666H30.1118L21.4919 16.4709L14.6073 6.66666H6.66666L17.0776 21.4924L6.66666 33.3333H9.01923L18.122 22.9797L25.3927 33.3333H33.3333L22.5364 17.9582H22.5369ZM19.3148 21.6231L18.2599 20.1468L9.8669 8.39957H13.4803L20.2536 17.8799L21.3084 19.3562L30.1129 31.6792H26.4995L19.3148 21.6236V21.6231Z"
      fill="var(--icon-color, black)" />
  </svg>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TwitterXComponent {}
