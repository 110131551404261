p-dynamicdialog#ppg-sort-by-dialog {
  .p-dialog .p-dialog-header {
    padding-bottom: 0;
  }
}
.ppg-sort-by-dialog {
  width: max-content;
  margin: 0;
}
.ppg-sort-by-dialog-absolute {
  max-height: none !important;
  position: absolute !important;
}
.transparent-mask {
  background-color: #ffffff00;
}
