import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, input } from '@angular/core';

@Component({
  selector: 'ppg-error-base',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './error-base.component.html',
  styleUrl: './error-base.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorBaseComponent {
  pageTitle = input.required<string>();
  pageDescription = input.required<string>();
}
