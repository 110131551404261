"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QDatePath = void 0;
const QBasePath_1 = require("../base/QBasePath");
const DateTimeFunctions_1 = require("./DateTimeFunctions");
const IdentityFormatter_1 = require("./IdentityFormatter");
class QDatePath extends QBasePath_1.QBasePath {
  constructor() {
    super(...arguments);
    this.formatValue = IdentityFormatter_1.identityFormatter;
    this.year = (0, DateTimeFunctions_1.yearFn)(this.path);
    this.month = (0, DateTimeFunctions_1.monthFn)(this.path);
    this.day = (0, DateTimeFunctions_1.dayFn)(this.path);
  }
}
exports.QDatePath = QDatePath;
