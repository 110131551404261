<div class="flex flex-column align-items-stretch w-full h-full gap-3">
  <div class="p-2">
    <div class="colors-image grid grid-nogutter">
      @if (showEmptyState()) {
        <div class="col-7 color-image-unavailable">
          <img [ngSrc]="url()" [alt]="description()" width="31" height="32" />
          <p class="unavailable-message">{{ description() }}</p>
        </div>
      } @else {
        <div
          class="colors-image-left-side col-7"
          ppgBackground
          [imageUrl]="primaryColor().image"
          [rgb]="primaryColor().rgb"
          (onImageLoaded)="primaryColorImageLoadFailed.set(false)"
          (onImageLoadError)="primaryColorImageLoadFailed.set(true)"></div>
        <div class="colors-image-right-side col-5">
          <div
            class="color-30"
            ppgBackground
            [imageUrl]="secondaryColor().image"
            [rgb]="secondaryColor().rgb"
            (onImageLoaded)="secondaryColorImageLoadFailed.set(false)"
            (onImageLoadError)="secondaryColorImageLoadFailed.set(true)"></div>
          <div
            class="color-10"
            ppgBackground
            [imageUrl]="accentColor().image"
            [rgb]="accentColor().rgb"
            (onImageLoaded)="accentColorImageLoadFailed.set(false)"
            (onImageLoadError)="accentColorImageLoadFailed.set(true)"></div>
        </div>
      }
    </div>
  </div>
  @if (!showEmptyState()) {
    <div class="flex flex-column gap-3 justify-content-between h-full">
      <div class="text-center">
        <h4 class="headline m-0">{{ model().headline }}</h4>
      </div>
      <div class="gap-3">
        @for (color of validColors(); track color) {
          <ppg-color-used [color]="color"></ppg-color-used>
        }
      </div>
    </div>
  }
</div>
