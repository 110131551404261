import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'ppg-pause',
  standalone: true,
  template: `<svg
    tabindex="0"
    class="icon"
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.15476 5.75C8.03376 5.75 7.125 6.65876 7.125 7.77976V19.9583C7.125 21.0793 8.03376 21.9881 9.15476 21.9881C10.2758 21.9881 11.1845 21.0793 11.1845 19.9583V7.77976C11.1845 6.65876 10.2758 5.75 9.15476 5.75ZM19.3037 5.75C18.1827 5.75 17.2739 6.65876 17.2739 7.77976V19.9583C17.2739 21.0793 18.1827 21.9881 19.3037 21.9881C20.4247 21.9881 21.3334 21.0793 21.3334 19.9583V7.77976C21.3334 6.65876 20.4247 5.75 19.3037 5.75Z"
      fill="white" />
  </svg>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PauseComponent {}
