import { Injectable } from '@angular/core';

import { ColorInfoInvalid, ColorInfoValid, Rgb } from '@ppg/core/models';

@Injectable({ providedIn: 'root' })
export class ColorUtils {
  isLight(rgb: string | Rgb | undefined): boolean {
    if (!rgb) {
      return true;
    }

    const transformedRgb = (rgb as Rgb).r !== undefined ? (rgb as Rgb) : this.stringToRgb(rgb as string);

    if (transformedRgb) {
      return transformedRgb.r * 0.299 + transformedRgb.g * 0.587 + transformedRgb.b * 0.114 > 130;
    }

    return true;
  }

  stringToRgb(value: string | undefined): Rgb | undefined {
    if (!value) {
      return undefined;
    }

    const rgbValues = value.split(',').map((color) => parseInt(color.trim(), 10));

    if (rgbValues.some((color) => isNaN(color) || color < 0) || rgbValues.length !== 3) {
      return undefined;
    }

    return { r: rgbValues[0], g: rgbValues[1], b: rgbValues[2] };
  }

  rgbToHex(rgb: Rgb | undefined): string | '' {
    if (!rgb || typeof rgb.r !== 'number' || typeof rgb.g !== 'number' || typeof rgb.b !== 'number') {
      return '';
    }
    return '#' + this.#decimalToHex(rgb.r) + this.#decimalToHex(rgb.g) + this.#decimalToHex(rgb.b);
  }

  #decimalToHex(colorNumber: number): string {
    const hex = colorNumber.toString(16);
    return hex.length == 1 ? '0' + hex : hex;
  }

  matchColorsToProductNumbers(
    colors: ColorInfoValid[],
    productsNumbers: string[],
  ): (ColorInfoValid | ColorInfoInvalid)[] {
    return productsNumbers.map((productNumber) => this.#findColorByProductNumber(colors, productNumber));
  }

  #findColorByProductNumber(colors: ColorInfoValid[], productNumber: string): ColorInfoValid | ColorInfoInvalid {
    const fetchedColorInfo = colors.find((colorInfo) => colorInfo.productNumber === productNumber);

    return fetchedColorInfo ?? new ColorInfoInvalid(productNumber, false, undefined);
  }
}
