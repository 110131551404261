import { ChangeDetectionStrategy, Component, inject, input, Signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { map, Observable, switchMap } from 'rxjs';

import {
  ColorInfoValid,
  Component603010Content,
  Component603010ContentModel,
  Component603010Model,
  Container603010Content,
  Container603010Model,
} from '@ppg/core/models';
import { ColorInfoService, ColorUtils } from '@ppg/shared/color/services';

import { Container603010Component } from '../container-603010/container-603010.component';

@Component({
  selector: 'ppg-container-603010-content',
  standalone: true,
  templateUrl: './container-603010-content.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [Container603010Component],
  styleUrl: './container-603010-content.component.scss',
})
export class Container603010ContentComponent {
  readonly #colorInfoService = inject(ColorInfoService);
  readonly #colorUtils = inject(ColorUtils);

  readonly content = input.required<Container603010Content>();
  readonly fullWidth = input.required<boolean>();

  readonly #content$ = toObservable(this.content);

  readonly sharedContainerModel: Signal<Container603010Model | null> = toSignal(
    this.#content$.pipe(switchMap(this.#mapContentContainerToShared.bind(this))),
    { initialValue: null },
  );

  #mapContentContainerToShared(containerContent: Container603010Content): Observable<Container603010Model> {
    const allProductNumbers = containerContent.components.flatMap((component: Component603010ContentModel) => [
      component.content.primaryColorProductNumberEmg,
      component.content.secondaryColorProductNumberEmg,
      component.content.accentColorProductNumberEmg,
    ]);

    const uniqueColorsEMGs = Array.from(new Set(allProductNumbers));

    return this.#colorInfoService.getColorInfo(uniqueColorsEMGs).pipe(
      map((containerColors) => ({
        headline: containerContent.headline,
        subheadline: containerContent.subheadline,
        description: containerContent.description,
        textAlignment: containerContent.textAlignment,
        components: containerContent.components.map((component) =>
          this.#mapContentComponentToShared(component.content, containerColors),
        ),
      })),
    );
  }

  #mapContentComponentToShared(component: Component603010Content, colors: ColorInfoValid[]): Component603010Model {
    const componentColors = this.#colorUtils.matchColorsToProductNumbers(colors, [
      component.primaryColorProductNumberEmg,
      component.secondaryColorProductNumberEmg,
      component.accentColorProductNumberEmg,
    ]);

    return {
      headline: component.headline,
      primaryColor: componentColors[0],
      secondaryColor: componentColors[1],
      accentColor: componentColors[2],
    };
  }
}
