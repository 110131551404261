import { DOCUMENT, Location } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { MetaDefinition } from '@angular/platform-browser';

import { CurrentSiteState } from '@ppg/configuration';
import { PAGE_LEVEL_META } from '@ppg/core/constants';
import { ClientSiteSettingsKeys, PageType, MixedContentPageType } from '@ppg/core/enums';
import { PageLevelMeta, LinkDefinition } from '@ppg/core/models';

@Injectable({
  providedIn: 'root',
})
export class PageLevelMetaService {
  readonly #currentSiteState: CurrentSiteState = inject(CurrentSiteState);
  readonly #document: Document = inject(DOCUMENT);
  readonly #location: Location = inject(Location);

  public getMetaTags(tags: PageLevelMeta, pageType: PageType | MixedContentPageType): MetaDefinition[] {
    return this.#transformMetaTags(tags, pageType);
  }

  public getLinkTags(tags: PageLevelMeta): LinkDefinition[] {
    const linkTags: LinkDefinition[] = [];

    linkTags.push({
      rel: PAGE_LEVEL_META.rel.canonical,
      href: tags.canonicalUrl ? tags.canonicalUrl : this.#document.location.pathname,
    });

    return linkTags;
  }

  #transformMetaTags(tags: PageLevelMeta, pageType: PageType | MixedContentPageType): MetaDefinition[] {
    const pageMetaDefinitions: MetaDefinition[] = [];

    pageMetaDefinitions.push({ name: PAGE_LEVEL_META.name.description, content: tags.description ?? '' });

    if (tags.indexSeo === false) {
      pageMetaDefinitions.push({ name: PAGE_LEVEL_META.name.robots, content: PAGE_LEVEL_META.content.indexSeoFalse });
    }

    if (pageType !== PageType.PAGE_ARTICLE_LIST) {
      pageMetaDefinitions.push({ name: PAGE_LEVEL_META.name.keywords, content: tags.keywords ?? '' });
    }

    if (pageType !== PageType.PAGE_PRODUCT_LINE && pageType !== PageType.PAGE_ARTICLE_LIST) {
      pageMetaDefinitions.push({
        property: PAGE_LEVEL_META.property.og_url,
        content: this.#document.location.host + this.#location.path(),
      });
      pageMetaDefinitions.push({ property: PAGE_LEVEL_META.property.og_type, content: tags.pageType ?? '' });
      pageMetaDefinitions.push({ property: PAGE_LEVEL_META.property.og_title, content: tags.title ?? '' });
      pageMetaDefinitions.push({ property: PAGE_LEVEL_META.property.og_description, content: tags.description ?? '' });
      pageMetaDefinitions.push({ property: PAGE_LEVEL_META.property.og_image, content: tags.searchImage ?? '' });

      pageMetaDefinitions.push({
        name: PAGE_LEVEL_META.name.twitter_card,
        content: PAGE_LEVEL_META.content.twitter_card,
      });
      pageMetaDefinitions.push({
        name: PAGE_LEVEL_META.name.twitter_site,
        content: this.#currentSiteState.getSiteSettingValueByKey(ClientSiteSettingsKeys.TwitterCard),
      });

      pageMetaDefinitions.push({ property: PAGE_LEVEL_META.property.twitter_title, content: tags.title ?? '' });
      pageMetaDefinitions.push({ name: PAGE_LEVEL_META.name.twitter_description, content: tags.description ?? '' });
      pageMetaDefinitions.push({ name: PAGE_LEVEL_META.name.twitter_image, content: tags.searchImage ?? '' });
    }

    if (pageType === PageType.PAGE_HUB) {
      pageMetaDefinitions.push({
        property: PAGE_LEVEL_META.property.og_image_width,
        content: PAGE_LEVEL_META.content.og_image_width,
      });
      pageMetaDefinitions.push({
        property: PAGE_LEVEL_META.property.og_image_height,
        content: PAGE_LEVEL_META.content.og_image_height,
      });
    }

    return pageMetaDefinitions;
  }
}
