import { AsyncPipe, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { SkeletonModule } from 'primeng/skeleton';
import { catchError, map, of, scan, startWith, switchMap } from 'rxjs';

import { StaticContentService } from '@ppg/core/content';
import { ClientSiteSettingsKeys } from '@ppg/core/enums';
import { ColorInfoInvalid, ColorInfoValid, ColorInfoResState } from '@ppg/core/models';
import { ColorInfoService } from '@ppg/shared/color/services';

import { ColorUsedComponent } from '../color-used/color-used.component';

const MAX_COLORS_USED = 6;

@Component({
  selector: 'ppg-colors-used-list',
  standalone: true,
  imports: [AsyncPipe, ColorUsedComponent, SkeletonModule, NgOptimizedImage],
  templateUrl: './colors-used-list.component.html',
  styleUrl: './colors-used-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColorsUsedListComponent {
  readonly productNumbers = input.required<string[], string[]>({
    transform: (original) => original.slice(0, MAX_COLORS_USED),
  });

  private readonly colorInfoService = inject(ColorInfoService);
  private readonly staticContentService = inject(StaticContentService);

  readonly colorInfoRes$ = toObservable(this.productNumbers).pipe(
    switchMap((productNumbers) =>
      this.colorInfoService.getColorInfo(productNumbers).pipe(
        // fill missing colors and set required order
        map((colorInfos: (ColorInfoValid | ColorInfoInvalid)[]) =>
          productNumbers.map(
            (productNumber) =>
              colorInfos.find((c) => c.productNumber === productNumber) ||
              new ColorInfoInvalid(productNumber, false, undefined),
          ),
        ),
        map(
          (data: (ColorInfoValid | ColorInfoInvalid)[]) =>
            ({ data, loading: false, error: !data.some(({ isValid }) => isValid) }) as unknown as ColorInfoResState,
        ),
      ),
    ),
    startWith({ error: false, loading: true } as ColorInfoResState),
    catchError(() => of<ColorInfoResState>({ error: true, loading: false })),
    scan<ColorInfoResState>((state, change) => ({ ...state, ...change })),
  );

  readonly colorsUnavailableAsset = this.staticContentService.getStaticAsset(
    ClientSiteSettingsKeys.AssetsColorsUnavailable,
  )!;
}
