<div class="w-full flex justify-content-center">
  <div class="page-section page-section-x-padded">
    @if (content().headline; as headline) {
      <h2 class="headline" [class.text-center]="centeredText()">{{ headline }}</h2>
    }

    @if (content().description; as description) {
      <h5 class="description" [class.text-center]="centeredText()">{{ description }}</h5>
    }

    <section [ngClass]="['template', layout()]">
      @for (card of cards(); let i = $index; track i) {
        <ppg-component-image-gallery
          class="card"
          [content]="card"
          [style.aspectRatio]="template()[i]"></ppg-component-image-gallery>
      }
    </section>
  </div>
</div>
