@if (!inProgress()) {
  @if (staticContent()?.[status()]; as content) {
    <div class="flex flex-column gap-2">
      <p-message
        styleClass="max-w-max"
        [severity]="content?.severity"
        [text]="content?.text"
        [attr.data-test-id]="'inventoryStatus.' + status()" />
      @if (comment(); as comment) {
        <p class="p-0 m-0 text-xs" data-test-id="inventoryStatus.comment">{{ comment }}</p>
      }
    </div>
  }
} @else {
  <p-skeleton height="4rem" styleClass="mb-2" />
}
