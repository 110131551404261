@if (control() && control().invalid && control().dirty) {
  <div class="flex flex-column justify-content-start align-items-start" data-test-id="controlValidator.error.container">
    @for (errorCode of registeredErrorCodes(); track errorCode) {
      @if (control().hasError(errorCode) && errorMessageDictionary()[errorCode]) {
        <span class="p-error flex gap-2 align-items-center text-base" data-test-id="controlValidator.error.message">
          @if (errorMessageDictionary()[errorCode].contentType === 'html') {
            <div
              class="flex align-items-center"
              [innerHTML]="errorMessageDictionary()[errorCode].content"
              data-test-id="controlValidator.error.message.html.content"></div>
          } @else {
            <i class="pi pi-exclamation-circle p-error text-base"></i>
            {{ buildValidationMessage(errorMessageDictionary()[errorCode]) }}
          }
        </span>
      }
    }
  </div>
}
