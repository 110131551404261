<p-card [title]="content().headline" class="content-banner" styleClass="noBorder">
  <div
    data-test-id="contentBlock.container"
    class="inline-flex horizontal-card w-full"
    [class]="isImagePositionRight() ? 'flex-column lg:flex-row' : 'flex-column-reverse lg:flex-row'"
    [ngClass]="backgroundClass()">
    @if (hasImage() && !isImagePositionRight()) {
      <div
        data-test-id="contentBlock.image"
        class="inline-flex flex-column justify-content-center align-items-center w-full lg:w-6 animation-duration-2000 animation-ease-in-out"
        pAnimateOnScroll
        enterClass="fadeinright"
        once="false">
        @if (content().imageWithColorsUsed; as imageWithColorsUsed) {
          <ppg-image-with-colors-used class="flex align-items-center w-full" [content]="imageWithColorsUsed.content" />
        } @else if (content().image) {
          <ppg-image
            [url]="imgUrl()"
            [height]="imgHeight()"
            [width]="imgWidth()"
            [description]="imgDescription()"
            class="flex align-items-center w-full" />
        }
      </div>
    }

    <div
      data-test-id="contentBlock.description"
      pAnimateOnScroll
      [enterClass]="isImagePositionRight() ? 'fadeinleft' : 'fadeinright'"
      once="false"
      class="horizontal-card-description flex flex-column align-items-start justify-content-center w-full lg:w-6 animation-duration-2000 animation-ease-in-out">
      @if (content().showLogo) {
        <div>
          <a class="container flex-grow-1">
            <img
              class="logo"
              [ngSrc]="logoUrl() || brandingAssetsHeaderLogo()"
              alt="Brand Logo"
              width="58"
              height="46" />
          </a>
        </div>
      }
      @if (content().headline) {
        <h2 id="headline" class="light-font-color" [ngClass]="textColorClass()">
          {{ content().headline }}
        </h2>
      }
      @if (content().subheading) {
        <h3 id="subheading" class="light-font-color" [ngClass]="textColorClass()">
          {{ content().subheading }}
        </h3>
      }

      <div class="light-font-color" [ngClass]="textColorClass()">
        @for (contentItem of content().content; track $index) {
          <ppg-rich-text [content]="contentItem.content"></ppg-rich-text>
        }
      </div>
      @if (content().cta) {
        <div class="cta-container">
          <p-button
            class="cta-content-button"
            [label]="content().cta?.content?.text"
            iconPos="right"
            [outlined]="false"
            [icon]="isTargetBlank() ? 'pi pi-arrow-up-right' : ''"
            (onClick)="onCtaClicked()"></p-button>
        </div>
      }
    </div>

    @if (hasImage() && isImagePositionRight()) {
      <div
        data-test-id="contentBlock.image"
        class="inline-flex flex-column justify-content-center align-items-center w-full lg:w-6 animation-duration-2000 animation-ease-in-out"
        pAnimateOnScroll
        enterClass="fadeinleft"
        once="false">
        @if (content().imageWithColorsUsed; as imageWithColorsUsed) {
          <ppg-image-with-colors-used class="flex align-items-center w-full" [content]="imageWithColorsUsed.content" />
        } @else if (content().image) {
          <ppg-image
            [url]="imgUrl()"
            [height]="imgHeight()"
            [width]="imgWidth()"
            [description]="imgDescription()"
            class="flex align-items-center w-full" />
        }
      </div>
    }
  </div>
</p-card>
