import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { ImageModule } from 'primeng/image';

import { ImageLoading } from '@ppg/core/enums';

export type ImageSize = number | 'auto' | 'inherit';

@Component({
  selector: 'ppg-image',
  standalone: true,
  imports: [ImageModule],
  templateUrl: './image.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageComponent {
  readonly url = input.required<string>();
  readonly description = input<string>();
  readonly width = input.required<ImageSize>();
  readonly height = input.required<ImageSize>();
  readonly imageStyle = input({});
  readonly loading = input<ImageLoading>(ImageLoading.LAZY);
  readonly isResponsive = input(true);
}
