<div class="flex flex-column gap-5">
  @if (shouldShowTexts()) {
    <div
      class="flex flex-column gap-3 texts-container"
      [class.align-items-center]="areTextsCentered()"
      [class.text-center]="areTextsCentered()">
      @if (model().headline) {
        <h2 class="m-0">{{ model().headline }}</h2>
      }

      @if (model().subheadline) {
        <h3 class="m-0">{{ model().subheadline }}</h3>
      }

      @if (model().description) {
        <h5 class="m-0">{{ model().description }}</h5>
      }
    </div>
  }

  <div class="flex align-self-center colors-grid-container w-full">
    @for (component of model().components; track component; let i = $index) {
      <ppg-component-603010 class="w-full" [model]="model().components[i]"></ppg-component-603010>
    }
  </div>
</div>
