import { Color } from './color.model';

export class ColorInfoInvalid implements Color {
  readonly isValid = false as const;
  readonly colorName: string = '';
  readonly productNumber: string;
  readonly isFavorite: boolean;
  readonly addedAsFavoriteAt?: Date;

  constructor(productNumber: string, isFavorite: boolean, addedToFavoritesAt: Date | undefined) {
    this.productNumber = productNumber;
    this.isFavorite = isFavorite;
    this.addedAsFavoriteAt = addedToFavoritesAt;
  }
}
