import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, ElementRef, inject, input } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ImageModule } from 'primeng/image';

import { ResourceItem } from '@ppg/core/enums';
import { ComponentStepsContent } from '@ppg/core/models';
import { ResourceService } from '@ppg/shared/resource';
import { RichTextComponent } from '@ppg/shared/rich-text';
import { UrlCtaService } from '@ppg/shared/utils';

import { ImageComponent } from '../image/image.component';

@Component({
  selector: 'ppg-steps',
  standalone: true,
  imports: [CommonModule, CardModule, ButtonModule, RichTextComponent, ImageModule, ImageComponent],
  templateUrl: './steps.component.html',
  styleUrl: './steps.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepsComponent {
  readonly content = input.required<ComponentStepsContent>();

  readonly #elementRef = inject(ElementRef);

  readonly #urlCtaService: UrlCtaService = inject(UrlCtaService);
  readonly #resourceService = inject(ResourceService);

  readonly imageUrl = computed(() => this.content().image?.url ?? '');
  readonly imageHeight = computed(() => this.content().image?.height ?? 0);
  readonly imageWidth = computed(() => this.content().image?.width ?? 0);
  readonly imageDescription = computed(() => this.content().image?.description ?? '');

  readonly isLinkTargetBlank = computed(() => this.#urlCtaService.isTargetBlank(this.content()?.cta));

  readonly stepNumberLabel = computed(() => {
    const stepResource = this.#resourceService.getResourceByKey(ResourceItem.COMPONENT_STEPS_STEP);
    const stepNumber = this.stepNumber();

    return stepResource ? `${stepResource} ${stepNumber}:` : `${stepNumber}:`;
  });

  readonly stepNumber = computed(() => {
    let currentStepIndex = 0;
    const parentContainer = this.#elementRef.nativeElement.parentElement.parentElement as HTMLElement;
    const stepsElements = parentContainer.querySelectorAll('ppg-steps');

    stepsElements.forEach((element, index) => {
      const subElem = element.querySelector('p-card');
      if (subElem?.getAttribute('title') === this.content().stepHeadline) {
        currentStepIndex = index;
      }
    });

    return currentStepIndex + 1;
  });

  navigateTo(): void {
    this.#urlCtaService.navigate(this.content()?.cta);
  }
}
