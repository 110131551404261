import { ImageGalleryLayout } from '@ppg/core/enums';
import { Template } from '@ppg/core/models';

export const TEMPLATE_MAPPING: Record<ImageGalleryLayout, Template> = {
  [ImageGalleryLayout.TWO_IMAGES_1]: ['2 / 3', '2 / 3'],
  [ImageGalleryLayout.TWO_IMAGES_2]: ['4 / 3', '4 / 3'],
  [ImageGalleryLayout.TWO_IMAGES_3]: ['4 / 3', '2 / 3'],
  [ImageGalleryLayout.THREE_IMAGES_1]: ['4 / 3', '4 / 3', '2 / 3'],
  [ImageGalleryLayout.THREE_IMAGES_2]: ['2 / 3', '4 / 3', '4 / 3'],
  [ImageGalleryLayout.THREE_IMAGES_3]: ['2 / 3', '2 / 3', '2 / 3'],
  [ImageGalleryLayout.FOUR_IMAGES_1]: ['4 / 3', '2 / 3', '2 / 3', '4 / 3'],
  [ImageGalleryLayout.FOUR_IMAGES_2]: ['2 / 3', '4 / 3', '4 / 3', '2 / 3'],
  [ImageGalleryLayout.FOUR_IMAGES_3]: ['4 / 3', '2 / 3', '2 / 3', '4 / 3'],
  [ImageGalleryLayout.PUZZLE]: ['4 / 3', '4 / 3', '2 / 3', '4 / 3', '2 / 3', '4 / 3', '4 / 3'],
};

export const CARDS_NUMBER_MAPPING: Record<ImageGalleryLayout, number> = {
  [ImageGalleryLayout.TWO_IMAGES_1]: 2,
  [ImageGalleryLayout.TWO_IMAGES_2]: 2,
  [ImageGalleryLayout.TWO_IMAGES_3]: 2,
  [ImageGalleryLayout.THREE_IMAGES_1]: 3,
  [ImageGalleryLayout.THREE_IMAGES_2]: 3,
  [ImageGalleryLayout.THREE_IMAGES_3]: 3,
  [ImageGalleryLayout.FOUR_IMAGES_1]: 4,
  [ImageGalleryLayout.FOUR_IMAGES_2]: 4,
  [ImageGalleryLayout.FOUR_IMAGES_3]: 4,
  [ImageGalleryLayout.PUZZLE]: -7, // minus 7 because the number of cards is dynamic but limited to 7
};
