import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'ppg-tiktok',
  standalone: true,
  template: `<svg
    tabindex="0"
    class="icon"
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none">
    <rect width="40" height="40" rx="6" fill="var(--icon-background, white)" />
    <rect x="0.5" y="0.5" width="39" height="39" rx="5.5" stroke="var(--icon-border, white)" />

    <path
      d="M28.6779 12.3697C28.4979 12.2767 28.3227 12.1747 28.1529 12.0642C27.6592 11.7378 27.2065 11.3532 26.8047 10.9187C25.7992 9.76818 25.4236 8.60102 25.2853 7.78384H25.2908C25.1753 7.10554 25.2231 6.66667 25.2303 6.66667H20.6505V24.3758C20.6505 24.6136 20.6505 24.8485 20.6405 25.0808C20.6405 25.1096 20.6377 25.1363 20.6361 25.1674C20.6361 25.1802 20.6361 25.1935 20.6333 25.2069V25.2169C20.585 25.8523 20.3813 26.4661 20.0402 27.0043C19.699 27.5425 19.2308 27.9886 18.6767 28.3034C18.0993 28.6319 17.4462 28.8042 16.7818 28.8033C14.648 28.8033 12.9187 27.0634 12.9187 24.9147C12.9187 22.7659 14.648 21.026 16.7818 21.026C17.1857 21.0256 17.5872 21.0891 17.9712 21.2143L17.9768 16.5512C16.8109 16.4006 15.6265 16.4932 14.4983 16.8233C13.37 17.1534 12.3224 17.7137 11.4215 18.4689C10.6322 19.1547 9.96854 19.9731 9.46053 20.8871C9.2672 21.2204 8.53779 22.5598 8.44947 24.7336C8.39391 25.9674 8.76445 27.2456 8.94111 27.774V27.7851C9.05221 28.0962 9.48275 29.1578 10.1844 30.0527C10.7501 30.7706 11.4186 31.4012 12.1682 31.9243V31.9132L12.1793 31.9243C14.3964 33.4309 16.8546 33.332 16.8546 33.332C17.2801 33.3148 18.7056 33.332 20.3244 32.5648C22.1199 31.7143 23.1421 30.4472 23.1421 30.4472C23.7951 29.69 24.3143 28.8272 24.6775 27.8956C25.092 26.8062 25.2303 25.4996 25.2303 24.9774V15.5823C25.2858 15.6157 26.0258 16.1051 26.0258 16.1051C26.0258 16.1051 27.0919 16.7884 28.7551 17.2334C29.9484 17.55 31.5561 17.6167 31.5561 17.6167V13.0702C30.9928 13.1313 29.849 12.9536 28.6779 12.3697Z"
      fill="var(--icon-color, black)" />
  </svg>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TiktokComponent {}
