"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QNumericEnumCollectionPath = void 0;
const PrimitveCollections_1 = require("../../primitve-collection/PrimitveCollections");
const QCollectionPath_1 = require("../QCollectionPath");
class QNumericEnumCollectionPath extends QCollectionPath_1.QCollectionPath {
  constructor(path, theEnum) {
    // @ts-ignore
    super(path, () => {});
    this.theEnum = theEnum;
    if (!theEnum) {
      throw new Error("QNumericEnumCollectionPath: Enum must be supplied!");
    }
  }
  getEntity(withPrefix = false) {
    return new PrimitveCollections_1.QNumericEnumCollection(this.theEnum, withPrefix ? this.path : undefined);
  }
}
exports.QNumericEnumCollectionPath = QNumericEnumCollectionPath;
