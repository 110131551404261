"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QDateTimeOffsetV2Param = exports.DATE_TIME_OFFSET_V2_TYPE_PREFIX = void 0;
const QParam_1 = require("../QParam");
const UrlParamHelper_1 = require("../UrlParamHelper");
exports.DATE_TIME_OFFSET_V2_TYPE_PREFIX = "datetimeoffset";
const getUrlConformValue = value => {
  return (0, UrlParamHelper_1.formatParamWithTypePrefix)(exports.DATE_TIME_OFFSET_V2_TYPE_PREFIX, value);
};
const parseValueFromUrl = urlConformValue => {
  return (0, UrlParamHelper_1.parseWithTypePrefix)(exports.DATE_TIME_OFFSET_V2_TYPE_PREFIX, urlConformValue);
};
class QDateTimeOffsetV2Param extends QParam_1.QParam {
  constructor() {
    super(...arguments);
    this.getUrlConformValue = getUrlConformValue;
    this.parseValueFromUrl = parseValueFromUrl;
  }
}
exports.QDateTimeOffsetV2Param = QDateTimeOffsetV2Param;
