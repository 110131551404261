"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QDoubleV2Param = void 0;
const QStringNumberV2Param_1 = require("./QStringNumberV2Param");
class QDoubleV2Param extends QStringNumberV2Param_1.QStringNumberV2Param {
  getTypeSuffix() {
    return "d";
  }
}
exports.QDoubleV2Param = QDoubleV2Param;
