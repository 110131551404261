import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ImageComponent } from '../image/image.component';

@Component({
  selector: 'ppg-fallback-image',
  standalone: true,
  imports: [ImageComponent],
  templateUrl: './fallback-image.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FallbackImageComponent {
  url = '/assets/img/fallback.png';
  width = 285;
  height = 190;
}
