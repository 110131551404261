import { CommonModule } from '@angular/common';
import { Component, computed, inject, input, output } from '@angular/core';
import { Button } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ImageModule } from 'primeng/image';

import { ComponentContentBanner, CTA } from '@ppg/core/models';
import { UrlCtaService } from '@ppg/shared/utils';

@Component({
  selector: 'ppg-color-warranty',
  standalone: true,
  imports: [CommonModule, ImageModule, Button, CardModule],
  styleUrls: ['./color-warranty.component.scss'],
  templateUrl: './color-warranty.component.html',
})
export class ColorWarrantyComponent {
  readonly #urlCtaService: UrlCtaService = inject(UrlCtaService);

  readonly warranty = input<ComponentContentBanner>();
  readonly image = computed(() => this.warranty()?.content?.image);
  readonly cta = computed(() => this.warranty()?.content?.cta);
  readonly ctaContent = computed(() => this.cta()?.content);

  ctaButtonClicked = output();

  navigateTo(cta: CTA | undefined): void {
    this.ctaButtonClicked.emit();
    this.#urlCtaService.navigate(cta);
  }
}
