"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QNumericEnumPath = void 0;
const NumericEnumConverter_1 = require("../../enum/NumericEnumConverter");
const UrlParamHelper_1 = require("../../param/UrlParamHelper");
const BaseEnumPath_1 = require("./BaseEnumPath");
class QNumericEnumPath extends BaseEnumPath_1.BaseEnumPath {
  constructor(path, theEnum) {
    super(path);
    this.theEnum = theEnum;
    if (!theEnum) {
      throw new Error("QNumericEnumPath: Enum must be supplied!");
    }
    this.converter = new NumericEnumConverter_1.NumericEnumConverter(theEnum);
  }
  /**
   * We require the string value of the enum for any OData operation
   */
  mapValue(value) {
    return (0, UrlParamHelper_1.formatWithQuotes)(this.converter.convertTo(value));
  }
}
exports.QNumericEnumPath = QNumericEnumPath;
