"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QTimeOfDayParam = void 0;
const QParam_1 = require("../QParam");
const UrlParamHelper_1 = require("../UrlParamHelper");
class QTimeOfDayParam extends QParam_1.QParam {
  constructor() {
    super(...arguments);
    this.getUrlConformValue = UrlParamHelper_1.formatLiteralParam;
    this.parseValueFromUrl = UrlParamHelper_1.parseLiteral;
  }
}
exports.QTimeOfDayParam = QTimeOfDayParam;
