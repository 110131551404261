"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.identityFormatter = void 0;
function identityFormatter(value) {
  return value;
}
exports.identityFormatter = identityFormatter;
