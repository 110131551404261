import { Component, computed, input } from '@angular/core';

import { ContainerHighImagesStripContent } from '@ppg/core/models';

import { HighImageStripComponent } from './high-image-strip-component/high-image-strip.component';

@Component({
  selector: 'ppg-container-high-images-strip',
  standalone: true,
  imports: [HighImageStripComponent],
  templateUrl: './container-high-images-strip.component.html',
  styleUrls: ['./container-high-images-strip.component.scss'],
})
export class ContainerHighImagesStripComponent {
  readonly content = input.required<ContainerHighImagesStripContent>();

  readonly cards = computed(() => this.content().cards.filter((item) => item) ?? []);
  readonly pageSectionBackground = computed(() => this.content().backgroundColorRGB ?? '');

  readonly largeCards = computed(() => !this.content().cards.length || this.content().cards.length <= 2);
  readonly mediumCards = computed(() => this.content().cards.length === 3);
  readonly smallCards = computed(() => this.content().cards.length === 4);
}
