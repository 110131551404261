import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  input,
  output,
  signal,
  TemplateRef,
  untracked,
  viewChild,
} from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { MenuModule } from 'primeng/menu';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SidebarModule } from 'primeng/sidebar';
import { filter, map, switchMap } from 'rxjs';

import { Breakpoints } from '@ppg/core/enums';
import {
  ExpansionChangedEvent,
  FilterGroup,
  FilterPopoverBreakpoints,
  FilterPopoverResources,
  FilterSelectionChangedEvent,
} from '@ppg/core/models';

import { MenuBuilder, MenuItem } from './filter-menu.builder';

@Component({
  selector: 'ppg-filter-popover',
  standalone: true,
  imports: [
    ButtonModule,
    OverlayPanelModule,
    SidebarModule,
    MenuModule,
    CheckboxModule,
    BadgeModule,
    NgTemplateOutlet,
    FormsModule,
    NgClass,
    ChipModule,
    RadioButtonModule,
  ],
  templateUrl: './filter-popover.component.html',
  styleUrl: './filter-popover.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterPopoverComponent<G = string, F = string> {
  readonly resources = input.required<FilterPopoverResources>();
  readonly filterGroups = input.required<FilterGroup<G, F>[]>();
  readonly selectedFilters = input<string[]>([]);
  readonly seeMoreCap = input<number | null>(null);
  readonly withPopoverClose = input(true, { transform: booleanAttribute });
  readonly showAppliedFiltersIndicator = input(false, { transform: booleanAttribute });
  readonly type = input<'checkbox' | 'radio' | 'link'>('checkbox');
  readonly breakpoints = input<FilterPopoverBreakpoints>({
    overlayPanel: Breakpoints.Tablet,
    sidebarPanel: Breakpoints.Mobile,
  });
  readonly minFiltersCount = input<number>(0);

  readonly selectionChanged = output<FilterSelectionChangedEvent<G, F>>();
  readonly toggleExpansion = output<ExpansionChangedEvent<G>>();
  readonly clearSelection = output();

  protected readonly isCloseVisible = computed(() => !this.isOverlayPanel() || this.withPopoverClose());
  protected readonly isHeaderVisible = computed(() => !!this.resources().title || this.isCloseVisible());
  protected readonly isFooterVisible = computed(() => !!this.resources().clear);

  protected readonly menuItems = computed<MenuItem[]>(() => {
    const builder = new MenuBuilder();

    if (this.isHeaderVisible()) {
      builder.addSeparator();
    }

    this.filterGroups().forEach((fg) => {
      if (fg.filters.length > this.minFiltersCount()) {
        builder.addFilterGroup(fg, (builder) => {
          const { value, filters, isExpanded } = fg;
          const shouldCap = this.seeMoreCap() !== null && filters.length > this.seeMoreCap()!;
          const showAllFilters = isExpanded || !shouldCap;
          const filtersToShow = showAllFilters ? filters : filters.slice(0, this.seeMoreCap()!);

          filtersToShow.forEach((f) => {
            builder.addFilter(f);
          });

          if (!showAllFilters) {
            builder.addSeeCapToggler(value, this.resources().seeMore!);
          } else if (shouldCap) {
            builder.addSeeCapToggler(value, this.resources().seeLess!);
          }
        });
      }
    });

    if (this.isFooterVisible()) {
      builder.addSeparator();
    }

    return builder.build();
  });

  private readonly breakpointObserver = inject(BreakpointObserver);

  private readonly overlayPanel = viewChild(OverlayPanel);
  private readonly sidebarTemplate = viewChild.required<TemplateRef<unknown>>('sidebar');
  private readonly overlayPanelTemplate = viewChild.required<TemplateRef<unknown>>('overlayPanel');

  private readonly displayMap = computed(() => {
    const { overlayPanel, sidebarPanel } = this.breakpoints();
    return {
      [overlayPanel]: this.overlayPanelTemplate,
      [sidebarPanel]: this.sidebarTemplate,
    } as const;
  });

  private readonly queryMatch = toSignal(
    toObservable(this.displayMap).pipe(
      switchMap((displayMap) =>
        this.breakpointObserver.observe(Object.keys(displayMap)).pipe(
          filter(({ matches }: BreakpointState) => matches),
          map(({ breakpoints }: BreakpointState) => Object.entries(breakpoints).find(([_query, match]) => match)![0]),
        ),
      ),
    ),
    { initialValue: '' },
  );

  protected readonly isOverlayPanel = computed(() => this.queryMatch() === this.breakpoints().overlayPanel);
  protected readonly template = computed(() => this.displayMap()[this.queryMatch()]?.());

  readonly open = signal<boolean>(false);

  constructor() {
    effect(() => {
      if (!this.open()) {
        untracked(() => this.filterGroups().forEach(({ value }) => this.toggleSeeMore(value, false)));
      }
    });
  }

  protected toggleOpen(event: MouseEvent): void {
    this.overlayPanel()?.toggle(event);

    this.open.update((value) => !value);
  }

  protected selectItem(item: MenuItem, checked: boolean): void {
    const group = this.menuItems().find((m) => m.items?.includes(item))!;
    const filter = group.items!.find((i) => i === item)!;

    this.selectionChanged.emit({ group: group.value! as G, filter: filter.value! as F, checked });
  }

  protected toggleSeeMore(group: G, expanded?: boolean): void {
    this.toggleExpansion.emit({ group, expanded });
  }
}
