export interface ImageComponentModel {
  url: string;
  description?: string;
  width: number;
  height: number;
  imagePosition?: ImagePosition;
}

export enum ImagePosition {
  Right = 'right',
  Left = 'left',
}
