"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QComplexParam = void 0;
class QComplexParam {
  constructor(name, qObject, mappedName) {
    this.name = name;
    this.qObject = qObject;
    this.mappedName = mappedName;
    if (!(name === null || name === void 0 ? void 0 : name.trim())) {
      throw new Error("QComplexParam: Name is required!");
    }
    if (!qObject) {
      throw new Error("QComplexParam: QueryObject is required!");
    }
  }
  getName() {
    return this.name;
  }
  getMappedName() {
    var _a;
    return (_a = this.mappedName) !== null && _a !== void 0 ? _a : this.getName();
  }
  convertFrom(value) {
    const result = this.qObject.convertFromOData(value);
    return Array.isArray(result) ? result : result;
  }
  convertTo(value) {
    return this.qObject.convertToOData(value);
  }
  formatUrlValue(value) {
    const result = Array.isArray(value) ? this.convertTo(value) : this.convertTo(value);
    return JSON.stringify(result);
  }
  parseUrlValue(value) {
    return this.convertFrom(value ? JSON.parse(value) : value);
  }
}
exports.QComplexParam = QComplexParam;
