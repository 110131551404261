<p-sidebar
  [visible]="visible()"
  [position]="position()"
  (onHide)="handleOnHide()"
  [style]="style()"
  [styleClass]="styleClass()">
  @if (header()) {
    <ng-template pTemplate="header">
      <h4 class="my-0">{{ header() }}</h4>
    </ng-template>
    <p-divider class="mt-0" styleClass="mt-0"></p-divider>
  }
  <div class="flex flex-column gap-3 mb-4">
    @for (option of options(); track option.id) {
      <div class="flex">
        <p-radioButton
          [attr.data-test-id]="'sortBySidebar.radioButton.' + option.id"
          [(ngModel)]="selectedValue"
          [value]="option"
          [inputId]="'sort-by-sidebar-btn-' + option.id"
          (onClick)="switchOption(option)" />
        <label
          [attr.data-test-id]="'sortBySidebar.label.' + option.id"
          class="ml-3"
          id="sort-by-label"
          for="{{ option.id }}"
          >{{ option.label }}</label
        >
      </div>
    }
  </div>
</p-sidebar>
