"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QEnumPath = void 0;
const UrlParamHelper_1 = require("../../param/UrlParamHelper");
const BaseEnumPath_1 = require("./BaseEnumPath");
class QEnumPath extends BaseEnumPath_1.BaseEnumPath {
  constructor(path, theEnum) {
    super(path);
    this.theEnum = theEnum;
    if (!theEnum) {
      throw new Error("QEnumPath: Enum must be supplied! ");
    }
  }
  mapValue(value) {
    return (0, UrlParamHelper_1.formatWithQuotes)(value);
  }
}
exports.QEnumPath = QEnumPath;
