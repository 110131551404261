"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.secondFn = exports.minuteFn = exports.hourFn = exports.dayFn = exports.monthFn = exports.yearFn = void 0;
const UrlParamHelper_1 = require("../../param/UrlParamHelper");
const QNumberV2Path_1 = require("./QNumberV2Path");
function buildNumberReturningFunction(func, path) {
  return () => new QNumberV2Path_1.QNumberV2Path((0, UrlParamHelper_1.buildFunctionExpression)(func, path));
}
function yearFn(path) {
  return buildNumberReturningFunction("year" /* DateTimeFilterFunctions.YEAR */, path);
}
exports.yearFn = yearFn;
function monthFn(path) {
  return buildNumberReturningFunction("month" /* DateTimeFilterFunctions.MONTH */, path);
}
exports.monthFn = monthFn;
function dayFn(path) {
  return buildNumberReturningFunction("day" /* DateTimeFilterFunctions.DAY */, path);
}
exports.dayFn = dayFn;
function hourFn(path) {
  return buildNumberReturningFunction("hour" /* DateTimeFilterFunctions.HOUR */, path);
}
exports.hourFn = hourFn;
function minuteFn(path) {
  return buildNumberReturningFunction("minute" /* DateTimeFilterFunctions.MINUTE */, path);
}
exports.minuteFn = minuteFn;
function secondFn(path) {
  return buildNumberReturningFunction("second" /* DateTimeFilterFunctions.SECOND */, path);
}
exports.secondFn = secondFn;
