import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'ppg-linkedin',
  standalone: true,
  template: `<svg
    tabindex="0"
    class="icon"
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none">
    <rect width="40" height="40" rx="6" fill="var(--icon-background, white)" />
    <rect x="0.5" y="0.5" width="39" height="39" rx="5.5" stroke="var(--icon-border, white)" />
    <path
      d="M31.2 6.66667H8.95002C8.66323 6.65323 8.37661 6.69649 8.10655 6.79397C7.8365 6.89144 7.58833 7.04123 7.37624 7.23474C7.16415 7.42825 6.99231 7.6617 6.87056 7.9217C6.7488 8.18171 6.67952 8.46318 6.66669 8.75V31.05C6.68349 31.6503 6.92945 32.2213 7.35407 32.646C7.77869 33.0706 8.34975 33.3165 8.95002 33.3333H31.2C31.7836 33.3073 32.3337 33.0537 32.7325 32.6269C33.1313 32.2001 33.347 31.634 33.3334 31.05V8.75C33.3381 8.47084 33.2858 8.19365 33.1797 7.93542C33.0735 7.6772 32.9157 7.44338 32.7159 7.24831C32.5162 7.05324 32.2787 6.90103 32.018 6.80101C31.7573 6.70099 31.479 6.65528 31.2 6.66667ZM15 28.9H11.1167V17.0167H15V28.9ZM13.15 15.2167C12.879 15.2261 12.609 15.1794 12.3569 15.0795C12.1049 14.9796 11.8762 14.8287 11.6852 14.6362C11.4943 14.4436 11.3452 14.2137 11.2474 13.9608C11.1495 13.708 11.105 13.4376 11.1167 13.1667C11.1047 12.8928 11.1501 12.6195 11.2499 12.3643C11.3498 12.109 11.5019 11.8775 11.6965 11.6844C11.8911 11.4914 12.1239 11.3412 12.3799 11.2434C12.636 11.1456 12.9096 11.1025 13.1834 11.1167C13.4543 11.1072 13.7244 11.1539 13.9764 11.2538C14.2285 11.3537 14.4572 11.5047 14.6481 11.6972C14.8391 11.8897 14.9882 12.1196 15.086 12.3725C15.1839 12.6254 15.2283 12.8958 15.2167 13.1667C15.2287 13.4405 15.1833 13.7138 15.0834 13.9691C14.9836 14.2243 14.8315 14.4559 14.6369 14.6489C14.4423 14.8419 14.2095 14.9921 13.9535 15.0899C13.6974 15.1877 13.4237 15.2309 13.15 15.2167ZM28.9 28.9H25V22.4C25 20.85 24.45 19.7833 23.0667 19.7833C22.6369 19.7871 22.2189 19.9236 21.8698 20.1742C21.5207 20.4249 21.2576 20.7773 21.1167 21.1833C21.0083 21.4877 20.9629 21.8109 20.9834 22.1333V28.9H17.15V17.0167H20.9834V18.6833C21.3242 18.0572 21.8314 17.5375 22.4491 17.1816C23.0667 16.8257 23.7708 16.6475 24.4834 16.6667C27 16.6667 28.9 18.3333 28.9 21.8833V28.9Z"
      fill="var(--icon-color, black)" />
  </svg>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkedinComponent {}
