"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QBigNumberPath = void 0;
const UrlParamHelper_1 = require("../../param/UrlParamHelper");
const QBasePath_1 = require("../base/QBasePath");
class QBigNumberPath extends QBasePath_1.QBasePath {
  constructor() {
    super(...arguments);
    this.add = this.plus;
    this.sub = this.minus;
    this.mul = this.multiply;
    this.div = this.divide;
    this.divBy = this.divideWithFraction;
    this.mod = this.modulo;
  }
  formatValue(value) {
    return value;
  }
  createNewFunctionPath(func) {
    return new QBigNumberPath((0, UrlParamHelper_1.buildFunctionExpression)(func, this.path), this.converter);
  }
  createNewOperationPath(operator, value) {
    const converted = this.convertInput(value);
    return new QBigNumberPath((0, UrlParamHelper_1.buildOperatorExpression)(this.path, operator, converted), this.converter);
  }
  plus(value) {
    return this.createNewOperationPath("add" /* NumberFilterOperators.ADDITION */, value);
  }
  minus(value) {
    return this.createNewOperationPath("sub" /* NumberFilterOperators.SUBTRACTION */, value);
  }
  multiply(value) {
    return this.createNewOperationPath("mul" /* NumberFilterOperators.MULTIPLICATION */, value);
  }
  divide(value) {
    return this.createNewOperationPath("div" /* NumberFilterOperators.DIVISION */, value);
  }
  divideWithFraction(value) {
    return this.createNewOperationPath("divby" /* NumberFilterOperators.DIVISION_WITH_FRACTION */, value);
  }
  modulo(value) {
    return this.createNewOperationPath("mod" /* NumberFilterOperators.MODULO */, value);
  }
  ceiling() {
    return this.createNewFunctionPath("ceiling" /* NumberFilterFunctions.CEILING */);
  }
  floor() {
    return this.createNewFunctionPath("floor" /* NumberFilterFunctions.FLOOR */);
  }
  round() {
    return this.createNewFunctionPath("round" /* NumberFilterFunctions.ROUND */);
  }
}
exports.QBigNumberPath = QBigNumberPath;
