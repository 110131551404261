"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QSearchTerm = exports.searchTerm = void 0;
function searchTerm(term) {
  return term && term instanceof QSearchTerm ? term : new QSearchTerm(term);
}
exports.searchTerm = searchTerm;
class QSearchTerm {
  constructor(term, handlePhrase = true) {
    const cleaned = term === null || term === void 0 ? void 0 : term.trim();
    if (!cleaned) {
      this.term = "";
    } else {
      this.term = handlePhrase && cleaned.indexOf(" ") > -1 ? `"${cleaned}"` : cleaned;
    }
  }
  toString() {
    return this.term;
  }
  not() {
    var _a;
    if ((_a = this.term) === null || _a === void 0 ? void 0 : _a.trim()) {
      return new QSearchTerm(`NOT ${this.term}`, false);
    }
    return this;
  }
  and(term) {
    if (term === null || term === void 0 ? void 0 : term.toString()) {
      if (this.term) {
        return new QSearchTerm(`${this.term} AND ${searchTerm(term).toString()}`, false);
      } else {
        return searchTerm(term);
      }
    }
    return this;
  }
  or(term) {
    if (term === null || term === void 0 ? void 0 : term.toString()) {
      const st = typeof term === "string" ? searchTerm(term) : term;
      if (this.term) {
        return new QSearchTerm(`(${this.term} OR ${st.toString()})`, false);
      } else {
        return searchTerm(term);
      }
    }
    return this;
  }
}
exports.QSearchTerm = QSearchTerm;
