"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QFilterExpression = void 0;
class QFilterExpression {
  constructor(expression) {
    this.expression = expression;
  }
  toString() {
    var _a;
    return ((_a = this.expression) === null || _a === void 0 ? void 0 : _a.trim()) || "";
  }
  not() {
    var _a;
    if ((_a = this.expression) === null || _a === void 0 ? void 0 : _a.trim()) {
      return new QFilterExpression(`not(${this.expression})`);
    }
    return this;
  }
  combine(expression, isOrOperation, withoutParentheses = false) {
    if (expression === null || expression === void 0 ? void 0 : expression.toString()) {
      if (this.expression) {
        const newExpr = `${this.expression} ${isOrOperation ? "or" : "and"} ${expression.toString()}`;
        return new QFilterExpression(isOrOperation && !withoutParentheses ? `(${newExpr})` : newExpr);
      } else {
        return expression;
      }
    }
    return this;
  }
  and(expression) {
    return this.combine(expression, false);
  }
  or(expression, withoutParentheses = false) {
    return this.combine(expression, true, withoutParentheses);
  }
}
exports.QFilterExpression = QFilterExpression;
