import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';

import { TextAlignment } from '@ppg/core/enums';
import { ComponentImageGalleryContent, ContainerImageGalleryContent } from '@ppg/core/models';

import { ComponentImageGalleryComponent } from './component-image-gallery/component-image-gallery.component';
import { CARDS_NUMBER_MAPPING, TEMPLATE_MAPPING } from './container-image-gallery.constants';

@Component({
  selector: 'ppg-container-image-gallery',
  standalone: true,
  imports: [ComponentImageGalleryComponent, NgClass],
  templateUrl: './container-image-gallery.component.html',
  styleUrl: './container-image-gallery.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContainerImageGalleryComponent {
  readonly content = input.required<ContainerImageGalleryContent>();

  readonly layout = computed(() => this.content().layout);
  readonly template = computed(() => TEMPLATE_MAPPING[this.layout()]);
  readonly cards = computed(() => this.adjustCardsNumber(this.content().cards.map((card) => card.content)));
  readonly centeredText = computed(() => this.content().textAlignment === TextAlignment.CENTER);

  /**
   * Adjusts the number of cards to match the specified layout.
   * It either extends the array or trims it as necessary.
   * If required number is negative, cards will not be added, only trimmed if exceeding.
   */
  private adjustCardsNumber(cards: ComponentImageGalleryContent[]): ComponentImageGalleryContent[] {
    const provided = cards.length;
    const required = CARDS_NUMBER_MAPPING[this.layout()] ?? 0;
    const diff = required - provided;

    // {} - image unavailable
    return [...cards, ...Array.from({ length: Math.max(diff, 0) }, () => ({}))].slice(0, Math.abs(required));
  }
}
