import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, signal, WritableSignal } from '@angular/core';
import { ButtonModule } from 'primeng/button';

import { ImageLoading, ResourceItem } from '@ppg/core/enums';
import { ComponentImageWithColorsUsedContent } from '@ppg/core/models';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ResourceItemComponent } from '@ppg/shared/resource';

import { ColorsUsedDialogComponent } from './colors-used-dialog/colors-used-dialog.component';
import { ImageComponent } from '../../components/image/image.component';

@Component({
  selector: 'ppg-image-with-colors-used',
  standalone: true,
  imports: [ImageComponent, ButtonModule, ResourceItemComponent, ColorsUsedDialogComponent, NgTemplateOutlet],
  templateUrl: './image-with-colors-used.component.html',
  styleUrl: './image-with-colors-used.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageWithColorsUsedComponent {
  readonly content = input.required<ComponentImageWithColorsUsedContent>();
  readonly buttonBottomOffset = input(0);

  readonly isResponsive = input(true);
  readonly imageStyle = input({});
  readonly loading = input<ImageLoading>(ImageLoading.LAZY);
  readonly isRoot = input<boolean>(false);

  resourceItem = ResourceItem;

  visible: WritableSignal<boolean> = signal(false);

  showDialog(): void {
    this.visible.set(true);
  }
}
