"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QAction = void 0;
const OperationReturnType_1 = require("./OperationReturnType");
class QAction {
  constructor(name, qReturnType = OperationReturnType_1.emptyOperationReturnType) {
    this.name = name;
    this.qReturnType = qReturnType;
  }
  getName() {
    return this.name;
  }
  buildUrl() {
    return this.name;
  }
  convertUserParams(params) {
    const qParams = this.getParams();
    if (!params || !qParams) {
      return undefined;
    }
    return Object.entries(params).map(([key, value]) => {
      const qParam = qParams.find(q => q.getMappedName() === key);
      if (!qParam) {
        throw new Error(`Unknown parameter "${key}"!`);
      }
      // maps the name and converts the value
      return [qParam.getName(), qParam.convertTo(value)];
    }).filter(p => p[1] !== undefined).reduce((collector, [key, value]) => {
      collector[key] = value;
      return collector;
    }, {});
  }
  convertODataParams(params) {
    const qParams = this.getParams();
    if (!params || !qParams) {
      return undefined;
    }
    return Object.entries(params).map(([key, value]) => {
      const qParam = qParams.find(q => q.getName() === key);
      if (!qParam) {
        throw new Error(`Unknown parameter "${key}"!`);
      }
      // maps the name and converts the value
      return [qParam.getMappedName(), qParam.convertFrom(value)];
    }).filter(p => p[1] !== undefined).reduce((collector, [key, value]) => {
      collector[key] = value;
      return collector;
    }, {});
  }
  convertResponse(response) {
    return this.qReturnType.convertResponse(response);
  }
}
exports.QAction = QAction;
