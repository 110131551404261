import { Option } from './option.model';

export enum SortType {
  ASC = 'asc',
  DESC = 'desc',
}

export interface SortOption extends Option {
  key: string;
  type: SortType;
}
