"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.filterIn = exports.filterInEmulated = exports.filterGreaterEquals = exports.filterGreaterThan = exports.filterLowerEquals = exports.filterLowerThan = exports.filterNotEquals = exports.filterEquals = exports.filterIsNotNull = exports.filterIsNull = exports.orderDescending = exports.orderAscending = void 0;
const UrlParamHelper_1 = require("../../param/UrlParamHelper");
const QFilterExpression_1 = require("../../QFilterExpression");
const QOrderByExpression_1 = require("../../QOrderByExpression");
function orderAscending(path) {
  /**
   * Order by this property in ascending order.
   *
   * @returns orderby expression
   */
  return () => new QOrderByExpression_1.QOrderByExpression(`${path} asc`);
}
exports.orderAscending = orderAscending;
function orderDescending(path) {
  /**
   * Order by this property in descending order.
   *
   * @returns orderby expression
   */
  return () => new QOrderByExpression_1.QOrderByExpression(`${path} desc`);
}
exports.orderDescending = orderDescending;
function filterIsNull(path) {
  /**
   * Base filter function: property must be null.
   */
  return () => new QFilterExpression_1.QFilterExpression(`${path} eq null`);
}
exports.filterIsNull = filterIsNull;
function filterIsNotNull(path) {
  /**
   * Base filter function: property must not be null.
   */
  return () => new QFilterExpression_1.QFilterExpression(`${path} ne null`);
}
exports.filterIsNotNull = filterIsNotNull;
function filterEquals(path, mapValue) {
  /**
   * Base filter function: property must equal the given value.
   */
  return value => {
    const result = value === null ? "null" : mapValue(value);
    return (0, UrlParamHelper_1.buildQFilterOperation)(path, "eq" /* StandardFilterOperators.EQUALS */, result);
  };
}
exports.filterEquals = filterEquals;
function filterNotEquals(path, mapValue) {
  /**
   * Base filter function: property must not equal the given value.
   */
  return value => {
    const result = value === null ? "null" : mapValue(value);
    return (0, UrlParamHelper_1.buildQFilterOperation)(path, "ne" /* StandardFilterOperators.NOT_EQUALS */, result);
  };
}
exports.filterNotEquals = filterNotEquals;
function filterLowerThan(path, mapValue) {
  /**
   * Base filter function: property must be lower than the given value.
   */
  return value => {
    return (0, UrlParamHelper_1.buildQFilterOperation)(path, "lt" /* StandardFilterOperators.LOWER_THAN */, mapValue(value));
  };
}
exports.filterLowerThan = filterLowerThan;
function filterLowerEquals(path, mapValue) {
  /**
   * Base filter function: property must be lower than or equal to the given value.
   */
  return value => {
    return (0, UrlParamHelper_1.buildQFilterOperation)(path, "le" /* StandardFilterOperators.LOWER_EQUALS */, mapValue(value));
  };
}
exports.filterLowerEquals = filterLowerEquals;
function filterGreaterThan(path, mapValue) {
  /**
   * Base filter function: property must be greater than the given value.
   */
  return value => {
    return (0, UrlParamHelper_1.buildQFilterOperation)(path, "gt" /* StandardFilterOperators.GREATER_THAN */, mapValue(value));
  };
}
exports.filterGreaterThan = filterGreaterThan;
function filterGreaterEquals(path, mapValue) {
  /**
   * Base filter function: property must be greater than or equal to the given value.
   */
  return value => {
    return (0, UrlParamHelper_1.buildQFilterOperation)(path, "ge" /* StandardFilterOperators.GREATER_EQUALS */, mapValue(value));
  };
}
exports.filterGreaterEquals = filterGreaterEquals;
function filterInEmulated(path, mapValue) {
  /**
   * Base filter function: property must equal one of the given values.
   *
   * The in-statement is actually emulated by using an or-concatenation of equals-statements.
   */
  return (...values) => {
    return values.reduce((expression, value, currentIndex) => {
      const expr = (0, UrlParamHelper_1.buildQFilterOperation)(path, "eq" /* StandardFilterOperators.EQUALS */, mapValue(value));
      return expression ? expression.or(expr, currentIndex < values.length - 1) : expr;
    }, null);
  };
}
exports.filterInEmulated = filterInEmulated;
function filterIn(path, mapValue) {
  /**
   * Base filter function (V4): property must equal one of the given values.
   *
   * This implementation uses the native in-statement which is available since V4, but not implemented by
   * all V4 OData services.
   */
  return (...values) => {
    return (0, UrlParamHelper_1.buildQFilterOperation)(path, "in" /* StandardFilterOperators.IN */, `(${values.map(mapValue).join(",")})`);
  };
}
exports.filterIn = filterIn;
