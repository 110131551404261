import { computed, inject, Injectable } from '@angular/core';
import { map, shareReplay } from 'rxjs';

import { CurrentSiteState } from '@ppg/configuration';
import { ClientSiteSettingsKeys } from '@ppg/core/enums';
import { ColorFamily, ColorFamilyDataModel, GetColorFamiliesResponseDataModel } from '@ppg/core/models';

import { ColorInfoApiService } from './color-info-api.service';
import { ColorFamilyTypeService } from '../color-family-helper/color-family-type.service';

@Injectable({ providedIn: 'root' })
export class ColorFamilyInfoService {
  private readonly assetNotFoundCode = 'notfound';

  private readonly currentSiteState = inject(CurrentSiteState);
  private readonly colorApiService = inject(ColorInfoApiService);
  private readonly colorFamilyTypeService = inject(ColorFamilyTypeService);
  productApiLanguage = this.colorApiService.productApiLanguage;

  readonly colorFamilyTypes = computed(() => {
    return (
      this.currentSiteState.getSiteSettingValueByKey(ClientSiteSettingsKeys.ColorJourneyFamilyTypeNames)?.split(',') ??
      []
    );
  });

  private readonly colorFamiliesGroupedByType$ = this.colorApiService.getColorFamilies(this.colorFamilyTypes()).pipe(
    map((families) => this.mapToColorFamiliesAndGroupByType(families)),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  getColorFamiliesGroupedByType() {
    return this.colorFamiliesGroupedByType$;
  }

  private mapToColorFamiliesAndGroupByType(
    colorFamilyDataModels: GetColorFamiliesResponseDataModel,
  ): Record<string, ColorFamily[]> {
    const colorImageAttribute = 'colorimage';
    const ambianceAttribute = 'ambiance';

    const colorFamiliesByType: Record<string, ColorFamily[]> = {};

    colorFamilyDataModels.colorFamilyTypes.forEach((colorFamilyType) => {
      const colorFamiliesFilteredByType = colorFamilyDataModels.colorFamilies.filter(
        (colorFamily) => colorFamily.parentId === colorFamilyType.identifier,
      );

      const colorFamilyTypeName =
        colorFamilyType.name.find((name) => name.language === this.productApiLanguage)?.translation || '';
      const colorFamilyTypeLabel = this.colorFamilyTypeService.getFamilyTypeLabel(colorFamilyTypeName);

      const mappedColorFamilies = colorFamiliesFilteredByType.map((colorFamilyDataModels) => {
        const name = colorFamilyDataModels.name.find((name) => name.language === this.productApiLanguage)?.translation;
        const rgbRaw = colorFamilyDataModels.metaTitle?.find(
          (name) => name.language === this.productApiLanguage,
        )?.translation;
        const rgbFormatted = rgbRaw ? this.removeWhitespace(rgbRaw) : undefined;

        const colorFamilyAssets = colorFamilyDataModels.assets?.filter(
          (asset) => this.removeWhitespace(asset.id.toLowerCase()) !== this.assetNotFoundCode,
        );

        const colorImage = colorFamilyAssets?.find(
          (attr) => this.removeWhitespace(attr.type.toLowerCase()) === colorImageAttribute,
        )?.url;
        const descriptionImage = colorFamilyAssets?.find(
          (attr) => this.removeWhitespace(attr.type.toLowerCase()) === ambianceAttribute,
        )?.url;

        return {
          id: colorFamilyDataModels.id,
          name: name,
          typeLabel: colorFamilyTypeLabel,
          structureGroupId: colorFamilyDataModels.identifier,
          imageUrl: colorImage,
          rgb: rgbFormatted,
          description: {
            content: this.getDescription(colorFamilyDataModels),
            imageUrl: descriptionImage,
          },
        } as ColorFamily;
      });

      colorFamiliesByType[colorFamilyTypeLabel] = mappedColorFamilies;
    });

    return colorFamiliesByType;
  }

  private removeWhitespace(input: string) {
    return input ? input.replace(/\s+/g, '') : input;
  }

  private getDescription(colorFamilyDataModel: ColorFamilyDataModel) {
    return colorFamilyDataModel.description
      ?.find((desc) => desc.language === this.productApiLanguage)
      ?.translation.replaceAll('<CRLF>', '<br>');
  }
}
