import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';

import { AssetYouTubeVideoContent } from '@ppg/core/models';
import { RichTextComponent } from '@ppg/shared/rich-text';

import { YouTubeConst } from '../../../lib/common/components.const';
import { SafeUrlPipe } from '../../../lib/common/pipes/safe-youtube-url/safe-url.pipe';

@Component({
  selector: 'ppg-asset-youtube-video',
  standalone: true,
  imports: [RichTextComponent, SafeUrlPipe],
  templateUrl: './asset-youtube-video.component.html',
  styleUrl: './asset-youtube-video.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetYoutubeVideoComponent {
  readonly content = input.required<AssetYouTubeVideoContent>();

  readonly youTubeDescriptionVisible = computed<boolean>(
    () => this.content().description && this.content().description.length > 0,
  );

  readonly getYouTubeUrl = computed(() => {
    return YouTubeConst.BASE_URL + `${this.content().youtubeId}`;
  });
}
