"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QId = void 0;
const QFunction_1 = require("./QFunction");
/**
 * Represents a function to produce the id path of an entity, e.g. MyEntity(number=123,name='Test').
 * There's no difference between V2 and V4 here.
 */
class QId extends QFunction_1.QFunction {
  constructor(name) {
    super(name, undefined, {
      v2Mode: false
    });
  }
}
exports.QId = QId;
