"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QNumericEnumCollection = exports.QEnumCollection = exports.QDateTimeOffsetV2Collection = exports.QDateTimeV2Collection = exports.QTimeV2Collection = exports.QDateCollection = exports.QTimeOfDayCollection = exports.QDateTimeOffsetCollection = exports.QBinaryCollection = exports.QGuidV2Collection = exports.QGuidCollection = exports.QBooleanCollection = exports.QStringNumberV2Collection = exports.QNumberV2Collection = exports.QBigNumberCollection = exports.QNumberCollection = exports.QStringV2Collection = exports.QStringCollection = void 0;
const QEnumPath_1 = require("../path/enum/QEnumPath");
const QNumericEnumPath_1 = require("../path/enum/QNumericEnumPath");
const QBinaryPath_1 = require("../path/QBinaryPath");
const QBooleanPath_1 = require("../path/QBooleanPath");
const QDateTimeOffsetV2Path_1 = require("../path/v2/QDateTimeOffsetV2Path");
const QDateTimeV2Path_1 = require("../path/v2/QDateTimeV2Path");
const QGuidV2Path_1 = require("../path/v2/QGuidV2Path");
const QNumberV2Path_1 = require("../path/v2/QNumberV2Path");
const QStringNumberV2Path_1 = require("../path/v2/QStringNumberV2Path");
const QStringV2Path_1 = require("../path/v2/QStringV2Path");
const QTimeV2Path_1 = require("../path/v2/QTimeV2Path");
const QBigNumberPath_1 = require("../path/v4/QBigNumberPath");
const QDatePath_1 = require("../path/v4/QDatePath");
const QDateTimeOffsetPath_1 = require("../path/v4/QDateTimeOffsetPath");
const QGuidPath_1 = require("../path/v4/QGuidPath");
const QNumberPath_1 = require("../path/v4/QNumberPath");
const QStringPath_1 = require("../path/v4/QStringPath");
const QTimeOfDayPath_1 = require("../path/v4/QTimeOfDayPath");
const QPrimitiveCollection_1 = require("./QPrimitiveCollection");
class QStringCollection extends QPrimitiveCollection_1.QPrimitiveCollection {
  constructor() {
    super(...arguments);
    this.it = new QStringPath_1.QStringPath(this.withPrefix(), this.converter);
  }
}
exports.QStringCollection = QStringCollection;
class QStringV2Collection extends QPrimitiveCollection_1.QPrimitiveCollection {
  constructor() {
    super(...arguments);
    this.it = new QStringV2Path_1.QStringV2Path(this.withPrefix(), this.converter);
  }
}
exports.QStringV2Collection = QStringV2Collection;
class QNumberCollection extends QPrimitiveCollection_1.QPrimitiveCollection {
  constructor() {
    super(...arguments);
    this.it = new QNumberPath_1.QNumberPath(this.withPrefix(), this.converter);
  }
}
exports.QNumberCollection = QNumberCollection;
class QBigNumberCollection extends QPrimitiveCollection_1.QPrimitiveCollection {
  constructor() {
    super(...arguments);
    this.it = new QBigNumberPath_1.QBigNumberPath(this.withPrefix(), this.converter);
  }
}
exports.QBigNumberCollection = QBigNumberCollection;
class QNumberV2Collection extends QPrimitiveCollection_1.QPrimitiveCollection {
  constructor() {
    super(...arguments);
    this.it = new QNumberV2Path_1.QNumberV2Path(this.withPrefix(), this.converter);
  }
}
exports.QNumberV2Collection = QNumberV2Collection;
class QStringNumberV2Collection extends QPrimitiveCollection_1.QPrimitiveCollection {
  constructor() {
    super(...arguments);
    this.it = new QStringNumberV2Path_1.QStringNumberV2Path(this.withPrefix(), this.converter);
  }
}
exports.QStringNumberV2Collection = QStringNumberV2Collection;
class QBooleanCollection extends QPrimitiveCollection_1.QPrimitiveCollection {
  constructor() {
    super(...arguments);
    this.it = new QBooleanPath_1.QBooleanPath(this.withPrefix(), this.converter);
  }
}
exports.QBooleanCollection = QBooleanCollection;
class QGuidCollection extends QPrimitiveCollection_1.QPrimitiveCollection {
  constructor() {
    super(...arguments);
    this.it = new QGuidPath_1.QGuidPath(this.withPrefix(), this.converter);
  }
}
exports.QGuidCollection = QGuidCollection;
class QGuidV2Collection extends QPrimitiveCollection_1.QPrimitiveCollection {
  constructor() {
    super(...arguments);
    this.it = new QGuidV2Path_1.QGuidV2Path(this.withPrefix(), this.converter);
  }
}
exports.QGuidV2Collection = QGuidV2Collection;
class QBinaryCollection extends QPrimitiveCollection_1.QPrimitiveCollection {
  constructor() {
    super(...arguments);
    this.it = new QBinaryPath_1.QBinaryPath(this.withPrefix());
  }
}
exports.QBinaryCollection = QBinaryCollection;
class QDateTimeOffsetCollection extends QPrimitiveCollection_1.QPrimitiveCollection {
  constructor() {
    super(...arguments);
    this.it = new QDateTimeOffsetPath_1.QDateTimeOffsetPath(this.withPrefix(), this.converter);
  }
}
exports.QDateTimeOffsetCollection = QDateTimeOffsetCollection;
class QTimeOfDayCollection extends QPrimitiveCollection_1.QPrimitiveCollection {
  constructor() {
    super(...arguments);
    this.it = new QTimeOfDayPath_1.QTimeOfDayPath(this.withPrefix(), this.converter);
  }
}
exports.QTimeOfDayCollection = QTimeOfDayCollection;
class QDateCollection extends QPrimitiveCollection_1.QPrimitiveCollection {
  constructor() {
    super(...arguments);
    this.it = new QDatePath_1.QDatePath(this.withPrefix(), this.converter);
  }
}
exports.QDateCollection = QDateCollection;
class QTimeV2Collection extends QPrimitiveCollection_1.QPrimitiveCollection {
  constructor() {
    super(...arguments);
    this.it = new QTimeV2Path_1.QTimeV2Path(this.withPrefix(), this.converter);
  }
}
exports.QTimeV2Collection = QTimeV2Collection;
class QDateTimeV2Collection extends QPrimitiveCollection_1.QPrimitiveCollection {
  constructor() {
    super(...arguments);
    this.it = new QDateTimeV2Path_1.QDateTimeV2Path(this.withPrefix(), this.converter);
  }
}
exports.QDateTimeV2Collection = QDateTimeV2Collection;
class QDateTimeOffsetV2Collection extends QPrimitiveCollection_1.QPrimitiveCollection {
  constructor() {
    super(...arguments);
    this.it = new QDateTimeOffsetV2Path_1.QDateTimeOffsetV2Path(this.withPrefix(), this.converter);
  }
}
exports.QDateTimeOffsetV2Collection = QDateTimeOffsetV2Collection;
class QEnumCollection extends QPrimitiveCollection_1.QPrimitiveCollection {
  constructor(theEnum, prefix) {
    super(prefix, undefined);
    this.it = new QEnumPath_1.QEnumPath(this.withPrefix(), theEnum);
  }
}
exports.QEnumCollection = QEnumCollection;
class QNumericEnumCollection extends QPrimitiveCollection_1.QPrimitiveCollection {
  constructor(theEnum, prefix) {
    super(prefix, undefined);
    this.it = new QNumericEnumPath_1.QNumericEnumPath(this.withPrefix(), theEnum);
  }
}
exports.QNumericEnumCollection = QNumericEnumCollection;
