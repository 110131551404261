import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';

import { PAGE_LEVEL_META } from '@ppg/core/constants';
import { LinkDefinition } from '@ppg/core/models';

@Injectable({
  providedIn: 'root',
})
export class LinkTagService {
  readonly #document: Document = inject(DOCUMENT);

  addOrUpdateTags(tags: LinkDefinition[]): void {
    tags.forEach((link) => this.#addOrUpdateTag(link));
  }

  #addOrUpdateTag(tag: LinkDefinition): void {
    if (!tag.href || !tag.rel) {
      return;
    }
    const canonicalLink = this.#document.querySelector(`link[rel="${PAGE_LEVEL_META.rel.canonical}"]`);

    if (canonicalLink && tag.rel === PAGE_LEVEL_META.rel.canonical) {
      canonicalLink.setAttribute('href', tag.href);
      return;
    }
    const linkElement = this.#document.createElement('link');

    Object.entries(tag).forEach(([key, value]) => {
      if (value) {
        linkElement.setAttribute(key, value);
      }
    });

    this.#document.head.appendChild(linkElement);
  }
}
