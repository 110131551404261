import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { AccordionModule } from 'primeng/accordion';

import { FaqContent } from '@ppg/core/models';
import { RichTextComponent } from '@ppg/shared/rich-text';

@Component({
  selector: 'ppg-faq',
  standalone: true,
  imports: [AccordionModule, RichTextComponent],
  templateUrl: './faq.component.html',
  styleUrl: './faq.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FaqComponent {
  readonly content = input.required<FaqContent>();
  readonly pageSectionBackground = computed(() => this.content().backgroundColorRGB ?? '');

  answers = computed(() => this.content().answer ?? []);
}
