"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QEnumParam = void 0;
const BaseEnumParam_1 = require("./BaseEnumParam");
class QEnumParam extends BaseEnumParam_1.BaseEnumParam {
  mapValue(value) {
    return value;
  }
  mapValueBack(value) {
    return value;
  }
}
exports.QEnumParam = QEnumParam;
