<div class="w-full flex justify-content-center">
  <div class="page-section page-section-x-padded">
    <div class="asset-youtube-video-component">
      <div class="youtube-headline">{{ content().headline }}</div>
      <div class="ppg-youtube-embaded">
        <iframe
          [title]="content().headline"
          class="ppg-youtube-iframe"
          [src]="getYouTubeUrl() | safeUrl"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen>
        </iframe>
      </div>
      @if (youTubeDescriptionVisible()) {
        <div class="youtube-description">
          @for (description of content().description; track description) {
            <ppg-rich-text class="youtube-richtext" [content]="description.content" />
          }
        </div>
      }
    </div>
  </div>
</div>
