<div class="w-full flex justify-content-center" [style.background-color]="pageSectionBackground()">
  <div class="page-section page-section-x-padded">
    <div class="container mx-auto">
      <div class="high-image-strip-container">
        @for (card of cards(); track $index) {
          <ppg-high-image-strip-component
            [card]="card"
            [class]="{ 'card-l': largeCards(), 'card-m': mediumCards(), 'card-s': smallCards() }">
          </ppg-high-image-strip-component>
        }
      </div>
    </div>
  </div>
</div>
