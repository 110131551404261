"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.buildQFilterOperation = exports.buildFunctionExpression = exports.buildOperatorExpression = exports.isPathValue = exports.parseWithQuotes = exports.formatWithQuotes = exports.formatParamWithQuotes = exports.parseWithTypeSuffix = exports.formatWithTypeSuffix = exports.formatParamWithTypeSuffix = exports.parseWithTypePrefix = exports.formatWithTypePrefix = exports.formatParamWithTypePrefix = exports.parseLiteral = exports.formatLiteral = exports.formatLiteralParam = void 0;
const QFilterExpression_1 = require("../QFilterExpression");
function parseNullValue(value) {
  return value === "null" ? null : value;
}
function formatLiteralParam(value) {
  if (value === null) {
    return "null";
  }
  if (value === undefined) {
    return undefined;
  }
  return formatLiteral(value);
}
exports.formatLiteralParam = formatLiteralParam;
function formatLiteral(value) {
  return String(value);
}
exports.formatLiteral = formatLiteral;
function parseLiteral(value) {
  return parseNullValue(value);
}
exports.parseLiteral = parseLiteral;
function formatParamWithTypePrefix(typePrefix, value) {
  if (value === null) {
    return "null";
  }
  if (value === undefined) {
    return undefined;
  }
  return formatWithTypePrefix(typePrefix, value);
}
exports.formatParamWithTypePrefix = formatParamWithTypePrefix;
function formatWithTypePrefix(typePrefix, value) {
  return `${typePrefix}'${value}'`;
}
exports.formatWithTypePrefix = formatWithTypePrefix;
function parseWithTypePrefix(typePrefix, value) {
  const cleanedValue = parseNullValue(value);
  if (typeof cleanedValue === "string") {
    // we throw an error here if value doesn't conform to pattern
    if (!cleanedValue.startsWith(`${typePrefix}'`) || !cleanedValue.endsWith("'")) {
      throw new Error(`Type prefix "${typePrefix}" was expected, but not found in value: ${cleanedValue}`);
    }
    return cleanedValue.substring(typePrefix.length + 1, cleanedValue.length - 1);
  }
  return cleanedValue;
}
exports.parseWithTypePrefix = parseWithTypePrefix;
function formatParamWithTypeSuffix(typeSuffix, value) {
  if (value === null) {
    return "null";
  }
  if (value === undefined) {
    return undefined;
  }
  return formatWithTypeSuffix(typeSuffix, value);
}
exports.formatParamWithTypeSuffix = formatParamWithTypeSuffix;
function formatWithTypeSuffix(typeSuffix, value) {
  return `${value}${typeSuffix}`;
}
exports.formatWithTypeSuffix = formatWithTypeSuffix;
function parseWithTypeSuffix(typeSuffix, value) {
  const cleanedValue = parseNullValue(value);
  // lenient: allow the type suffix to be left out
  if (typeof cleanedValue === "string" && cleanedValue.endsWith(typeSuffix)) {
    return cleanedValue.substring(0, cleanedValue.length - typeSuffix.length);
  }
  return cleanedValue;
}
exports.parseWithTypeSuffix = parseWithTypeSuffix;
function formatParamWithQuotes(value) {
  if (value === null) {
    return "null";
  }
  if (value === undefined) {
    return undefined;
  }
  return formatWithQuotes(value);
}
exports.formatParamWithQuotes = formatParamWithQuotes;
function formatWithQuotes(value) {
  return `'${value}'`;
}
exports.formatWithQuotes = formatWithQuotes;
function parseWithQuotes(value) {
  const cleanedValue = parseNullValue(value);
  if (typeof cleanedValue === "string") {
    // we throw an error here if value doesn't conform to pattern
    if (!cleanedValue.startsWith("'") || !cleanedValue.endsWith("'")) {
      throw new Error(`Expected single quotes when parsing value: ${value}`);
    }
    return cleanedValue.substring(1, cleanedValue.length - 1);
  }
  return cleanedValue;
}
exports.parseWithQuotes = parseWithQuotes;
function isPathValue(value) {
  return typeof value === "object" && typeof (value === null || value === void 0 ? void 0 : value.getPath) === "function";
}
exports.isPathValue = isPathValue;
function buildOperatorExpression(path, operator, value) {
  return `${path} ${operator} ${value}`;
}
exports.buildOperatorExpression = buildOperatorExpression;
function buildFunctionExpression(functionName, param1, param2) {
  return `${functionName}(${param1}${param2 ? `,${param2}` : ""})`;
}
exports.buildFunctionExpression = buildFunctionExpression;
function buildQFilterOperation(path, operator, value) {
  return new QFilterExpression_1.QFilterExpression(buildOperatorExpression(path, operator, value));
}
exports.buildQFilterOperation = buildQFilterOperation;
