"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QGuidV2Param = exports.parseValueFromUrl = exports.getUrlConformValue = exports.GUID_V2_TYPE_PREFIX = void 0;
const QParam_1 = require("../QParam");
const UrlParamHelper_1 = require("../UrlParamHelper");
exports.GUID_V2_TYPE_PREFIX = "guid";
const getUrlConformValue = value => {
  return (0, UrlParamHelper_1.formatParamWithTypePrefix)(exports.GUID_V2_TYPE_PREFIX, value);
};
exports.getUrlConformValue = getUrlConformValue;
const parseValueFromUrl = urlConformValue => {
  return (0, UrlParamHelper_1.parseWithTypePrefix)(exports.GUID_V2_TYPE_PREFIX, urlConformValue);
};
exports.parseValueFromUrl = parseValueFromUrl;
class QGuidV2Param extends QParam_1.QParam {
  constructor() {
    super(...arguments);
    this.getUrlConformValue = exports.getUrlConformValue;
    this.parseValueFromUrl = exports.parseValueFromUrl;
  }
}
exports.QGuidV2Param = QGuidV2Param;
