"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BaseEnumPath = void 0;
const BaseFunctions_1 = require("../base/BaseFunctions");
class BaseEnumPath {
  constructor(path) {
    this.path = path;
    this.ascending = (0, BaseFunctions_1.orderAscending)(this.path);
    this.asc = this.ascending;
    this.descending = (0, BaseFunctions_1.orderDescending)(this.path);
    this.desc = this.descending;
    this.isNull = (0, BaseFunctions_1.filterIsNull)(this.path);
    this.isNotNull = (0, BaseFunctions_1.filterIsNotNull)(this.path);
    this.equals = (0, BaseFunctions_1.filterEquals)(this.path, this.mapValue.bind(this));
    this.eq = this.equals;
    this.notEquals = (0, BaseFunctions_1.filterNotEquals)(this.path, this.mapValue.bind(this));
    this.ne = this.notEquals;
    this.lowerThan = (0, BaseFunctions_1.filterLowerThan)(this.path, this.mapValue.bind(this));
    this.lt = this.lowerThan;
    this.lowerEquals = (0, BaseFunctions_1.filterLowerEquals)(this.path, this.mapValue.bind(this));
    this.le = this.lowerEquals;
    this.greaterThan = (0, BaseFunctions_1.filterGreaterThan)(this.path, this.mapValue.bind(this));
    this.gt = this.greaterThan;
    this.greaterEquals = (0, BaseFunctions_1.filterGreaterEquals)(this.path, this.mapValue.bind(this));
    this.ge = this.greaterEquals;
    this.in = (0, BaseFunctions_1.filterInEmulated)(this.path, this.mapValue.bind(this));
    if (!path || !path.trim()) {
      throw new Error("Path must be supplied!");
    }
  }
  /**
   * Returns the path of this property.
   */
  getPath() {
    return this.path;
  }
}
exports.BaseEnumPath = BaseEnumPath;
