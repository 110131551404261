"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QStringNumberV2Param = void 0;
const QParam_1 = require("../QParam");
const UrlParamHelper_1 = require("../UrlParamHelper");
class QStringNumberV2Param extends QParam_1.QParam {
  constructor() {
    super(...arguments);
    this.getUrlConformValue = value => {
      const suffix = this.getTypeSuffix();
      return suffix !== undefined ? (0, UrlParamHelper_1.formatParamWithTypeSuffix)(suffix, value) : (0, UrlParamHelper_1.formatLiteralParam)(value);
    };
    this.parseValueFromUrl = urlConformValue => {
      const suffix = this.getTypeSuffix();
      return suffix !== undefined ? (0, UrlParamHelper_1.parseWithTypeSuffix)(suffix, urlConformValue) : (0, UrlParamHelper_1.parseLiteral)(urlConformValue);
    };
  }
  getTypeSuffix() {
    return undefined;
  }
}
exports.QStringNumberV2Param = QStringNumberV2Param;
