"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NumericEnumConverter = void 0;
class NumericEnumConverter {
  constructor(theEnum) {
    this.theEnum = theEnum;
    this.from = "Edm.EnumType";
    this.id = "NumericEnumConverter";
    this.to = "enum";
  }
  convertFrom(value) {
    return typeof value !== "string" ? value : this.theEnum[value];
  }
  convertTo(value) {
    return this.theEnum[value];
  }
}
exports.NumericEnumConverter = NumericEnumConverter;
