<p-accordion
  [multiple]="isMultiple()"
  expandIcon="pi pi-fw pi-chevron-down flex custom-accordion-icon"
  collapseIcon="pi pi-fw pi-chevron-up flex custom-accordion-icon"
  data-test-id="filterAccordion.accordion">
  @for (filterGroup of filterGroups(); track filterGroup.value) {
    @if (filterGroup.filters.length > minFiltersCount()) {
      <p-accordionTab
        class="border-round-md mb-1"
        [header]="filterGroup.label"
        [selected]="!!filterGroup.isSelectedTab"
        data-test-id="filterAccordion.tab">
        @for (checkbox of filterGroup.filters; track checkbox.value) {
          <div
            class="filter-item flex justify-content-between align-items-center"
            data-test-id="filterAccordion.option">
            <p-checkbox
              class="cursor-pointer"
              labelStyleClass="u-break-word"
              binary="true"
              [(ngModel)]="checkbox.checked"
              [name]="checkbox.label"
              [value]="checkbox.value"
              [label]="checkbox.label"
              (onChange)="toggleSelection($event, checkbox.value, filterGroup.value)"
              data-test-id="filterAccordion.checkbox" />
            <p-chip class="filter-count" data-test-id="filterAccordion.count" styleClass="neutral">
              {{ checkbox.count }}
            </p-chip>
          </div>
        }
      </p-accordionTab>
    }
  }
</p-accordion>
