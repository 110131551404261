<div class="w-full flex justify-content-center" [style.background-color]="pageSectionBackground()">
  <div class="page-section page-section-x-padded">
    <p-accordion expandIcon="pi pi-plus" collapseIcon="pi pi-minus">
      <p-accordionTab>
        <p-header>
          {{ content().question }}
        </p-header>

        @for (answer of answers(); track $index) {
          <div>
            <ppg-rich-text [content]="answer.content"></ppg-rich-text>
          </div>
        }
      </p-accordionTab>
    </p-accordion>
  </div>
</div>
