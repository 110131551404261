"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BaseEnumParam = void 0;
const UrlParamHelper_1 = require("../UrlParamHelper");
class BaseEnumParam {
  constructor(name, mappedName) {
    this.name = name;
    this.mappedName = mappedName;
    if (!name) {
      throw new Error("Name is required for QParam objects!");
    }
  }
  getName() {
    return this.name;
  }
  getMappedName() {
    var _a;
    return (_a = this.mappedName) !== null && _a !== void 0 ? _a : this.getName();
  }
  convertFrom(value) {
    return Array.isArray(value) ? value.map(v => v === null || v === undefined ? v : this.mapValue(v)) : value === null || value === undefined ? value : this.mapValue(value);
  }
  convertTo(value) {
    if (value === null) {
      return null;
    }
    if (value === undefined) {
      return undefined;
    }
    return Array.isArray(value) ? value.map(v => v === null || v === undefined ? undefined : this.mapValueBack(v)) : this.mapValueBack(value);
  }
  formatUrlValue(value) {
    return Array.isArray(value) ? JSON.stringify(this.convertTo(value)) : (0, UrlParamHelper_1.formatParamWithQuotes)(this.convertTo(value));
  }
  parseUrlValue(value) {
    const parsed = (0, UrlParamHelper_1.parseWithQuotes)(value);
    if (value && parsed === undefined) {
      try {
        const jsonParsed = JSON.parse(value);
        if (Array.isArray(jsonParsed)) {
          return this.convertFrom(jsonParsed);
        }
      } catch (e) {}
    }
    return this.convertFrom(parsed);
  }
}
exports.BaseEnumParam = BaseEnumParam;
