"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QPrimitiveCollection = void 0;
const PRIMITIVE_VALUE_REFERENCE = "$it";
class QPrimitiveCollection {
  constructor(prefix, converter) {
    this.prefix = prefix;
    this.converter = converter;
  }
  withPrefix() {
    return this.prefix ? `${this.prefix}/${PRIMITIVE_VALUE_REFERENCE}` : PRIMITIVE_VALUE_REFERENCE;
  }
  convertFromOData(odataModel) {
    if (odataModel === null) {
      return null;
    }
    if (odataModel === undefined) {
      return undefined;
    }
    const converter = this.it.converter;
    return !converter ? odataModel : Array.isArray(odataModel) ? odataModel.map(om => converter.convertFrom(om)) : converter.convertFrom(odataModel);
  }
  convertToOData(userModel) {
    if (userModel === null) {
      return null;
    }
    if (typeof userModel === "undefined") {
      return userModel;
    }
    const converter = this.it.converter;
    return !converter ? userModel : Array.isArray(userModel) ? userModel.map(um => converter.convertTo(um)) : converter.convertTo(userModel);
  }
}
exports.QPrimitiveCollection = QPrimitiveCollection;
