import { Injectable, signal } from '@angular/core';

import { FavoriteColor, Color } from '@ppg/core/models';
import { BaseLocalStorageService } from '@ppg/core/services';

@Injectable({ providedIn: 'root' })
export class FavoriteColorLocalStorageService extends BaseLocalStorageService<FavoriteColor[]> {
  #favoriteColors = signal<FavoriteColor[]>([]);
  favoriteColors = this.#favoriteColors.asReadonly();

  protected override localStorageKey = 'userFavoriteColors';

  constructor() {
    super();
    const favoriteColors = this.getFromLocalStorage();
    if (favoriteColors) {
      this.#favoriteColors.set(favoriteColors);
    }
  }

  addFavoriteColor(color: Color): void {
    this.#setColorsInLocalStorage([
      ...this.#favoriteColors(),
      <FavoriteColor>{
        productNumber: color.productNumber,
        colorName: color.colorName,
        addedToFavoritesAt: new Date().toUTCString(),
      },
    ]);
  }

  getFavoriteColors(): FavoriteColor[] {
    return this.#favoriteColors();
  }

  removeFavoriteColor(productNumber: string): void {
    const updatedFavoriteColors = this.#favoriteColors().filter((c) => c.productNumber !== productNumber);
    this.#favoriteColors.set(updatedFavoriteColors);
    this.setInLocalStorage(updatedFavoriteColors);
  }

  clearFavoriteColors(): void {
    this.#favoriteColors.set([]);
    this.removeFromLocalStorage();
  }

  #setColorsInLocalStorage(favoriteColors: FavoriteColor[]): void {
    this.#favoriteColors.set(favoriteColors);
    this.setInLocalStorage(this.#favoriteColors());
  }
}
