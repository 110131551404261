import { CommonModule } from '@angular/common';
import { Component, output, input, ChangeDetectionStrategy } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AccordionModule } from 'primeng/accordion';
import { CheckboxChangeEvent, CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';

import { FilterGroup, FilterSelectionChangedEvent } from '@ppg/core/models';

@Component({
  selector: 'ppg-filter-accordion',
  imports: [CommonModule, AccordionModule, CheckboxModule, FormsModule, ChipModule],
  templateUrl: './filter-accordion.component.html',
  styleUrls: ['./filter-accordion.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterAccordionComponent {
  readonly filterGroups = input.required<readonly FilterGroup[]>();
  readonly isMultiple = input<boolean>(true);
  readonly minFiltersCount = input<number>(1);

  readonly selectionChanged = output<FilterSelectionChangedEvent>();

  toggleSelection({ checked }: CheckboxChangeEvent, filter: string, group: string): void {
    this.selectionChanged.emit({ checked, filter, group });
  }
}
