"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertV4CollectionResponse = exports.convertV4ModelResponse = exports.convertV4ValueResponse = exports.convertV2CollectionResponse = exports.convertV2ModelResponse = exports.convertV2ValueResponse = void 0;
/*
/!**
 * Spec and real implementations of OData V2 differ in some points regarding the response types:
 * - spec about primitive props as return type: "d.results"; real world uses only "d" (same as V1)
 * - spec about complex types as return type: "d.results"; real world uses only "d" (same as V1 & V2 models)
 * - spec about primitive collections as return type: "d: []"; real world "d.results: []" (same as V2 collections)
 *
 * With this helper function we try to use the "results" object, if it exists, otherwise we fall back to just
 * using object "d" itself. This way we support both semantics.
 *
 * @see https://www.odata.org/documentation/odata-version-2-0/json-format/
 * @param responseData
 *!/
function getValueForV2OrV1ResponseModels(responseData: ODataValueResponseV2<any>) {
  return typeof responseData.d?.results === "object" &&
    Object.keys(responseData.d).filter((d) => !d.startsWith("__")).length === 1
    ? responseData.d.results
    : responseData.d;
}
*/
const convertV2ValueResponse = (response, qResponseType) => {
  const asV2Model = response.data;
  const value = asV2Model === null || asV2Model === void 0 ? void 0 : asV2Model.d;
  if (typeof value === "object") {
    // we try to match by known attribute name
    if (value.hasOwnProperty(qResponseType.getName())) {
      // map attribute name and convert the attribute value
      asV2Model.d = {
        [qResponseType.getMappedName()]: qResponseType.convertFrom(value[qResponseType.getName()])
      };
    }
    // alternatively, if single attribute is given, then we use that one
    else {
      const keyValue = Object.entries(value);
      if (keyValue.length === 1) {
        const [key, val] = keyValue[0];
        // convert value only
        asV2Model.d[key] = qResponseType.convertFrom(val);
      }
    }
  }
  return response;
};
exports.convertV2ValueResponse = convertV2ValueResponse;
const convertV2ModelResponse = (response, qResponseType) => {
  const asV2Model = response.data;
  if ((asV2Model === null || asV2Model === void 0 ? void 0 : asV2Model.d) && typeof asV2Model.d === "object") {
    asV2Model.d = qResponseType.convertFrom(asV2Model.d);
  }
  return response;
};
exports.convertV2ModelResponse = convertV2ModelResponse;
const convertV2CollectionResponse = (response, qResponseType) => {
  var _a;
  const asV2Collection = response.data;
  const value = (_a = asV2Collection === null || asV2Collection === void 0 ? void 0 : asV2Collection.d) === null || _a === void 0 ? void 0 : _a.results;
  if (value && typeof value === "object") {
    asV2Collection.d.results = qResponseType.convertFrom(value);
  }
  // support for V1
  else if ((asV2Collection === null || asV2Collection === void 0 ? void 0 : asV2Collection.d) && typeof asV2Collection.d === "object") {
    asV2Collection.d = qResponseType.convertFrom(asV2Collection.d);
  }
  return response;
};
exports.convertV2CollectionResponse = convertV2CollectionResponse;
const convertV4ValueResponse = (response, qResponseType) => {
  var _a;
  const asV4Value = (_a = response.data) === null || _a === void 0 ? void 0 : _a.value;
  if (response.status === 204) {
    response.data = {
      value: null
    };
  } else if (asV4Value !== undefined && asV4Value !== null) {
    response.data.value = qResponseType.convertFrom(asV4Value);
  }
  return response;
};
exports.convertV4ValueResponse = convertV4ValueResponse;
const convertV4ModelResponse = (response, qResponseType) => {
  const asV4Model = response.data;
  if (asV4Model && typeof asV4Model === "object") {
    response.data = qResponseType.convertFrom(asV4Model);
  }
  return response;
};
exports.convertV4ModelResponse = convertV4ModelResponse;
const convertV4CollectionResponse = (response, qResponseType) => {
  const asV4Collection = response.data;
  const value = asV4Collection === null || asV4Collection === void 0 ? void 0 : asV4Collection.value;
  if (value && typeof value === "object") {
    asV4Collection.value = qResponseType.convertFrom(value);
  }
  return response;
};
exports.convertV4CollectionResponse = convertV4CollectionResponse;
